import TextBlockWithTitlePreview from "../components/TextBlockWithTitle/TextBlockWithTitlePreview";
import ContentsTypes from "shared/enums/contentsTypes";
import ContentsCustomTypes from "enums/contentsCustomTypes.enum";
export var TextBlockWithTitle = {
    label: "Paragraphe intitulé",
    type: ContentsCustomTypes.TEXT_BLOCK_WITH_TITLE,
    Preview: TextBlockWithTitlePreview,
    icon: "align-left",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Titre",
            key: "title",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Texte",
            key: "text",
            type: ContentsTypes.TEXT,
            value: "",
        },
    ],
};
