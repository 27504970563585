var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Grid, Typography } from "@mui/material";
import { generateContentId } from "administration/utils/adminContentsUtils";
import ImageContainer from "shared/components/contents/imageContainer/ImageContainer";
import Image from "shared/components/contents/image/Image";
var templateLabelStyled = {
    bgcolor: "layout.template",
    color: "secondary.text",
    p: 2,
    fontWeight: "bold",
};
var templateStyled = {
    cursor: "pointer",
    border: "1px solid",
    borderColor: "layout.templateBorder",
    borderRadius: "5px",
    overflow: "hidden",
    "&:hover": {
        opacity: 0.6,
    },
};
var TemplateSelector = function (_a) {
    var form = _a.form, setForm = _a.setForm, template = _a.template, allTemplates = _a.allTemplates, setIsTemplateStep = _a.setIsTemplateStep;
    var handleTemplateChange = function (templateDefinition) {
        var key = templateDefinition.key, _a = templateDefinition.initialContents, initialContents = _a === void 0 ? [] : _a;
        setForm(__assign(__assign({}, form), { template: key, contents: initialContents.map(generateContentId) }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "subtitle1", sx: { fontWeight: 600, color: "form.text", mb: 1 }, children: "Choisissez un gabarit de page" }), _jsx(Grid, { container: true, spacing: 1, children: allTemplates.map(function (t) { return (_jsx(Grid, { item: true, xs: 3, children: _jsxs(Box, { sx: __assign(__assign({}, templateStyled), { opacity: template === t.key ? 0.6 : 1 }), onClick: function () {
                            setIsTemplateStep(false);
                            handleTemplateChange(t);
                        }, children: [_jsx(ImageContainer, { ratio: 50, children: _jsx(Image, { file: { url: t.templateImage || "/default-image.jpg" }, alt: t.key, position: t.templateImage ? "top" : "center", disableLazy: true }) }), _jsx(Box, { sx: templateLabelStyled, children: t.label })] }) }, t.key)); }) })] }));
};
export default TemplateSelector;
