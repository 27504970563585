var Languages;
(function (Languages) {
    Languages["Fran\u00E7ais"] = "fr";
    Languages["Anglais"] = "en";
    Languages["Espagnol"] = "es";
    Languages["Allemand"] = "de";
    Languages["Italien"] = "it";
    Languages["Portugais"] = "pt";
    Languages["Russe"] = "ru";
    Languages["Chinois"] = "zh";
    Languages["Japonais"] = "ja";
    Languages["Cor\u00E9en"] = "ko";
    Languages["Arabe"] = "ar";
})(Languages || (Languages = {}));
export default Languages;
