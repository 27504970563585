import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, TextField } from "@mui/material";
import { ModalActions } from "administration/components/Modal";
import Button from "shared/components/contents/button/Button";
import { Fragment, useState } from "react";
var RenameVersionForm = function (props) {
    var _a = props.versionName, versionName = _a === void 0 ? "" : _a, onValidate = props.onValidate, onCancel = props.onCancel;
    var _b = useState(versionName || ""), name = _b[0], setName = _b[1];
    var handleNameChange = function (e) {
        setName(e.target.value);
    };
    var handleValidate = function () {
        if (typeof onValidate === "function") {
            onValidate(name);
        }
    };
    return (_jsxs(Fragment, { children: [_jsx("h2", { children: "Renommer cette version" }), _jsx(Box, { mb: 2, children: _jsx(TextField, { label: "Nom de la version", value: name, onChange: handleNameChange }) }), _jsxs(ModalActions, { children: [_jsx(Button, { color: "secondary", onClick: onCancel, children: "Annuler" }), _jsx(Button, { color: "primary", onClick: handleValidate, children: "Renommer" })] })] }));
};
export default RenameVersionForm;
