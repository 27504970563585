var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, InputLabel } from "@mui/material";
import { pageImageFiltersKey } from "shared/utils/imageUtil";
import FilePicker from "../FilePicker";
var AdminPageImageForm = function (_a) {
    var form = _a.form, setForm = _a.setForm, image = _a.image, version = _a.version, formSubmitted = _a.formSubmitted, imageFiltersChild = _a.imageFiltersChild;
    var handleChangeImage = function (newImage) {
        if (newImage === void 0) { newImage = { url: "" }; }
        if (!newImage) {
            setForm(__assign(__assign({}, form), { image: undefined }));
        }
        else {
            var filters_1 = newImage.filters, others = __rest(newImage, ["filters"]);
            var newContents = (form === null || form === void 0 ? void 0 : form.contents) || [];
            if (filters_1) {
                var hasFilters_1 = false;
                newContents = newContents.map(function (c) {
                    if (c.key === pageImageFiltersKey) {
                        hasFilters_1 = true;
                        return filters_1;
                    }
                    return c;
                });
                if (!hasFilters_1) {
                    newContents.push(filters_1);
                }
            }
            else {
                newContents = newContents.filter(function (c) { return c.key !== pageImageFiltersKey; });
            }
            setForm(__assign(__assign({}, form), { contents: newContents, image: newImage
                    ? __assign(__assign({}, image), others) : undefined }));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { mb: 1, children: _jsx(InputLabel, { shrink: true, children: "Image de la page" }) }), _jsx(FilePicker, { onSelectFile: handleChangeImage, file: image, pageVersion: version, image: true, formSubmitted: formSubmitted, imageFiltersChild: imageFiltersChild })] }));
};
export default AdminPageImageForm;
