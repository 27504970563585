// import React from "react";
import CmsHooks from "shared/utils/register/CmsHooks";
import getEditorComponent from "utils/getEditorComponent";
import getDynamicAdminContentsTypes from "utils/getDynamicAdminContentsTypes";
import getFormComponent from "utils/getFormComponent";
import customContents from "contents/customContents";
import { HOOKS } from "shared/utils/register/definitions";
import customContentTypes from "enums/contentsCustomTypes.enum";
// import Routes from "bo/Routes";

// const adminWebsitePath = "/websites";

export default () => {
  CmsHooks.registerHook(HOOKS.templateAdminUtils_getEditorComponent, getEditorComponent);
  CmsHooks.registerHook(HOOKS.templateAdminUtils_getDynamicAdminContentsTypes, getDynamicAdminContentsTypes);
  CmsHooks.registerHook(HOOKS.ContentForm_getFormComponent, getFormComponent);
  CmsHooks.registerHook(HOOKS.adminContentsUtils_contents, customContents);
  CmsHooks.registerHook(HOOKS.Custom_content_types, customContentTypes);
  // CmsHooks.registerHook(HOOKS.Bo_Routes, <Routes adminWebsitePath={adminWebsitePath} />);
  // CmsHooks.registerHook(HOOKS.Bo_AdminWebsitePath, adminWebsitePath);
};
