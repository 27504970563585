var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal as MuiModal } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import SimpleBar from "simplebar-react";
export var ModalActions = function (props) { return (_jsx(Grid, __assign({ container: true, justifyContent: "flex-end", sx: {
        "&> *": {
            ml: 1,
        },
    } }, props))); };
var wrapperStyled = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};
var mainStyled = function (_a) {
    var theme = _a.theme, size = _a.size;
    return ({
        width: "".concat(size && theme.breakpoints.values[size] ? "".concat(theme.breakpoints.values[size], "px") : "auto"),
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "auto",
        "& .simplebar-track.simplebar-vertical .simplebar-scrollbar:before ": {
            bgcolor: "layout.scrollbar",
        },
    });
};
var Modal = function (props) {
    var children = props.children, onClose = props.onClose, size = props.size, title = props.title, others = __rest(props, ["children", "onClose", "size", "title"]);
    return (_jsx(MuiModal, __assign({ onClose: onClose }, others, { children: _jsx(Box, { sx: wrapperStyled, onClick: onClose, children: _jsx(Paper, { onClick: function (e) { return e.stopPropagation(); }, sx: function (theme) { return mainStyled({ theme: theme, size: size }); }, children: _jsxs(SimpleBar, { style: { maxHeight: "90vh" }, children: [title && (_jsxs(_Fragment, { children: [_jsx(Box, { pl: 2, children: _jsx("h2", { children: title }) }), _jsx(Divider, {})] })), _jsx(Box, { p: 2, children: children })] }) }) }) })));
};
export default Modal;
