// import React from "react";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";
export var FormTitle = styled("h2")({
    textAlign: "center",
});
export var FormFieldset = styled("fieldset")(function (_a) {
    var theme = _a.theme;
    return ({
        marginBottom: "0.875rem",
        "& > legend": {
            color: theme.palette.secondary.text,
        },
    });
});
export var FormLabelTooltip = styled(Tooltip)({
    verticalAlign: "sub",
    ml: 1,
});
