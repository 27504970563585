var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { generateContentId } from "administration/utils/adminContentsUtils";
import { getTemplateByKey } from "shared/utils/templateUtils";
var AdminPageTemplateForm = function (_a) {
    var form = _a.form, setForm = _a.setForm, template = _a.template, formSubmitted = _a.formSubmitted, errors = _a.errors, canUpdateTemplate = _a.canUpdateTemplate, allTemplates = _a.allTemplates;
    var handleTemplateChange = function (templateDefinition) {
        var key = templateDefinition.key, _a = templateDefinition.initialContents, initialContents = _a === void 0 ? [] : _a;
        setForm(__assign(__assign({}, form), { template: key, contents: initialContents.map(generateContentId) }));
    };
    var showError = function (key) { return formSubmitted && errors[key]; };
    return (_jsx(Grid, { item: true, xs: 3, children: _jsxs(FormControl, { error: showError("template"), children: [_jsx(InputLabel, { shrink: true, children: "Gabarit" }), _jsx(Select, { value: template || "", name: "template", onChange: function (e) { return handleTemplateChange(getTemplateByKey(e.target.value)); }, disabled: !canUpdateTemplate, children: allTemplates.map(function (t) { return (_jsx(MenuItem, { value: t.key, children: t.label }, t.key)); }) }), showError("template") && _jsx(FormHelperText, { children: "Veuillez s\u00E9lectionner un gabarit" })] }) }));
};
export default AdminPageTemplateForm;
