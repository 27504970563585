import { useCallback } from "react";
import useAdminEditorStore from "administration/store/AdminEditorStore";
export var useSetCurrentSite = function () {
    var _a = useAdminEditorStore(function (state) { return ({
        setCurrentSiteId: state.setCurrentSiteId,
        setCurrentPageId: state.setCurrentPageId,
        setCurrentPageVersionId: state.setCurrentPageVersionId,
    }); }), setCurrentSiteId = _a.setCurrentSiteId, setCurrentPageId = _a.setCurrentPageId, setCurrentPageVersionId = _a.setCurrentPageVersionId;
    var setCurrentSite = useCallback(function (site, page) {
        var id = site.id;
        setCurrentSiteId(id);
        if (page) {
            setCurrentPageId(page.id);
        }
        else {
            setCurrentPageId(undefined);
        }
        setCurrentPageVersionId(undefined);
    }, [setCurrentSiteId, setCurrentPageId, setCurrentPageVersionId]);
    return { setCurrentSite: setCurrentSite };
};
