var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RemoveIcon from "@mui/icons-material/Cancel";
import DuplicateIcon from "@mui/icons-material/CopyAll";
import { Box, Grid } from "@mui/material";
import { AdminActionsContext } from "administration/components/admin/AdminActions";
import AdminContentEditor from "administration/components/admin/AdminContentEditor/AdminContentEditor";
import SelectContentModal from "administration/components/SelectContentModal/SelectContentModal";
import ActionPositions from "shared/enums/actionPositions";
import adminActionsOnContent from "shared/enums/adminActionsOnContent";
import { generateContentId, getAllDefaultContents, isValidContent } from "administration/utils/adminContentsUtils";
import React, { Fragment, useState } from "react";
var addContentContainerStyled = {
    position: "relative",
    cursor: "pointer",
    opacity: 0.1,
    transition: "opacity ease-in 100ms",
    py: 1.5,
    color: "#333366",
    ":hover": {
        opacity: 1,
        color: "#333366",
    },
};
var addContentIconStyled = {
    bgcolor: "currentcolor",
    pt: 0.25,
    px: 1.5,
    pb: 0.375,
    borderRadius: "12px",
    zIndex: 1,
};
var addContentDividerStyled = {
    position: "absolute",
    top: "calc(50% - 1px)",
    left: "16px",
    right: "16px",
    borderTop: "3px solid",
};
var AddContentButton = function (props) {
    var index = props.index, addContentText = props.addContentText, onClick = props.onClick;
    var buttonPlusRef = React.useRef(null);
    var handleClick = React.useCallback(function () {
        onClick(index, buttonPlusRef === null || buttonPlusRef === void 0 ? void 0 : buttonPlusRef.current);
    }, [onClick, index]);
    return (_jsxs(Grid, { container: true, justifyContent: "center", sx: addContentContainerStyled, onClick: handleClick, title: addContentText, children: [_jsx(Grid, { item: true, sx: addContentIconStyled, children: _jsx(Box, { component: "span", sx: { color: "white" }, ref: buttonPlusRef, children: "+" }) }), _jsx(Box, { sx: addContentDividerStyled })] }));
};
var DynamicAdmin = function (props) {
    var _a = props.children, children = _a === void 0 ? null : _a, content = props.content, onContentChange = props.onContentChange, _b = props.addContentText, addContentText = _b === void 0 ? "Ajouter un élement" : _b, contentsTypes = props.contentsTypes, _c = props.limit, limit = _c === void 0 ? Infinity : _c, _d = props.maxIndexForSpacing, maxIndexForSpacing = _d === void 0 ? null : _d;
    var dynamicChildKey = content.dynamicChildKey, contentId = content.id, rest = __rest(content, ["dynamicChildKey", "id"]);
    var _e = useState(-1), insertIndex = _e[0], setInsertIndex = _e[1];
    var _f = useState(false), displayModalSelectContent = _f[0], setDisplayModalSelectContent = _f[1];
    var _g = useState(undefined), modalSelectContentTarget = _g[0], setModalSelectContentTarget = _g[1];
    var _h = useState(), selectedContent = _h[0], setSelectedContent = _h[1];
    var availableContents = getAllDefaultContents().filter(function (content) { return content.type && (contentsTypes === null || contentsTypes === void 0 ? void 0 : contentsTypes.includes(content.type)); });
    var handleAddChild = function (newContent, index, openDrawer) {
        if (openDrawer === void 0) { openDrawer = false; }
        var i = index !== undefined ? index : insertIndex;
        var key = newContent.key, _a = newContent.value, value = _a === void 0 ? "" : _a, newContentChildren = newContent.children, type = newContent.type;
        var newChild = generateContentId({
            key: dynamicChildKey || key,
            value: value,
            type: type,
            children: newContentChildren,
        });
        var newChildren = __spreadArray([], (rest.children || []), true);
        var filterChildren = newChildren.filter(function (child) { return child.key === newChild.key; });
        var prevChild = null;
        if (i && i > 0) {
            prevChild = filterChildren[i - 1];
        }
        newChildren.splice(newChildren.indexOf(prevChild) + 1, 0, newChild);
        onContentChange(__assign(__assign({}, content), { children: newChildren }));
        if ((newContent === null || newContent === void 0 ? void 0 : newContent.editionModal) && openDrawer) {
            setSelectedContent(newChild);
        }
        else {
            setSelectedContent(undefined);
        }
    };
    var handleEditChild = React.useCallback(function (newChild) {
        var newChildren = __spreadArray([], (content.children || []), true);
        if (insertIndex > -1) {
            newChildren[insertIndex] = newChild;
            onContentChange(__assign(__assign({}, content), { children: newChildren }));
        }
    }, [content, insertIndex, onContentChange]);
    var handleSelectContent = function (content, index) {
        setDisplayModalSelectContent(false);
        if (isValidContent(content)) {
            handleAddChild(content, index, true);
            if (typeof index === "number") {
                setInsertIndex(index);
            }
        }
        else {
            if (index) {
                setInsertIndex(index); //@fixme: doubts about its usefulness
            }
            setSelectedContent(content);
        }
    };
    var handleClickAddContent = function (index, target) {
        if (availableContents.length > 1) {
            setModalSelectContentTarget(target.current);
            setDisplayModalSelectContent(true);
            setInsertIndex(index);
        }
        else if (availableContents.length === 1) {
            handleSelectContent(availableContents[0], index);
        }
    };
    var handleRemoveChild = function (index) { return function () {
        var newChildren = __spreadArray([], (content.children || []), true);
        var filterChildren = newChildren.filter(function (child) { return child.key === dynamicChildKey; });
        var childToRemove = filterChildren[index];
        onContentChange(__assign(__assign({}, content), { children: newChildren.filter(function (child) { return child !== childToRemove; }) }));
    }; };
    var handleDuplicateChild = function (index) { return function () {
        var newChildren = __spreadArray([], (content.children || []), true);
        var filterChildren = newChildren.filter(function (child) { return child.key === dynamicChildKey; });
        var childToDuplicate = filterChildren[index];
        var indexOfChildToDuplicate = newChildren.indexOf(childToDuplicate);
        var newChild = generateContentId(__assign({}, childToDuplicate));
        newChildren.splice(indexOfChildToDuplicate, 0, newChild);
        onContentChange(__assign(__assign({}, content), { children: newChildren }));
    }; };
    var handleClose = React.useCallback(function () { return setSelectedContent(undefined); }, []);
    var appendButtonToNode = function (node, index, allNodes) {
        if (allNodes === void 0) { allNodes = []; }
        return (!!node && (_jsxs(Fragment, { children: [index === 0 && allNodes.length < limit && (_jsx(AddContentButton, { index: 0, addContentText: addContentText, onClick: handleClickAddContent })), maxIndexForSpacing && index > 0 && allNodes.length < limit && index <= maxIndexForSpacing && (_jsx(Box, { sx: { height: "33px", width: "1px" } })), _jsxs(Box, { sx: { width: "100%" }, children: [_jsx(AdminActionsContext.Provider, { value: {
                                parentActions: [
                                    {
                                        node: (_jsx(DuplicateIcon, { onClick: handleDuplicateChild(index), titleAccess: "Dupliquer cet \u00E9l\u00E9ment" }, "DuplicateIcon-".concat(contentId))),
                                        position: ActionPositions.TOP_RIGHT,
                                        order: 4,
                                    },
                                    {
                                        node: (_jsx(RemoveIcon, { onClick: handleRemoveChild(index), titleAccess: "Supprimer cet \u00E9l\u00E9ment" }, "RemoveIcon-".concat(contentId))),
                                        position: ActionPositions.TOP_RIGHT,
                                        order: 5,
                                        action: adminActionsOnContent.REMOVE_CONTENT,
                                    },
                                ],
                            }, children: node }), availableContents.length > 0 && allNodes.length < limit && (_jsx(AddContentButton, { index: index + 1, addContentText: addContentText, onClick: handleClickAddContent }))] })] }, node.key || index)));
    };
    var renderedChildren = typeof children === "function" ? children(appendButtonToNode) : React.Children.map(children, appendButtonToNode);
    var hasChildren = !!renderedChildren;
    if (Array.isArray(renderedChildren)) {
        hasChildren = renderedChildren.filter(function (c) { return !!c; }).length > 0;
    }
    return (_jsxs(Fragment, { children: [!hasChildren && _jsx(AddContentButton, { index: 0, addContentText: addContentText, onClick: handleClickAddContent }), renderedChildren, _jsx(SelectContentModal, { open: displayModalSelectContent, contents: availableContents, onSelectContent: handleSelectContent, onClose: function () { return setDisplayModalSelectContent(false); }, target: modalSelectContentTarget }), _jsx(AdminContentEditor, { open: !!selectedContent, content: selectedContent, onValidate: Boolean(selectedContent === null || selectedContent === void 0 ? void 0 : selectedContent.id) ? undefined : handleAddChild, onContentChange: Boolean(selectedContent === null || selectedContent === void 0 ? void 0 : selectedContent.id) ? handleEditChild : undefined, onClose: handleClose, drawer: Boolean(selectedContent === null || selectedContent === void 0 ? void 0 : selectedContent.id) })] }));
};
export default DynamicAdmin;
