var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import TextAreaForm from "administration/components/contentForms/TextAreaForm";
import { encodeCodeBlockText } from "shared/utils/commonUtils";
var CodeBlockForm = function (props) {
    var content = props.content, onContentChange = props.onContentChange, others = __rest(props, ["content", "onContentChange"]);
    var handleCodeBlockTextChange = function (code) {
        var value = code.value;
        onContentChange(__assign(__assign({}, content), { value: encodeCodeBlockText(value) }));
    };
    return _jsx(TextAreaForm, __assign({ content: content, onContentChange: handleCodeBlockTextChange, rows: 15 }, others));
};
export default CodeBlockForm;
