var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CKEditor } from "ckeditor4-react";
import { useEffect, useReducer, useRef, useState } from "react";
import useEditorStore from "administration/store/EditorStore";
function reducer(state, action) {
    return action.value;
}
var CKEditorCustom = function (props) {
    var onChange = props.onChange, initData = props.initData, _a = props.debounce, debounce = _a === void 0 ? 2000 : _a, others = __rest(props, ["onChange", "initData", "debounce"]);
    var timeoutRef = useRef(null);
    var setEditorValueChange = useEditorStore(function (state) { return ({ setEditorValueChange: state.setEditorValueChange }); }).setEditorValueChange;
    var _b = useReducer(reducer, initData), localData = _b[0], dispatch = _b[1];
    var _c = useState(null), localEditor = _c[0], setLocalEditor = _c[1];
    useEffect(function () {
        dispatch({ value: initData });
        if (localEditor) {
            localEditor.setData(initData);
        }
    }, [initData]);
    useEffect(function () {
        if (localData !== initData) {
            if ((timeoutRef === null || timeoutRef === void 0 ? void 0 : timeoutRef.current) !== null) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(function () {
                timeoutRef.current = null;
                setEditorValueChange(true);
                onChange(localData);
            }, debounce);
        }
    }, [debounce, initData, localData, onChange, setEditorValueChange]);
    return (_jsx(CKEditor, __assign({ onLoaded: function (e) {
            setLocalEditor(e.editor);
        }, onBeforeLoad: function (CKEDITOR) {
            // eslint-disable-next-line no-param-reassign
            CKEDITOR.disableAutoInline = true;
        } }, others, { editorUrl: "/ckeditor/ckeditor.js", initData: localData, onChange: function (e) { return dispatch({ value: e.editor.getData() }); } })));
};
export default CKEditorCustom;
