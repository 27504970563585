import { accordion } from "./accordion/accordion.descriptor";
import { accordionPanel } from "./accordion/accordionPanel.descriptor";
import { attachment } from "./attachment/attachment.descriptor";
import { blockTitle } from "./blockTitle/blockTitle.descriptor";
import { button } from "./button/button.descriptor";
import { checkbox } from "./checkbox/checkbox.descriptor";
import { codeBlock } from "./codeBlock/codeBlock.descriptor";
import { codeBlockText } from "./codeBlock/codeBlockText.descriptor";
import { color } from "./color/color.descriptor";
import { container } from "./container/container.descriptor";
import { contentsGroup } from "./contentsGroup/contentsGroup.descriptor";
import { contentsGroupList } from "./contentsGroupList/contentsGroupsList.descriptor";
import { dynamic } from "./dynamic/dynamic.descriptor";
import { expansionPanel } from "./expansionPanel/expansionPanel.descriptor";
import { expansionPanelDetails } from "./expansionPanelDetails/expansionPanelDetails.descriptor";
import { expansionText } from "./expansionText/expansionText.descriptor";
import { file } from "./file/file.descriptor";
import { fileImage } from "./fileImage/fileImage.descriptor";
import { galerieImage } from "./galerieImage/galerieImage.descriptor";
import { glossary } from "./glossary/glossary.descriptor";
import { glossaryItem } from "./glossaryItem/glossaryItem.descriptor";
import { grid } from "./grid/grid.descriptor";
import { icon } from "./icon/icon.descriptor";
import { image } from "./image/image.descriptor";
import { imageBlock } from "./imageBlock/imageBlock.descriptor";
import { imageFile } from "./imageFile/imageFile.descriptor";
import { imageFilters } from "./imageFilters/imageFilters.descriptor";
import { infoBlock } from "./infoBlock/infoBlock.descriptor";
import { infoBlockList } from "./infoBlockList/infoBlockList.descriptor";
import { input } from "./input/input.descriptor";
import { link } from "./link/link.descriptor";
import { margin } from "./margin/margin.descriptor";
import { menuGroup } from "./menuGroup/menuGroup.descriptor";
import { menuItem } from "./menuItem/menuItem.descriptor";
import { page } from "./page/page.descriptor";
import { select } from "./select/select.descriptor";
import { siteSettingst } from "./siteSettings/siteSettings.descriptor";
import { slider } from "./slider/slider.descriptor";
import { socialNetwork } from "./socialNetwork/socialNetwork.descriptor";
import { step } from "./step/step.descriptor";
import { stepV2 } from "./stepV2/stepV2.descriptor";
import { stepV2Content } from "./stepV2Content/stepV2Content.descriptor";
import { subPage } from "./subPage/subPage.descriptor";
import { subPageList } from "./subPage/subPageList.descriptor";
import { tab } from "./tabPanel/tab.descriptor";
import { tabs } from "./tabPanel/tabs.descriptor";
import { templateSettings } from "./templateSettings/templateSettings.descriptor";
import { templatesSettings } from "./templatesSettings/templatesSettings.descriptor";
import { text } from "./text/text.descriptor";
import { timeline } from "./timeLine/timeline.descriptor";
import { url } from "./url/url.descriptor";
import { video } from "./video/video.descriptor";
import { warningBanner } from "./warningBanner/warningBanner.descriptor";
export var adminContents = [
    text,
    button,
    imageBlock,
    galerieImage,
    imageFile,
    image,
    imageFilters,
    video,
    accordionPanel,
    expansionPanel,
    expansionPanelDetails,
    accordion,
    expansionText,
    attachment,
    checkbox,
    color,
    contentsGroupList,
    contentsGroup,
    file,
    fileImage,
    glossary,
    glossaryItem,
    grid,
    container,
    margin,
    icon,
    slider,
    infoBlock,
    infoBlockList,
    input,
    link,
    menuGroup,
    menuItem,
    page,
    select,
    siteSettingst,
    templatesSettings,
    templateSettings,
    socialNetwork,
    step,
    stepV2,
    stepV2Content,
    subPage,
    subPageList,
    timeline,
    tabs,
    tab,
    url,
    warningBanner,
    codeBlock,
    codeBlockText,
    blockTitle,
    dynamic,
];
