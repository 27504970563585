var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Grid, TextField } from "@mui/material";
import { stringToPath } from "shared/utils/urlUtils";
var AdminPageTitleForm = function (_a) {
    var form = _a.form, setForm = _a.setForm, title = _a.title, formSubmitted = _a.formSubmitted, errors = _a.errors;
    var handleChangeTitle = function (e) {
        var value = e.target.value;
        var pathFromTitle = stringToPath(value);
        setForm(__assign(__assign({}, form), { path: form.path === stringToPath(form.title) ? pathFromTitle : form.path, title: value, page: __assign(__assign({}, form.page), { name: value }) }));
    };
    var showError = function (key) { return formSubmitted && errors[key]; };
    return (_jsx(Grid, { item: true, xs: 6, children: _jsx(TextField, { error: showError("title"), label: "Titre de la page", value: title, name: "title", onChange: handleChangeTitle, helperText: showError("title") && "Veuillez donner un titre à cette page" }) }));
};
export default AdminPageTitleForm;
