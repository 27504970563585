import { jsx as _jsx } from "react/jsx-runtime";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import RenderPage from "administration/components/Layout/RenderPage";
import CmsHooks from "shared/utils/register/CmsHooks";
import { HOOKS } from "shared/utils/register/definitions";
import { useSites } from "shared/hooks/SiteHook";
import useAdminPageVersionStore from "administration/store/AdminPageVersionStore";
import { useShallow } from "zustand/react/shallow";
var NoLayout = function () {
    var projectTheme = CmsHooks.getHook(HOOKS.THEME);
    var getTheme = CmsHooks.getHook(HOOKS.GET_THEME);
    var currentSite = useSites().currentSite;
    var currentPageVersion = useAdminPageVersionStore(useShallow(function (state) { return ({
        currentPageVersion: state.currentPageVersion,
    }); })).currentPageVersion;
    var theme = (typeof getTheme === "function" && getTheme({ site: currentSite })) || projectTheme || createTheme();
    if (!currentPageVersion)
        return null;
    return (_jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(ThemeProvider, { theme: theme, children: _jsx(RenderPage, { page: currentPageVersion, handlePageChange: function (__p) {
                    throw new Error("Function not implemented.");
                }, editionMode: false, handleTemplateSettingsChange: function (__c) {
                    throw new Error("Function not implemented.");
                } }) }) }));
};
export default NoLayout;
