import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { FormTitle } from "administration/components/contentForms/ContentFormStyled";
import Icon from "shared/components/Icon";
import { getCategoryLabel } from "shared/enums/categoriesContents";
import { useThemeBackOffice } from "administration/theme/ThemeBackOfficeProvider";
import { getAllDefaultContents } from "administration/utils/adminContentsUtils";
import React from "react";
var AvailableContentsPreview = function () {
    var theme = useThemeBackOffice().themeBackOffice;
    var groupedContents = {};
    getAllDefaultContents()
        .filter(function (c) { return c.Preview; })
        .sort(function (a, b) { return a.label.localeCompare(b.label); })
        .forEach(function (content) {
        var category = content.category;
        groupedContents[category] = groupedContents[category] || [];
        groupedContents[category].push(content);
    });
    return Object.keys(groupedContents).map(function (category) { return (_jsxs(React.Fragment, { children: [_jsx("h2", { style: { textAlign: "center" }, children: getCategoryLabel(category) }), groupedContents[category].map(function (content) {
                var _a;
                var label = content.label, Preview = content.Preview, icon = content.icon, IconComponent = content.IconComponent, type = content.type;
                var contentIcon = null;
                if (icon) {
                    contentIcon = _jsx(Icon, { icon: icon });
                }
                else if (IconComponent) {
                    contentIcon = (_jsx(IconComponent, { color: (_a = theme === null || theme === void 0 ? void 0 : theme.palette["layout"].contentBtnBackground) !== null && _a !== void 0 ? _a : "inherit", backgroundColor: theme === null || theme === void 0 ? void 0 : theme.palette["layout"].contentBtnColor }));
                }
                return (_jsxs(Box, { mb: 8, children: [_jsxs(FormTitle, { children: [contentIcon, contentIcon && _jsx("span", { children: "\u00A0\u00A0" }), label] }), Preview && _jsx(Preview, {})] }, type));
            })] }, category)); });
};
export default AvailableContentsPreview;
