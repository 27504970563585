import BlockWithTitlePreview from "../components/BlockWithTitle/BlockWithTitlePreview";
import ContentsTypes from "shared/enums/contentsTypes";
import ContentsCustomTypes from "enums/contentsCustomTypes.enum";
export var BlockWithTitle = {
    label: "Bloc avec titre",
    type: ContentsCustomTypes.BLOCK_WITH_TITLE,
    Preview: BlockWithTitlePreview,
    icon: "window",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Image",
            key: "image",
            type: ContentsTypes.IMAGE,
            value: "",
        },
        {
            label: "Titre",
            key: "title",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Alignement de titre",
            key: "justify",
            type: ContentsTypes.SELECT,
            options: [
                { label: "À gauche", value: "flex-start" },
                { label: "À droite", value: "flex-end" },
            ],
            value: "flex-start",
        },
        {
            label: "Couleur de fond",
            key: "backgroundColor",
            type: ContentsTypes.COLOR,
            value: "#FFFFFF",
        },
        {
            key: "dynamicPart",
            type: ContentsTypes.DYNAMIC,
            value: "",
            label: "",
        },
    ],
};
