import ContentsTypes from "shared/enums/contentsTypes";
import DescriptionCardWithLogoPreview from "components/DescriptionCardWithLogo/DescriptionCardWithLogoPreview";
export var DescriptionCardWithLogo = {
    label: "Carte description avec logo",
    type: ContentsTypes.DESCRIPTION_CARD_WITH_LOGO,
    Preview: DescriptionCardWithLogoPreview,
    icon: "address-card",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "",
            key: "redirection",
            type: ContentsTypes.PAGE,
            value: "",
        },
        {
            label: "Logo",
            key: "logo",
            type: ContentsTypes.IMAGE,
        },
        {
            label: "Titre",
            key: "title",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Description",
            key: "description",
            type: ContentsTypes.SHORT_TEXT,
            value: "",
        },
    ],
};
