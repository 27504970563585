import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useKeycloak } from "@react-keycloak/web";
import AdminVersions from "administration/components/AdminVersions/AdminVersions";
import useEnvironment from "shared/hooks/useEnvironment";
import AdminSectionsService from "administration/services/AdminSectionsService";
import { SectionVersionRole } from "shared/types/cms/common/roles.enum";
import { PageVersionStatus } from "shared/types/cms/page/version/page-version.status.enum";
import { useCallback, useEffect, useState } from "react";
import LoadingPage from "./Layout/LoadingPage";
import { useSites } from "shared/hooks/SiteHook";
var AdminSection = function (props) {
    var title = props.title, type = props.type, children = props.children, _a = props.onSectionChanged, onSectionChanged = _a === void 0 ? null : _a;
    var keycloak = useKeycloak().keycloak;
    var keycloak_client_cms = useEnvironment().keycloak_client_cms;
    var canPublish = keycloak.hasResourceRole(SectionVersionRole.UPDATE_SECTION_VERSION_STATUS, keycloak_client_cms);
    var canUpdate = keycloak.hasResourceRole(SectionVersionRole.UPDATE_SECTION_VERSION, keycloak_client_cms);
    var canDelete = keycloak.hasResourceRole(SectionVersionRole.DELETE_SECTION_VERSION, keycloak_client_cms);
    var _b = useState([]), sectionsVersions = _b[0], setSectionsVersions = _b[1];
    var _c = useState(null), currentSection = _c[0], setCurrentSection = _c[1];
    var _d = useState(null), sectionForm = _d[0], setSectionForm = _d[1];
    useEffect(function () {
        setSectionForm(currentSection);
    }, [currentSection]);
    var currentSite = useSites().currentSite;
    var getSections = useCallback(function () {
        if (currentSite) {
            return AdminSectionsService.getSectionVersions({ type: type, siteId: currentSite.id }).then(function (sections) {
                sections.sort(function (_a, _b) {
                    var status1 = _a.status;
                    var status2 = _b.status;
                    if (status1 === status2 || ![status1, status2].includes(PageVersionStatus.ARCHIVED)) {
                        return 0;
                    }
                    return status1 === PageVersionStatus.ARCHIVED ? 1 : -1;
                });
                setSectionsVersions(sections);
                return sections;
            });
        }
    }, [currentSite, type]);
    var setCurrentSectionVersion = useCallback(function (section) {
        var sectionsPromise = getSections();
        if (sectionsPromise) {
            sectionsPromise.then(function (sections) {
                section ? setCurrentSection(sections === null || sections === void 0 ? void 0 : sections.find(function (s) { return s.id === section.id; })) : setCurrentSection(sections[0]);
            });
        }
    }, [getSections]);
    useEffect(function () {
        setCurrentSectionVersion();
    }, [setCurrentSectionVersion]);
    var sectionChangedCallback = useCallback(function (response) {
        if (typeof onSectionChanged === "function") {
            onSectionChanged(response);
        }
        return response;
    }, [onSectionChanged]);
    var handleSaveVersion = function (section) {
        if (section.status === PageVersionStatus.DRAFT) {
            return AdminSectionsService.update(section)
                .then(sectionChangedCallback)
                .catch(function () {
                setCurrentSectionVersion(section);
                // displayError("Erreur lors de la mise à jour du brouillon");
            });
        }
        return AdminSectionsService.create(section)
            .then(sectionChangedCallback)
            .catch(function () {
            setCurrentSectionVersion(section);
            // displayError("Erreur lors de la création du brouillon");
        });
    };
    var updateVersionStatus = function (_a) {
        var version = _a.version, status = _a.status;
        return AdminSectionsService.updateStatus(version, status)
            .then(sectionChangedCallback)
            .catch(function () {
            // displayError("Erreur lors de la mise à jour du statut de la version");
        });
    };
    var deleteVersion = function (version) { return AdminSectionsService.delete(version).then(sectionChangedCallback); };
    return (_jsx(_Fragment, { children: sectionForm ? (_jsx(AdminVersions, { title: title, versions: sectionsVersions, currentVersion: sectionForm, setCurrentVersion: setCurrentSectionVersion, onVersionChange: setSectionForm, saveVersion: handleSaveVersion, canUnpublish: false, canPublish: canPublish, canUpdate: canUpdate, canDelete: canDelete, canRename: canUpdate, updateStatus: updateVersionStatus, deleteVersion: deleteVersion, children: function (handleVersionChange) { return children({ section: sectionForm, onSectionChange: handleVersionChange }); } })) : (_jsx(LoadingPage, { title: "Chargement...", sx: { height: "100%" } })) }));
};
export default AdminSection;
