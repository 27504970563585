var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Grid, Link, Popover } from "@mui/material";
import { StyledEngineProvider, ThemeProvider, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { useKeycloak } from "@react-keycloak/web";
import AdminVersions from "administration/components/AdminVersions/AdminVersions";
import AdminVersionsItemAction from "administration/components/AdminVersionsItemAction";
import RenderPage from "administration/components/Layout/RenderPage";
import ModalConfirm from "administration/components/ModalConfirm";
import Icon from "shared/components/Icon";
import useEnvironment from "shared/hooks/useEnvironment";
import AdminPagesService from "administration/services/AdminPagesService";
import AdminPageVersionsService from "administration/services/AdminPageVersionsService";
import { useSites } from "shared/hooks/SiteHook";
import AdminSitesService from "administration/services/AdminSitesService";
import { PageRole, PageVersionRole } from "shared/types/cms/common/roles.enum";
import { PageVersionStatus } from "shared/types/cms/page/version/page-version.status.enum";
import { adminProperties } from "administration/utils/adminContentsUtils";
import { removeProperties } from "shared/utils/commonUtils";
import CmsHooks from "shared/utils/register/CmsHooks";
import React from "react";
import { v4 as uuidV4 } from "uuid";
import { HOOKS } from "shared/utils/register/definitions";
import { useShallow } from "zustand/react/shallow";
import useSnackbarStore from "shared/store/SnackbarStore";
import usePageChangeStore from "administration/store/PageChangeStore";
import { useCurrentPage, useHomePageLocation } from "administration/hooks/AdminPageHook";
import useAdminPageVersionStore from "administration/store/AdminPageVersionStore";
import { usePageVersion } from "administration/hooks/AdminPageVersionHook";
var responsiveViews = [
    {
        key: "mobile",
        label: "Mobile",
        width: 414,
        height: 736,
        icon: "mobile",
    },
    {
        key: "tablet portrait",
        label: "Tablette (portrait)",
        width: 748,
        height: 1024,
        icon: "tablet",
    },
    {
        key: "tablet landscape",
        label: "Tablette (paysage)",
        width: 1024,
        height: 748,
        icon: "tablet",
        style: { transform: "rotate(-90deg)" },
    },
];
export var SiteGroup = {
    name: "site",
    order: 2,
};
export var PageGroup = {
    name: "page",
    order: 3,
};
export var MediaGroup = {
    name: "media",
    order: 4,
};
export var ContentsGroup = {
    name: "contents",
    order: 5,
};
export var DataGroup = {
    name: "data",
    order: 6,
};
var Warning = styled("span")({
    color: "#f44336",
    fontWeight: 600,
});
var isHomePage = function (version) {
    if (!version) {
        return false;
    }
    var page = version.page;
    return page && page.parentId === null && version.path === "/";
};
var AdminVersionsPrincipal = function (props) {
    var _a, _b;
    var currentSiteTheme = props.currentSiteTheme, pageVersion = props.pageVersion, setCurrentPageVersion = props.setCurrentPageVersion, setPageVersion = props.setPageVersion, handleSaveVersion = props.handleSaveVersion, setModal = props.setModal, closeModal = props.closeModal, canUpdateVersion = props.canUpdateVersion, lockedTemplate = props.lockedTemplate, pageRef = props.pageRef, handleClickAdminPage = props.handleClickAdminPage;
    var key = React.useState(uuidV4())[0];
    var _c = React.useState(true), editionModeState = _c[0], setEditionModeState = _c[1];
    var _d = React.useState(null), responsiveView = _d[0], setResponsiveView = _d[1];
    var _e = React.useState(null), responsiveViewSelectorAnchorEl = _e[0], setResponsiveViewSelectorAnchorEl = _e[1];
    var editionMode = editionModeState && canUpdateVersion;
    var excludedTemplatesForDelete = React.useMemo(function () { return CmsHooks.getHook(HOOKS.TEMPLATES_excludedTemplatesForPageDelete) || []; }, []);
    var lockedTemplateForDelete = React.useMemo(function () { return pageVersion && Boolean(excludedTemplatesForDelete.find(function (template) { return template.key === (pageVersion === null || pageVersion === void 0 ? void 0 : pageVersion.template); })); }, [excludedTemplatesForDelete, pageVersion]);
    var chosenDevice = React.useMemo(function () { return responsiveViews.find(function (res) { return res.key === (responsiveView && responsiveView.key); }); }, [responsiveView]);
    var themeBackOffice = useTheme();
    var keycloak = useKeycloak().keycloak;
    var environment = useEnvironment();
    var _f = useSites(), currentSite = _f.currentSite, refreshSite = _f.refreshSite;
    var _g = usePageChangeStore(useShallow(function (state) { return ({
        pageChanges: state.pageChanges,
        setPageChanges: state.setPageChanges,
    }); })), pageChanges = _g.pageChanges, setPageChanges = _g.setPageChanges;
    var _h = useAdminPageVersionStore(useShallow(function (state) { return ({
        versionHasChanged: state.versionHasChanged,
        currentPageVersion: state.currentPageVersion,
    }); })), versionHasChanged = _h.versionHasChanged, currentPageVersion = _h.currentPageVersion;
    var pageVersions = usePageVersion().pageVersions;
    var _j = useSnackbarStore(function (state) { return ({
        displaySuccess: state.displaySuccess,
        displayError: state.displayError,
    }); }), displaySuccess = _j.displaySuccess, displayError = _j.displayError;
    var homePage = useHomePageLocation().homePage;
    var setCurrentPage = useCurrentPage().setCurrentPage;
    var canPublishVersion = keycloak.hasResourceRole(PageVersionRole.UPDATE_PAGE_VERSION_STATUS, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms) &&
        !lockedTemplate;
    var canUnpublish = React.useMemo(function () { return !isHomePage(pageVersion); }, [pageVersion]) &&
        keycloak.hasResourceRole(PageVersionRole.UPDATE_PAGE_VERSION_STATUS, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms) &&
        !lockedTemplate;
    var canDeleteVersion = keycloak.hasResourceRole(PageVersionRole.DELETE_PAGE_VERSION, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms) && !lockedTemplate;
    var canDeletePage = React.useMemo(function () {
        return !(pageVersions === null || pageVersions === void 0 ? void 0 : pageVersions.find(function (version) { return version.status === PageVersionStatus.PUBLISHED; })) &&
            keycloak.hasResourceRole(PageRole.DELETE_PAGE, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms) &&
            !lockedTemplateForDelete;
    }, [keycloak, environment, lockedTemplateForDelete, pageVersions]);
    var handleUndo = React.useCallback(function () {
        var pageChange = __assign({}, pageChanges[pageChanges.length - 2]);
        setPageVersion(pageChange);
        var changes = __spreadArray([], pageChanges, true);
        changes.splice(changes.length - 2, 2);
        setPageChanges(changes);
    }, [pageChanges, setPageChanges, setPageVersion]);
    var handleClickResponsive = React.useCallback(function (event) {
        setResponsiveViewSelectorAnchorEl(event.currentTarget);
    }, []);
    var handleClickView = React.useCallback(function (view) { return function () {
        setResponsiveView(view);
        setResponsiveViewSelectorAnchorEl(null);
    }; }, []);
    var handleDeletePageVersion = function (version) { return AdminPageVersionsService.delete(version); };
    var handleDeletePage = React.useCallback(function () {
        var _a;
        setModal({
            content: (_jsx(ModalConfirm, { title: "Supprimer \"".concat((_a = currentPageVersion === null || currentPageVersion === void 0 ? void 0 : currentPageVersion.page) === null || _a === void 0 ? void 0 : _a.name, "\""), text: _jsxs(Box, { pb: 2, children: ["Attention cette action est irr\u00E9versible et entra\u00EEne :", _jsx("br", {}), _jsxs("ul", { children: [_jsxs("li", { children: ["La suppression de ", _jsx(Warning, { children: "cet article ainsi que de toutes ses versions" })] }), _jsxs("li", { children: ["La suppression de ", _jsx(Warning, { children: "tous les sous-articles" }), " li\u00E9s \u00E0 cet article"] })] })] }), confirmText: "Supprimer", error: true, onConfirm: function () {
                    AdminPagesService.delete(currentPageVersion === null || currentPageVersion === void 0 ? void 0 : currentPageVersion.page).then(function () {
                        setCurrentPage(homePage.page);
                    });
                    closeModal();
                }, onCancel: closeModal })),
            size: "sm",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeModal, currentPageVersion, themeBackOffice.palette.error.main]);
    var updateVersionStatus = React.useCallback(function (_a) {
        var version = _a.version, status = _a.status;
        return AdminPageVersionsService.updateStatus(version, status).catch(function () {
            displayError("Erreur lors de la mise à jour du statut de la version");
        });
    }, [displayError]);
    var updateTemplateSettings = React.useCallback(function (newTemplateSettings) {
        if (!currentSite)
            return;
        AdminSitesService.update({
            id: currentSite.id,
            title: currentSite.title,
            contents: currentSite.contents.map(function (c) {
                return c.key === "templatesSettings" ? removeProperties(newTemplateSettings, adminProperties) : c;
            }),
        })
            .then(function () {
            refreshSite();
            displaySuccess("Le gabarit a été mis à jour.");
        })
            .catch(function () {
            displayError("Une erreur est survenue.");
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSite, displayError, displaySuccess, refreshSite]);
    return (_jsx(AdminVersions, { principal: true, showVersion: !responsiveView, canDelete: canDeleteVersion, canPublish: canPublishVersion, canRename: canUpdateVersion, manualSave: canUpdateVersion, leftActions: _jsxs(Grid, { container: true, alignItems: "center", style: { height: "100%" }, children: [canUpdateVersion && (_jsxs(AdminVersionsItemAction, { onClick: function () {
                        setEditionModeState(!editionMode);
                        setResponsiveView(null);
                    }, title: editionMode ? "Mode prévisualisation" : "Mode édition", children: [_jsx(Icon, { title: editionMode ? "Mode prévisualisation" : "Mode édition", icon: editionMode ? "eye" : "edit", type: "far", color: (_b = (_a = themeBackOffice === null || themeBackOffice === void 0 ? void 0 : themeBackOffice.palette) === null || _a === void 0 ? void 0 : _a.layout) === null || _b === void 0 ? void 0 : _b.icon }), _jsx(Box, { component: "span", sx: { ml: 2 }, children: editionMode ? "Mode prévisualisation" : "Mode édition" })] })), !editionMode && (_jsxs(_Fragment, { children: [_jsx(AdminVersionsItemAction, { title: "Ordinateur", onClick: handleClickView(null), selected: !responsiveView, children: _jsx(Icon, { icon: "laptop", type: "fal" }) }), _jsx(AdminVersionsItemAction, { title: "Responsive", onClick: handleClickResponsive, selected: !!responsiveView, children: (chosenDevice === null || chosenDevice === void 0 ? void 0 : chosenDevice.Icon) || (_jsx(Icon, { icon: "phone-laptop", type: "fal", color: themeBackOffice && themeBackOffice.palette.layout.icon })) }), _jsx(Popover, { open: !!responsiveViewSelectorAnchorEl, anchorEl: responsiveViewSelectorAnchorEl, onClose: function () { return setResponsiveViewSelectorAnchorEl(null); }, PaperProps: {
                                style: {
                                    height: "56px",
                                    maxHeight: "none",
                                    borderRadius: 0,
                                    backgroundColor: themeBackOffice && themeBackOffice.palette.layout.background,
                                    borderTop: "1px solid ".concat(themeBackOffice && themeBackOffice.palette.layout.border),
                                },
                            }, anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            }, transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            }, children: _jsx(Grid, { container: true, style: { height: "100%" }, children: responsiveViews.map(function (view) { return (_jsx(AdminVersionsItemAction, { title: view.label, onClick: handleClickView(view), selected: view.key === (chosenDevice && chosenDevice.key), children: _jsx(Icon, { icon: view.icon, type: "fal", color: themeBackOffice && themeBackOffice.palette.layout.icon, style: view.style }) }, uuidV4())); }) }) })] })), editionMode && (_jsx(AdminVersionsItemAction, { onClick: handleClickAdminPage, title: "Param\u00E8tres de la page", children: _jsx(Icon, { icon: "file-alt", title: "Param\u00E8tres de la page", color: themeBackOffice && themeBackOffice.palette.layout.icon }) })), !responsiveView && pageChanges.length > 1 && (_jsx(AdminVersionsItemAction, { onClick: handleUndo, title: "Annuler la derni\u00E8re action", children: _jsx(Icon, { title: "Annuler la derni\u00E8re action", icon: "undo", color: themeBackOffice && themeBackOffice.palette.layout.icon, style: {
                            fontWeight: "bold",
                            fontSize: "1.18rem",
                        } }) }))] }), rightActions: canDeletePage && (_jsx(Link, { href: "#", color: "error", onClick: handleDeletePage, children: "Supprimer cette page" })), versions: pageVersions, currentVersion: pageVersion, setCurrentVersion: setCurrentPageVersion, onVersionChange: setPageVersion, saveVersion: handleSaveVersion, canUnpublish: canUnpublish, updateStatus: updateVersionStatus, deleteVersion: handleDeletePageVersion, autoSaveDelay: (environment === null || environment === void 0 ? void 0 : environment.autosave_delay) || 0, children: function (handleVersionChange) { return (_jsx(Box, { sx: { position: "relative", flex: 1, height: "100%" }, children: _jsx(Box, { sx: { position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }, children: editionMode || !responsiveView ? (_jsx(Box, { ref: pageRef, sx: { position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }, children: _jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(ThemeProvider, { theme: currentSiteTheme, children: pageVersion.id && (_jsx(RenderPage, { page: pageVersion, handlePageChange: handleVersionChange, handleTemplateSettingsChange: updateTemplateSettings, editionMode: editionMode }, key)) }) }) })) : (_jsxs(Grid, { container: true, direction: "column", alignItems: "center", sx: { height: !versionHasChanged ? "100%" : "auto" }, children: [versionHasChanged && (_jsx(Grid, { item: true, sx: {
                                bgcolor: "primary.main",
                                color: "primary.textDisabled",
                                p: 2,
                                borderRadius: 2,
                                my: 3,
                                maxWidth: "".concat(responsiveView.width, "px"),
                            }, children: _jsxs(Grid, { container: true, alignItems: "center", wrap: "nowrap", children: [_jsx(InfoOutlinedIcon, { sx: { mr: 1 } }), _jsxs(Typography, { children: ["Vous avez des modifications en attente. ", _jsx("b", { children: "Enregistrez-les" }), " pour les visualiser en vue adaptative."] })] }) })), _jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", sx: { height: !versionHasChanged ? "100%" : "auto" }, children: _jsx("iframe", { title: "No layout", src: "/noLayout".concat(window.location.search), style: {
                                    resize: "both",
                                    height: "".concat(responsiveView.height, "px"),
                                    width: "".concat(responsiveView.width, "px"),
                                } }) })] })) }) })); } }));
};
export default AdminVersionsPrincipal;
