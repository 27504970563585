import ContentsTypes from "shared/enums/contentsTypes";
import InputForm from "../InputForm";
import ColorForm from "../ColorForm";
import TextAreaForm from "../TextAreaForm";
import CheckboxForm from "../CheckboxForm";
import SelectForm from "../SelectForm";
import SelectGridForm from "../GridForm/SelectGridForm";
import ImageForm from "../ImageForm";
import ImagesForm from "../ImagesForm";
import SliderForm from "../SliderForm";
import RadioGroupForm from "../RadioGroupForm";
import FileForm from "../FileForm";
import LinkForm from "../LinkForm";
import CardForm from "../CardForm";
import SubpageForm from "../SubpageForm";
import IconForm from "../IconForm";
import TabForm from "../TabForm";
import TabsForm from "../TabsForm";
import CodeBlockForm from "../CodeBlockForm";
import CmsHooks from "shared/utils/register/CmsHooks";
import { HOOKS } from "shared/utils/register/definitions";
import SiteSettingsForm from "../SiteSettingsForm";
var FormFactory = /** @class */ (function () {
    function FormFactory() {
    }
    FormFactory.hydrate = function (type) {
        if (!FormFactory.Form) {
            switch (type) {
                case ContentsTypes.EDITABLE_INPUT:
                case ContentsTypes.INPUT:
                case ContentsTypes.URL:
                case ContentsTypes.NUMERIC:
                    FormFactory.Form = InputForm;
                    break;
                case ContentsTypes.COLOR:
                    FormFactory.Form = ColorForm;
                    break;
                case ContentsTypes.INPUT_NUMBER:
                    FormFactory.formProps = { type: "number" };
                    FormFactory.Form = InputForm;
                    break;
                case ContentsTypes.SHORT_TEXT:
                    FormFactory.Form = TextAreaForm;
                    break;
                case ContentsTypes.CHECKBOX:
                    FormFactory.Form = CheckboxForm;
                    break;
                case ContentsTypes.SELECT:
                    FormFactory.Form = SelectForm;
                    break;
                case ContentsTypes.SELECT_GRID:
                    FormFactory.Form = SelectGridForm;
                    break;
                case ContentsTypes.IMAGE:
                case ContentsTypes.IMAGE_AS_FILE:
                    FormFactory.Form = ImageForm;
                    break;
                case ContentsTypes.IMAGES:
                    FormFactory.Form = ImagesForm;
                    break;
                case ContentsTypes.SLIDER:
                    FormFactory.Form = SliderForm;
                    break;
                case ContentsTypes.RADIO_GROUP:
                    FormFactory.Form = RadioGroupForm;
                    break;
                case ContentsTypes.FILE_IMAGE:
                    FormFactory.formProps = { image: true };
                    FormFactory.Form = FileForm;
                    break;
                case ContentsTypes.FILE:
                    FormFactory.Form = FileForm;
                    break;
                case ContentsTypes.LINK:
                    FormFactory.Form = LinkForm;
                    break;
                case ContentsTypes.CARD:
                    FormFactory.Form = CardForm;
                    break;
                case ContentsTypes.SUBPAGE:
                case ContentsTypes.PAGE:
                    FormFactory.Form = SubpageForm;
                    break;
                case ContentsTypes.ICON:
                    FormFactory.Form = IconForm;
                    break;
                case ContentsTypes.TAB_CARDS:
                    FormFactory.Form = TabForm;
                    break;
                case ContentsTypes.TABS:
                case ContentsTypes.TABS_CARDS:
                    FormFactory.Form = TabsForm;
                    break;
                case ContentsTypes.SITE_SETTINGS:
                    FormFactory.Form = SiteSettingsForm;
                    break;
                case ContentsTypes.CODE_BLOCK_TEXT:
                    FormFactory.Form = CodeBlockForm;
                    break;
                default:
                    FormFactory.Form = null;
                    break;
            }
        }
    };
    FormFactory.init = function (type) {
        if (!FormFactory.instance) {
            FormFactory.instance = new FormFactory();
        }
        var getFormComponentFromProject = CmsHooks.getHook(HOOKS.ContentForm_getFormComponent);
        if (typeof getFormComponentFromProject === "function") {
            FormFactory.Form = getFormComponentFromProject({ type: type });
        }
        if (!FormFactory.Form) {
            FormFactory.hydrate(type);
        }
        return FormFactory.instance;
    };
    Object.defineProperty(FormFactory.prototype, "getForm", {
        get: function () {
            return {
                Form: FormFactory.Form,
                formProps: FormFactory.formProps,
            };
        },
        enumerable: false,
        configurable: true
    });
    FormFactory.Form = null;
    FormFactory.formProps = {};
    return FormFactory;
}());
export default FormFactory;
