var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormHelperText, Grid } from "@mui/material";
import SelectPage from "../SelectPage";
var AdminPageParentPageForm = function (_a) {
    var form = _a.form, setForm = _a.setForm, site = _a.site, canUpdateParent = _a.canUpdateParent, formSubmitted = _a.formSubmitted, errors = _a.errors, parentId = _a.parentId;
    var handleSelectParentPage = function (parentPage) {
        setForm(__assign(__assign({}, form), { page: __assign(__assign({}, form.page), { parentId: parentPage.id }) }));
    };
    var showError = function (key) { return formSubmitted && errors[key]; };
    return (_jsx(Grid, { item: true, xs: 4, children: _jsxs(FormControl, { error: showError("parentId"), children: [_jsx(SelectPage, { currentPage: { id: parentId }, onSelectPage: handleSelectParentPage, disabled: !canUpdateParent, label: "Page Parente", onlyPublishedPages: true, site: site }), showError("parentId") && _jsx(FormHelperText, { children: "Veuillez s\u00E9lectionner une page parente" })] }) }));
};
export default AdminPageParentPageForm;
