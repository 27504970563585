import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useCurrentPage, useHomePageLocation } from "administration/hooks/AdminPageHook";
import useAdminEditorStore from "administration/store/AdminEditorStore";
export var AdminPageProvider = function (_a) {
    var children = _a.children;
    var _b = useHomePageLocation(), homePage = _b.homePage, isLoading = _b.isLoading;
    var currentPageId = useAdminEditorStore(function (state) { return ({ currentPageId: state.currentPageId }); }).currentPageId;
    var setCurrentPage = useCurrentPage({
        onError: function () {
            setCurrentPage(homePage.page);
        },
    }).setCurrentPage;
    useEffect(function () {
        if (!currentPageId && homePage) {
            setCurrentPage(homePage.page);
        }
    }, [homePage, setCurrentPage, currentPageId]);
    if (isLoading) {
        return null;
    }
    return _jsx(_Fragment, { children: children });
};
