var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, TextField } from "@mui/material";
var DESCRIPTION_MIN_CHARACTER = 50;
var DESCRIPTION_MAX_CHARACTER = 160;
var AdminPageDescriptionForm = function (_a) {
    var form = _a.form, setForm = _a.setForm, description = _a.description;
    var handleFormChange = function (e) {
        var _a;
        var _b = e.target, value = _b.value, fieldName = _b.name;
        setForm(__assign(__assign({}, form), (_a = {}, _a[fieldName] = value, _a)));
    };
    var characterCountDescription = (description && description.length) || 0;
    return (_jsx(Grid, { container: true, children: _jsx(Grid, { item: true, xs: 12, children: _jsxs(Box, { mt: 1, children: [_jsx(TextField, { label: "Description de la page (meta-donn\u00E9e de r\u00E9f\u00E9rencement). ".concat(DESCRIPTION_MIN_CHARACTER, " caract\u00E8res minimum recommand\u00E9s."), value: description || "", name: "description", onChange: handleFormChange, multiline: true, rows: 5, inputProps: { maxLength: DESCRIPTION_MAX_CHARACTER } }), _jsx(Box, { mt: 1, children: "Caract\u00E8res : ".concat(characterCountDescription, "/").concat(DESCRIPTION_MAX_CHARACTER) })] }) }) }));
};
export default AdminPageDescriptionForm;
