var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, Grid, InputLabel, MenuItem } from "@mui/material";
import Select from "shared/components/Select";
import { useSites } from "shared/hooks/SiteHook";
var AdminPageDuplicateForm = function (_a) {
    var form = _a.form, setForm = _a.setForm, site = _a.site, setSite = _a.setSite;
    var _b = useSites(), currentSite = _b.currentSite, allSites = _b.allSites;
    var handleSiteChange = function (e) {
        var value = e.target.value;
        var site = allSites.find(function (s) { return s.id === value; });
        if (site) {
            setSite(site);
            setForm(__assign(__assign({}, form), { page: __assign(__assign({}, form.page), { parentId: "" }) }));
        }
    };
    return (_jsx(Box, { mb: 2, children: _jsx(Grid, { container: true, spacing: 1, children: _jsx(Grid, { item: true, xs: 4, children: _jsxs(FormControl, { children: [_jsx(InputLabel, { shrink: true, children: "Dans quel site ?" }), _jsx(Select, { value: (site === null || site === void 0 ? void 0 : site.id) || (currentSite === null || currentSite === void 0 ? void 0 : currentSite.id) || "", name: "site", onChange: handleSiteChange, wrapperProps: undefined, children: allSites === null || allSites === void 0 ? void 0 : allSites.map(function (site) { return (_jsx(MenuItem, { value: site.id, children: site.name }, site.id)); }) })] }) }) }) }));
};
export default AdminPageDuplicateForm;
