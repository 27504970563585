var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import { FormLabelTooltip } from "administration/components/contentForms/ContentFormStyled";
import { createRef, useCallback, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import useContentFormStore from "administration/store/ContentFormStore";
var ColorForm = function (props) {
    var content = props.content, onContentChange = props.onContentChange, _a = props.formSubmitted, formSubmitted = _a === void 0 ? false : _a, _b = props.required, required = _b === void 0 ? false : _b, _c = props.regexValidation, regexValidation = _c === void 0 ? null : _c, _d = props.tooltip, tooltip = _d === void 0 ? null : _d, _e = props.onClick, onClick = _e === void 0 ? null : _e, others = __rest(props, ["content", "onContentChange", "formSubmitted", "required", "regexValidation", "tooltip", "onClick"]);
    var id = content.id, label = content.label, value = content.value;
    var displayVertical = useContentFormStore(function (state) { return ({ displayVertical: state.displayVertical }); }).displayVertical;
    var _f = useState(false), displayPicker = _f[0], setDisplayPicker = _f[1];
    var labelRef = createRef();
    var pickerRef = createRef();
    var sketchPickerRef = createRef();
    var setPickerPosition = useCallback(function () {
        if (labelRef.current && pickerRef.current) {
            var position = labelRef.current.getBoundingClientRect();
            if (displayVertical) {
                pickerRef.current.style.right = "".concat(window.innerWidth - position.x + 8, "px");
                pickerRef.current.style.top = position.y < 350 ? "".concat(position.y, "px") : "".concat(position.y - 280, "px");
            }
            else {
                pickerRef.current.style.left = "".concat(position.x, "px");
                pickerRef.current.style.bottom = "".concat(window.innerHeight - position.y + 8, "px");
            }
        }
    }, [labelRef, pickerRef, displayVertical]);
    useEffect(function () {
        setPickerPosition();
    }, [setPickerPosition]);
    useEffect(function () {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", setPickerPosition, true);
        }
        return function () {
            window.removeEventListener("scroll", setPickerPosition);
        };
    }, [setPickerPosition]);
    var handleValidateColorPicker = function (color) {
        onContentChange(__assign(__assign({}, content), { value: color.source === "rgb" ? "rgba(".concat(color.rgb.r, ", ").concat(color.rgb.g, ", ").concat(color.rgb.b, ", ").concat(color.rgb.a, ")") : color.hex }));
    };
    var handleClickInput = function (e) {
        if (typeof onClick === "function") {
            onClick(e);
        }
        setDisplayPicker(true);
    };
    var handleInputChange = function (e) {
        onContentChange(__assign(__assign({}, content), { value: e.target.value }));
    };
    var showRequiredError = required && formSubmitted && !value;
    var showRegexValidationError = (regexValidation && formSubmitted && value && !RegExp(regexValidation).exec(value)) || undefined;
    useEffect(function () {
        var checkIfClickedOutsideSketchPicker = function (e) {
            if (displayPicker && sketchPickerRef.current && !sketchPickerRef.current.contains(e.target)) {
                setDisplayPicker(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutsideSketchPicker);
        return function () {
            document.removeEventListener("mousedown", checkIfClickedOutsideSketchPicker);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayPicker]);
    return (_jsxs(FormControl, { error: showRequiredError || showRegexValidationError, children: [_jsxs(InputLabel, { sx: { pointerEvents: "auto" }, ref: labelRef, children: [label, tooltip && (_jsx(FormLabelTooltip, { title: tooltip, children: _jsx(HelpIcon, {}) }))] }), _jsx(Input, __assign({ value: value, onChange: handleInputChange, onClick: handleClickInput }, others)), _jsx(Box, { sx: { position: "fixed", zIndex: "1800" }, ref: pickerRef, children: displayPicker && (_jsx("div", { ref: sketchPickerRef, children: _jsx(SketchPicker, { width: 250, color: value, onChangeComplete: handleValidateColorPicker }) })) }), showRequiredError && _jsx(FormHelperText, { error: true, children: "Champ obligatoire" }), showRegexValidationError && _jsxs(FormHelperText, { error: true, children: [label, " invalide"] })] }, id));
};
export default ColorForm;
