var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HelpIcon from "@mui/icons-material/Help";
import { FormControl, InputLabel, Slider } from "@mui/material";
import { FormLabelTooltip } from "administration/components/contentForms/ContentFormStyled";
var SliderForm = function (props) {
    var content = props.content, onContentChange = props.onContentChange, _a = props.tooltip, tooltip = _a === void 0 ? null : _a, others = __rest(props, ["content", "onContentChange", "tooltip"]);
    var id = content.id, label = content.label, value = content.value, min = content.min, max = content.max, step = content.step;
    var handleChange = function (event, newValue) {
        onContentChange(__assign(__assign({}, content), { value: String(newValue) }));
    };
    return (_jsxs(FormControl, { children: [_jsxs(InputLabel, { sx: { pointerEvents: "auto" }, children: [label, tooltip && (_jsx(FormLabelTooltip, { title: tooltip, children: _jsx(HelpIcon, {}) }))] }), _jsx(Slider, __assign({ value: Number(value), valueLabelDisplay: "auto", step: Number(step), marks: true, min: Number(min), max: Number(max), onChange: handleChange, sx: {
                    mt: 2,
                    mb: 0,
                    mx: "auto",
                    width: "90%",
                } }, others))] }, id));
};
export default SliderForm;
