var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import ContentsTypes from "shared/enums/contentsTypes";
import AdminListForm from "administration/components/contentForms/AdminListForm";
import { FormTitle } from "administration/components/contentForms/ContentFormStyled";
import InputForm from "administration/components/contentForms/InputForm";
var TabsForm = function (props) {
    var _a;
    var content = props.content, onContentChange = props.onContentChange;
    var label = content.label, children = content.children, type = content.type;
    var tabsTypes = [ContentsTypes.TAB, ContentsTypes.TAB_CARDS];
    var objectsChildren = (_a = children === null || children === void 0 ? void 0 : children.filter(function (c) { return tabsTypes.includes(c.type); })) !== null && _a !== void 0 ? _a : [];
    var handleChildChange = function (oldChild, newChild) {
        onContentChange(__assign(__assign({}, content), { children: children === null || children === void 0 ? void 0 : children.map(function (c) { return (c === oldChild ? newChild : c); }) }));
    };
    var handleChildrenChange = function (oldChildren, newChildren) {
        onContentChange(__assign(__assign({}, content), { children: newChildren }));
    };
    return (_jsxs(Fragment, { children: [_jsx(FormTitle, { children: label }), _jsx(AdminListForm, { inline: true, contents: objectsChildren, elementsType: type === ContentsTypes.TABS ? ContentsTypes.TAB : ContentsTypes.TAB_CARDS, elementsKey: "tabs", onContentsChange: function (newContents) { return handleChildrenChange(objectsChildren, newContents); }, children: objectsChildren.map(function (currentObject) {
                    var _a;
                    return (_jsx(InputForm, { content: __assign({}, (_a = currentObject.children) === null || _a === void 0 ? void 0 : _a.find(function (c) { return c.key === "title"; })), onContentChange: function (newContent) {
                            return handleChildChange(currentObject, __assign(__assign({}, currentObject), { children: (currentObject.children || []).map(function (c) { return (c.key === "title" ? newContent : c); }) }));
                        } }, currentObject.id));
                }) })] }));
};
export default TabsForm;
