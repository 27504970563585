var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CssBaseline from "@mui/material/CssBaseline";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import axios from "axios";
import clientAdmin from "clientAdmin";
import clientFront from "clientFront";
import LayoutBack from "administration/components/Layout/LayoutBack";
import LoadingPage from "administration/components/Layout/LoadingPage";
import NoLayout from "administration/components/Pages/NoLayout";
import ManageUsersPage from "administration/components/Pages/ManageUsersPage";
import ThemeBackOfficeProvider from "administration/theme/ThemeBackOfficeProvider";
import CmsHooks from "shared/utils/register/CmsHooks";
import AuthServiceInterface from "da-ged-web/interfaces/AuthServiceInterface";
import FileServiceInterface from "da-ged-web/interfaces/FileServiceInterface";
import FolderServiceInterface from "da-ged-web/interfaces/FolderServiceInterface";
import NodeServiceInterface from "da-ged-web/interfaces/NodeServiceInterface";
import Keycloak from "keycloak-js";
import merge from "lodash.merge";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { HOOKS } from "shared/utils/register/definitions";
import AuthService from "da-ged-web/services/AuthService";
import FileService from "da-ged-web/services/FileService";
import FolderService from "da-ged-web/services/FolderService";
import NodeService from "da-ged-web/services/NodeService";
import Snackbar from "shared/components/Snackbar";
import useSnackbarStore from "shared/store/SnackbarStore";
import { useShallow } from "zustand/react/shallow";
import Editor from "administration/components/Pages/Editor";
var keycloak = new Keycloak("/keycloak.json");
clientAdmin();
clientFront();
var interceptorBearerResolve = function (config) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, _b;
    return __generator(this, function (_c) {
        if (!((_a = config.url) === null || _a === void 0 ? void 0 : _a.startsWith("/")) && keycloak.authServerUrl && !((_b = config.url) === null || _b === void 0 ? void 0 : _b.includes(keycloak.authServerUrl))) {
            return [2 /*return*/, config];
        }
        config.headers.authorization = "Bearer ".concat(keycloak.token);
        return [2 /*return*/, config];
    });
}); };
var interceptorBearerReject = function (error) { return Promise.reject(error); };
axios.interceptors.request.use(interceptorBearerResolve, interceptorBearerReject);
var axiosInstance = axios.create();
axiosInstance.interceptors.request.use(interceptorBearerResolve, interceptorBearerReject);
var config = { API_GED_URL: "/ged", axios: axiosInstance };
FolderService.config = config;
AuthService.config = config;
FileService.config = config;
NodeService.config = config;
AuthServiceInterface.setImplementation(AuthService);
NodeServiceInterface.setImplementation(NodeService);
FileServiceInterface.setImplementation(FileService);
FolderServiceInterface.setImplementation(FolderService);
var hookQueryClientOptions = CmsHooks.getHook(HOOKS.clientAdmin_queryClientOptions) || {};
var queryClientOptions = merge({}, {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: function (failureCount, error) {
                var status = ((error === null || error === void 0 ? void 0 : error.response) || {}).status;
                if (status && status >= 400 && status < 500) {
                    return false;
                }
                return failureCount < 3;
            },
        },
    },
}, hookQueryClientOptions);
var queryClient = new QueryClient(queryClientOptions);
var App = function () {
    var projectRoutes = CmsHooks.getHook(HOOKS.Bo_Routes);
    var adminWebsitePath = CmsHooks.getHook(HOOKS.Bo_AdminWebsitePath);
    var _a = useSnackbarStore(useShallow(function (state) { return ({
        snackbarProps: { open: state.open, duration: state.duration, queue: state.queue },
        handleCloseSnackbar: state.handleCloseSnackbar,
        handleErrorStack: state.handleErrorStack,
    }); })), snackbarProps = _a.snackbarProps, handleCloseSnackbar = _a.handleCloseSnackbar, handleErrorStack = _a.handleErrorStack;
    var adminUsersPath = CmsHooks.getHook(HOOKS.Bo_AdminUsersPath);
    return (_jsxs(ReactKeycloakProvider, { authClient: keycloak, initOptions: {
            onLoad: "login-required",
            checkLoginIframe: false,
            pkceMethod: "S256",
        }, LoadingComponent: _jsx(LoadingPage, { title: "Authentification" }), children: [_jsx(Router, { children: _jsxs(QueryClientProvider, { client: queryClient, children: [window["__REACT_QUERY_DEBUG__"] && _jsx(ReactQueryDevtools, { initialIsOpen: false }), _jsxs(ThemeBackOfficeProvider, { children: [_jsx(CssBaseline, {}), _jsxs(LayoutBack, { children: [_jsx(Route, { path: "/noLayout", children: _jsx(NoLayout, {}) }), _jsx(Route, { path: adminUsersPath, exact: true, children: _jsx(ManageUsersPage, {}) }), _jsx(Route, { path: adminWebsitePath, exact: true, children: _jsx(Editor, {}) }), _jsx(Redirect, { from: "*", to: adminWebsitePath }), projectRoutes] })] })] }) }), _jsx(Snackbar, __assign({}, snackbarProps, { onClose: handleCloseSnackbar, handleErrorStack: handleErrorStack }))] }));
};
export default App;
var root = createRoot(document.getElementById("root"));
root.render(_jsx(App, {}));
