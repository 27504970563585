import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
var visuallyHiddenStyled = {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    m: "-1px",
    overflow: "hidden",
    p: 0,
    position: "absolute",
    top: 20,
    width: 1,
};
var EnhancedTableHead = function (props) {
    var order = props.order, orderBy = props.orderBy, columns = props.columns;
    // const createSortHandler = property => event => {
    //   onRequestSort(event, property);
    // };
    return (_jsx(TableHead, { children: _jsx(TableRow, { children: columns.map(function (headCell) { return (_jsx(TableCell, { align: headCell.numeric ? "right" : "left", padding: headCell.disablePadding ? "none" : "normal", sortDirection: orderBy === headCell.id ? order : false, children: _jsxs(TableSortLabel, { hideSortIcon: !headCell.sortable, active: orderBy === headCell.id, direction: order, children: [headCell.label, orderBy === headCell.id ? (_jsx(Box, { component: "span", sx: visuallyHiddenStyled, children: order === "desc" ? "sorted descending" : "sorted ascending" })) : null] }) }, headCell.id)); }) }) }));
};
export default EnhancedTableHead;
