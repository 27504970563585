import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import ContentIcon from "./ContentIcon";
var ContentItem = function (props) {
    var content = props.content, onClick = props.onClick;
    var label = content.label;
    return (_jsxs(Box, { sx: {
            p: 1,
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
            alignItems: "center",
            textAlign: "center",
            "&:hover": {
                bgcolor: "secondary.dark",
            },
        }, onClick: onClick, children: [_jsx(Box, { sx: { width: 24, height: 24, fontSize: 24, mb: 1 }, children: _jsx(ContentIcon, { content: content }) }), _jsx(Typography, { variant: "body2", children: label })] }));
};
export default ContentItem;
