var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import Icon from "shared/components/Icon";
import { useCallback, useEffect, useState } from "react";
import AdminMenuForm from "./AdminMenuForm";
var COLUMN_KEY = "LinkInternal";
var AdminFooterForm = function (props) {
    var footer = props.footer, onFooterChange = props.onFooterChange, _a = props.canUpdate, canUpdate = _a === void 0 ? false : _a;
    var _b = footer.contents, contents = _b === void 0 ? [] : _b;
    var sortItemsByColumn = function (a, b) {
        if (a.length < b.length)
            return -1;
        if (a.length > b.length)
            return 1;
        return a.localeCompare(b);
    };
    var getColumnsByContents = useCallback(function () {
        var columnsByContents = contents.reduce(function (list, content) {
            if (!list.includes(content.key))
                list.push(content.key);
            return list;
        }, []);
        if (columnsByContents.length === 0)
            return [COLUMN_KEY];
        return columnsByContents.sort(sortItemsByColumn);
    }, [contents]);
    var _c = useState(getColumnsByContents()), columns = _c[0], setColumns = _c[1];
    useEffect(function () {
        setColumns(getColumnsByContents());
    }, [getColumnsByContents]);
    var handleItemsChange = function (columnKey) { return function (items) {
        onFooterChange(__assign(__assign({}, footer), { contents: __spreadArray(__spreadArray([], contents.filter(function (child) { return child.key !== columnKey; }), true), items.contents, true).sort(function (a, b) {
                return sortItemsByColumn(a.key, b.key);
            }) }));
    }; };
    var handleClickAddColumn = function () {
        var lastColumn = columns[columns.length - 1];
        var keySuffix = lastColumn.replace(COLUMN_KEY, "");
        setColumns(__spreadArray(__spreadArray([], columns, true), ["".concat(COLUMN_KEY).concat(Number(keySuffix || "0") + 1)], false));
    };
    var handleMove = function (originIndex, targetIndex) {
        var columnOrigin = columns[originIndex];
        var columnTarget = columns[targetIndex];
        onFooterChange(__assign(__assign({}, footer), { contents: contents
                .map(function (content) {
                var contentTmp = __assign({}, content);
                if (content.key === columnOrigin) {
                    contentTmp.key = columnTarget;
                }
                else if (content.key === columnTarget) {
                    contentTmp.key = columnOrigin;
                }
                return contentTmp;
            })
                .sort(function (a, b) { return sortItemsByColumn(a.key, b.key); }) }));
    };
    return (_jsxs(Grid, { container: true, spacing: 2, children: [columns.map(function (columnKey, index) { return (_jsxs(Grid, { item: true, xs: 4, children: [_jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", children: [canUpdate && (_jsx(IconButton, { onClick: function () { return handleMove(index, index - 1); }, disabled: index === 0, size: "large", children: _jsx(Icon, { icon: "chevron-left", title: "D\u00E9placer \u00E0 gauche" }) })), _jsxs("h4", { children: ["Colonne ", index + 1] }), canUpdate && (_jsx(IconButton, { onClick: function () { return handleMove(index, index + 1); }, disabled: index === columns.length - 1, size: "large", children: _jsx(Icon, { icon: "chevron-right", title: "D\u00E9placer \u00E0 droite" }) }))] }), _jsx(AdminMenuForm, { menu: { contents: contents.filter(function (c) { return c.key === columnKey; }) }, itemKey: columnKey, onMenuChange: handleItemsChange(columnKey), maxDepth: 0, currentDepth: 0, canUpdate: canUpdate, subItemKey: "subMenus" })] }, columnKey)); }), canUpdate && columns.length < 6 && (_jsx(Grid, { item: true, xs: 4, children: _jsx("h4", { children: _jsx(MuiLink, { component: "button", variant: "body2", onClick: function () { return handleClickAddColumn(); }, children: "Ajouter une colonne" }) }) }, "".concat(columns[0]).concat(columns.length)))] }));
};
export default AdminFooterForm;
