import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { Box, Grid, InputAdornment, Popover, TextField, Typography } from "@mui/material";
import categories, { getCategoryLabel } from "shared/enums/categoriesContents";
import ThemeBackOfficeProvider from "administration/theme/ThemeBackOfficeProvider";
import React from "react";
import SimpleBar from "simplebar-react";
import ContentItem from "./ContentItem";
var SelectContentModal = function (props) {
    var open = props.open, contents = props.contents, onSelectContent = props.onSelectContent, onClose = props.onClose, target = props.target;
    var _a = React.useState(""), filter = _a[0], setFilter = _a[1];
    var handleChangeFilter = React.useCallback(function (e) {
        setFilter(e.target.value);
    }, []);
    React.useEffect(function () {
        if (!open) {
            setFilter("");
        }
    }, [open]);
    var filteredContents = React.useMemo(function () {
        return contents.filter(function (content) {
            var _a, _b;
            return (((_a = content === null || content === void 0 ? void 0 : content.category) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || "").includes(filter.toLowerCase()) ||
                (((_b = content === null || content === void 0 ? void 0 : content.label) === null || _b === void 0 ? void 0 : _b.toLowerCase()) || "").includes(filter.toLowerCase());
        });
    }, [contents, filter]);
    var handleFilterKeyDown = React.useCallback(function (e) {
        if (e.key === "Enter" && filteredContents.length === 1) {
            onSelectContent(filteredContents[0]);
        }
    }, [filteredContents, onSelectContent]);
    var getContentsByCategory = React.useCallback(function (category) {
        return filteredContents.filter(function (content) { return (category ? content.category === category : !content.category); });
    }, [filteredContents]);
    return (_jsx(ThemeBackOfficeProvider, { children: _jsx(Popover, { open: open, anchorEl: target, onClose: onClose, anchorReference: target ? "anchorEl" : "anchorPosition", anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "left",
            }, anchorPosition: { top: 200, left: 400 }, children: _jsxs(Box, { sx: {
                    width: 500,
                    p: 2,
                }, children: [_jsx(TextField, { placeholder: "Filtrer", fullWidth: true, autoFocus: true, value: filter, onChange: handleChangeFilter, onKeyDown: handleFilterKeyDown, InputProps: {
                            startAdornment: (_jsx(InputAdornment, { position: "start", children: _jsx(CobaltIcon, { name: "search" }) })),
                            endAdornment: filter && (_jsx(InputAdornment, { position: "end", onClick: function () { return setFilter(""); }, sx: { cursor: "pointer" }, children: _jsx(CobaltIcon, { name: "x" }) })),
                        }, sx: { mb: 2 } }), _jsx(SimpleBar, { style: { maxHeight: 600 }, children: [categories.STRUCTURE, "", categories.SPECIFIC].map(function (categoryName) {
                            var contentsList = getContentsByCategory(categoryName);
                            if (contentsList.length === 0) {
                                return null;
                            }
                            return (_jsxs(Box, { sx: { mb: 2 }, children: [_jsx(Typography, { variant: "h6", sx: { mb: 1 }, children: getCategoryLabel(categoryName) }), _jsx(Grid, { container: true, rowSpacing: 1, children: contentsList.map(function (content) { return (_jsx(Grid, { item: true, xs: 3, children: _jsx(ContentItem, { content: content, onClick: function () { return onSelectContent(content); } }) }, content.type)); }) })] }, categoryName));
                        }) })] }) }) }));
};
export default SelectContentModal;
