var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MoveDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoveUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditContentAction from "administration/components/actions/EditContentAction";
import AdminActions, { AdminActionsContext } from "administration/components/admin/AdminActions";
import AdminContentsGroups from "administration/components/admin/AdminContentsGroupsList/AdminContentsGroups";
import AdminTemplateSettingsActions from "administration/components/AdminTemplateSettings/AdminTemplateSettingsActions";
import { AdminUpdateContextProvider } from "administration/components/AdminUpdateContext";
import AutoResizeInput from "administration/components/AutoResizeInput";
import CKEditorCustom from "administration/components/CKEditorCustom";
import DynamicAdmin from "administration/components/DynamicAdmin";
import { swapArrayItems } from "shared/utils/ArrayUtils";
import { fullToolbarEditor } from "shared/utils/commonUtils";
import CmsHooks from "shared/utils/register/CmsHooks";
import { getDynamicComponent } from "shared/utils/templatePropsUtils";
import React, { Fragment } from "react";
import ContentsTypes from "shared/enums/contentsTypes";
import { HOOKS } from "shared/utils/register/definitions";
export var getEditorComponent = function (_a) {
    var content = _a.content, originalContent = _a.originalContent, sameLevelContents = _a.sameLevelContents, value = _a.value, handleContentChange = _a.handleContentChange, handleContentsChange = _a.handleContentsChange;
    var getEditorComponentFromProject = CmsHooks.getHook(HOOKS.templateAdminUtils_getEditorComponent);
    var getDynamicAdminContentsTypes = CmsHooks.getHook(HOOKS.templateAdminUtils_getDynamicAdminContentsTypes) || (function () { return []; });
    var type = content.type, id = content.id, editionModal = content.editionModal, isTemplateSettings = content.isTemplateSettings, contentKey = content.key, dynamicChildKey = content.dynamicChildKey, updatedAt = content.updatedAt, childrenContents = content.children;
    // what append when content.id is null ? we are using it into actions and editorComponent as key.
    // probably generate the error:
    // Warning: Each child in a list should have a unique "key" prop.
    // Check the render method of `AdminActions`. It was passed a child from DynamicAdmin
    var handleValueChange = function (newValue) {
        handleContentChange(__assign(__assign({}, content), { value: newValue }));
    };
    var handleRemoveContent = function () {
        handleContentChange();
    };
    var actions = [];
    if (editionModal) {
        actions.push({
            key: "EditContent-'".concat(id),
            node: (_jsx(EditContentAction, { content: content, onContentChange: handleContentChange }, "EditContentAction-".concat(id))),
            order: 1,
        });
    }
    var filteredContents = sameLevelContents.filter(function (c) { return c.key === contentKey; });
    var index = filteredContents.filter(function (c) { return c.key === contentKey; }).indexOf(originalContent);
    var previousContent = index > 0 && filteredContents[index - 1];
    var nextContent = index + 1 < filteredContents.length && filteredContents[index + 1];
    var swapContents = function (contentA, contentB) { return function () {
        handleContentsChange(swapArrayItems(sameLevelContents, sameLevelContents.indexOf(contentA), sameLevelContents.indexOf(contentB)));
    }; };
    if (previousContent) {
        actions.push({
            key: "MoveUp-".concat(id),
            node: (_jsx(MoveUpIcon, { viewBox: "4 4 16 16", onClick: swapContents(originalContent, previousContent) }, "MoveUpIcon-".concat(id))),
            order: 2,
        });
    }
    if (nextContent) {
        actions.push({
            key: "MoveDown-".concat(id),
            node: (_jsx(MoveDownIcon, { viewBox: "4 4 16 16", onClick: swapContents(originalContent, nextContent) }, "MoveDownIcon-".concat(id))),
            order: 3,
        });
    }
    if (type) {
        var editorComponent_1 = null;
        if (typeof getEditorComponentFromProject === "function") {
            editorComponent_1 = getEditorComponentFromProject({
                content: content,
                value: value,
                index: index,
                handleValueChange: handleValueChange,
                handleContentChange: handleContentChange,
                handleRemoveContent: handleRemoveContent,
            });
        }
        if (!editorComponent_1) {
            switch (type) {
                case ContentsTypes.EDITABLE_INPUT:
                    editorComponent_1 = _jsx(AutoResizeInput, { value: value, onChange: function (v) { return handleValueChange(v); } }, id);
                    break;
                case ContentsTypes.TEXT:
                    editorComponent_1 = getDynamicComponent(type, _jsx(CKEditorCustom, { onChange: function (data) { return handleValueChange(encodeURIComponent(data)); }, initData: decodeURIComponent(value), type: "inline", config: { toolbar: fullToolbarEditor } }, id), id);
                    break;
                case ContentsTypes.DYNAMIC: {
                    editorComponent_1 = (_jsx(DynamicAdmin, { content: content, addContentText: "Ins\u00E9rer un \u00E9l\u00E9ment ici", 
                        /*   deleteContentText="Supprimer cet élément"*/
                        contentsTypes: __spreadArray([
                            ContentsTypes.CONTAINER,
                            ContentsTypes.MARGIN,
                            ContentsTypes.TEXT,
                            ContentsTypes.EXPANSION_TEXT,
                            ContentsTypes.BUTTON,
                            ContentsTypes.ATTACHMENT,
                            ContentsTypes.IMAGES_GALLERY,
                            ContentsTypes.TIMELINE,
                            ContentsTypes.ACCORDION,
                            ContentsTypes.EXPANSION_PANEL,
                            ContentsTypes.VIDEO,
                            ContentsTypes.GRID,
                            ContentsTypes.INFO_BLOCK,
                            ContentsTypes.IMAGE_BLOCK,
                            ContentsTypes.CODE_BLOCK,
                            ContentsTypes.BLOCKTITLE
                        ], getDynamicAdminContentsTypes({ type: ContentsTypes.DYNAMIC }), true), onContentChange: handleContentChange, children: getDynamicComponent(type, value, id) }));
                    break;
                }
                case ContentsTypes.TIMELINE: {
                    editorComponent_1 = getDynamicComponent(type, {
                        steps: (_jsx(DynamicAdmin, { content: content, addContentText: "Ajouter une \u00E9tape", 
                            /*  deleteContentText="Supprimer cette étape"*/
                            contentsTypes: __spreadArray([
                                ContentsTypes.STEP_V2
                            ], getDynamicAdminContentsTypes({ type: ContentsTypes.TIMELINE }), true), onContentChange: handleContentChange, children: value && value.steps })),
                    }, id);
                    break;
                }
                case ContentsTypes.STEP_V2_CONTENT: {
                    editorComponent_1 = (_jsx(DynamicAdmin, { content: content, addContentText: "Ajouter un \u00E9l\u00E9ment", 
                        /* deleteContentText="Supprimer cet élément"*/
                        contentsTypes: __spreadArray([
                            ContentsTypes.MARGIN,
                            ContentsTypes.TEXT,
                            ContentsTypes.EXPANSION_TEXT,
                            ContentsTypes.BUTTON,
                            ContentsTypes.EXPANSION_PANEL,
                            ContentsTypes.IMAGE_BLOCK
                        ], getDynamicAdminContentsTypes({ type: ContentsTypes.STEP_V2_CONTENT }), true), onContentChange: handleContentChange, children: value && value.detailsElements }));
                    break;
                }
                case ContentsTypes.ACCORDION: {
                    editorComponent_1 = (_jsx(DynamicAdmin, { content: content, addContentText: "Ins\u00E9rer un panneau", 
                        /* deleteContentText="Supprimer ce panneau"*/
                        contentsTypes: __spreadArray([
                            ContentsTypes.ACCORDION_PANEL
                        ], getDynamicAdminContentsTypes({ type: ContentsTypes.ACCORDION }), true), onContentChange: handleContentChange, children: value && value.panels }));
                    break;
                }
                case ContentsTypes.ACCORDION_PANEL:
                    editorComponent_1 = getDynamicComponent(ContentsTypes.EXPANSION_PANEL, value, id);
                    break;
                case ContentsTypes.EXPANSION_PANEL_DETAILS: {
                    editorComponent_1 = (_jsx(DynamicAdmin, { content: content, addContentText: "Ins\u00E9rer un \u00E9l\u00E9ment", 
                        /*deleteContentText="Supprimer cet élément"*/
                        contentsTypes: __spreadArray([
                            ContentsTypes.MARGIN,
                            ContentsTypes.TEXT,
                            ContentsTypes.EXPANSION_TEXT,
                            ContentsTypes.BUTTON,
                            ContentsTypes.ATTACHMENT,
                            ContentsTypes.TIMELINE,
                            ContentsTypes.GRID,
                            ContentsTypes.INFO_BLOCK,
                            ContentsTypes.IMAGE_BLOCK,
                            ContentsTypes.CODE_BLOCK
                        ], getDynamicAdminContentsTypes({ type: ContentsTypes.EXPANSION_PANEL_DETAILS }), true), onContentChange: handleContentChange, children: value && value.detailsElements }));
                    break;
                }
                case ContentsTypes.GRID: {
                    var size = value.size;
                    var _b = value.items, items_1 = _b === void 0 ? [] : _b;
                    if (!Array.isArray(items_1)) {
                        items_1 = [items_1];
                    }
                    editorComponent_1 = (_jsx(DynamicAdmin, { content: content, addContentText: "Ins\u00E9rer un \u00E9l\u00E9ment", 
                        /* deleteContentText="Supprimer cet élément"*/
                        contentsTypes: __spreadArray([
                            ContentsTypes.MARGIN,
                            ContentsTypes.TEXT,
                            ContentsTypes.EXPANSION_TEXT,
                            ContentsTypes.BUTTON,
                            ContentsTypes.ATTACHMENT,
                            ContentsTypes.TIMELINE,
                            ContentsTypes.VIDEO,
                            ContentsTypes.GRID,
                            ContentsTypes.INFO_BLOCK,
                            ContentsTypes.IMAGE_BLOCK,
                            ContentsTypes.IMAGES_GALLERY,
                            ContentsTypes.CODE_BLOCK
                        ], getDynamicAdminContentsTypes({ type: ContentsTypes.GRID }), true), onContentChange: handleContentChange, maxIndexForSpacing: 12 / Number(size) - 1, limit: content.limit, children: function (appendButtonToNode) {
                            return items_1.length > 0 && getDynamicComponent(type, __assign(__assign({}, value), { items: items_1.map(appendButtonToNode) }), id);
                        } }));
                    break;
                }
                case ContentsTypes.CONTAINER: {
                    var containerElement_1 = value.containerElement;
                    editorComponent_1 = (_jsx(DynamicAdmin, { content: content, addContentText: "Ins\u00E9rer un \u00E9l\u00E9ment ici", 
                        /*  deleteContentText="Supprimer un élément"*/
                        contentsTypes: __spreadArray([
                            ContentsTypes.EXPANSION_TEXT,
                            ContentsTypes.GRID,
                            ContentsTypes.BUTTON,
                            ContentsTypes.ATTACHMENT,
                            ContentsTypes.TIMELINE,
                            ContentsTypes.ACCORDION,
                            ContentsTypes.EXPANSION_PANEL,
                            ContentsTypes.VIDEO,
                            ContentsTypes.INFO_BLOCK,
                            ContentsTypes.TEXT
                        ], getDynamicAdminContentsTypes({ type: ContentsTypes.CONTAINER }), true), onContentChange: handleContentChange, children: function () { return containerElement_1 && getDynamicComponent(type, value, id); } }));
                    break;
                }
                case ContentsTypes.SUBPAGES_LIST: {
                    var limit = value.limit;
                    var _c = value.pages, pages_1 = _c === void 0 ? [] : _c;
                    if (!Array.isArray(pages_1)) {
                        pages_1 = [pages_1];
                    }
                    editorComponent_1 = (_jsx(DynamicAdmin, { content: content, addContentText: "Ins\u00E9rer une page", 
                        /*deleteContentText="Supprimer cette page"*/
                        contentsTypes: __spreadArray([
                            ContentsTypes.SUBPAGE
                        ], getDynamicAdminContentsTypes({ type: ContentsTypes.SUBPAGES_LIST }), true), onContentChange: handleContentChange, limit: limit || content.limit, children: function (appendButtonToNode) {
                            return pages_1.length > 0 && getDynamicComponent(type, { pages: pages_1.map(appendButtonToNode) }, id, index);
                        } }));
                    break;
                }
                case ContentsTypes.GLOSSARY: {
                    editorComponent_1 = (_jsxs(Fragment, { children: [_jsx(DynamicAdmin, { content: content, addContentText: "Ajouter un mot / acronyme", contentsTypes: __spreadArray([
                                    ContentsTypes.GLOSSARY_ITEM
                                ], getDynamicAdminContentsTypes({ type: ContentsTypes.GLOSSARY }), true), onContentChange: handleContentChange, limit: 1 }), getDynamicComponent(type, value, id, index)] }));
                    break;
                }
                case ContentsTypes.GLOSSARY_ITEM: {
                    editorComponent_1 = __assign(__assign({}, value), { Wrapper: function (p) {
                            var children = p.children;
                            return (_jsx(AdminActions, { content: content, children: _jsx(DynamicAdmin, { onContentChange: handleRemoveContent, limit: 0, content: content, children: children }) }, id));
                        } });
                    break;
                }
                case ContentsTypes.INFO_BLOCK_LIST: {
                    var limit = value.limit;
                    var _d = value, _e = dynamicChildKey, _f = _d[_e], infos_1 = _f === void 0 ? [] : _f;
                    if (!Array.isArray(infos_1)) {
                        infos_1 = [infos_1];
                    }
                    editorComponent_1 = (_jsx(DynamicAdmin, { content: content, addContentText: "Ins\u00E9rer un bloc d'information", 
                        /*deleteContentText="Supprimer"*/
                        contentsTypes: __spreadArray([
                            ContentsTypes.INFO_BLOCK
                        ], getDynamicAdminContentsTypes({ type: ContentsTypes.INFO_BLOCK_LIST }), true), onContentChange: handleContentChange, limit: limit || content.limit, children: function (appendButtonToNode) {
                            return infos_1.length > 0 && getDynamicComponent(type, { infos: infos_1.map(appendButtonToNode) }, id);
                        } }));
                    break;
                }
                case ContentsTypes.TAB_CARDS:
                    break;
                case ContentsTypes.TABS: {
                    editorComponent_1 = __assign(__assign({}, value), { Wrapper: function (p) {
                            var children = p.children;
                            return (_jsx(AdminActions, { content: content, children: children }, "key-" + id));
                        } });
                    break;
                }
                case ContentsTypes.CONTENTS_GROUPS_LIST: {
                    editorComponent_1 = _jsx(AdminContentsGroups, { content: content, onContentChange: handleContentChange }, id);
                    break;
                }
                case ContentsTypes.EXPANSION_TEXT: {
                    editorComponent_1 = getDynamicComponent(type, __assign(__assign({}, value), { defaultExpanded: true }), id, index);
                    break;
                }
                default:
                    editorComponent_1 = getDynamicComponent(type, value, id, index);
            }
        }
        if (isTemplateSettings && editorComponent_1) {
            return _jsx(AdminTemplateSettingsActions, { children: editorComponent_1 });
        }
        if (React.isValidElement(editorComponent_1)) {
            return (_jsx(AdminUpdateContextProvider, { updatedAt: updatedAt, childrenContents: childrenContents, children: _jsx(AdminActionsContext.Consumer, { children: function (_a) {
                        var parentActions = _a.parentActions;
                        return (_jsx(AdminActionsContext.Provider, { value: { actions: parentActions ? parentActions.concat(actions) : actions }, children: _jsx(AdminActions, { content: content, children: editorComponent_1 }, id) }, id));
                    } }, id) }));
        }
        return editorComponent_1;
    }
    return value;
};
