import { jsx as _jsx } from "react/jsx-runtime";
import TabsComponent from "./TabsComponent";
var TabsPreview = function () {
    return (_jsx(TabsComponent, { tabs: {
            id: "example",
            tabs: [
                { id: "tab1", title: "tab1", children: null },
                { id: "tab2", title: "tab2", children: null },
            ],
        } }));
};
export default TabsPreview;
