import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Container from "shared/components/contents/container/Container";
var ContainerTitle = function (_a) {
    var title = _a.title, justifyContent = _a.justifyContent, width = _a.width;
    return (_jsx(Box, { mb: 3, children: _jsx(Container, { justifyContent: justifyContent, width: width, children: _jsx(Box, { sx: { border: "1px solid #999", p: 2, textAlign: "center" }, children: title }) }) }));
};
var ContainerPreview = function () {
    return (_jsxs(Box, { children: [_jsx(ContainerTitle, { title: "Conteneur 50% align\u00E9 \u00E0 gauche", justifyContent: "flex-start", width: "50" }), _jsx(ContainerTitle, { title: "Conteneur 30% align\u00E9 au centre", justifyContent: "center", width: "30" }), _jsx(ContainerTitle, { title: "Conteneur 60% align\u00E9 \u00E0 droite", justifyContent: "flex-end", width: "60" })] }));
};
export default ContainerPreview;
