var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormFieldset } from "administration/components/contentForms/ContentFormStyled";
import FilePicker from "administration/components/FilePicker";
import ContentsTypes from "shared/enums/contentsTypes";
import useContentFormStore from "administration/store/ContentFormStore";
import useAdminPageVersionStore from "administration/store/AdminPageVersionStore";
import { useShallow } from "zustand/react/shallow";
var ImageForm = function (props) {
    var content = props.content, onContentChange = props.onContentChange, formSubmitted = props.formSubmitted;
    var id = content.id, label = content.label, children = content.children, requiredByKey = content.requiredByKey;
    var currentPageVersion = useAdminPageVersionStore(useShallow(function (state) { return ({
        currentPageVersion: state.currentPageVersion,
    }); })).currentPageVersion;
    var displayVertical = useContentFormStore(function (state) { return ({ displayVertical: state.displayVertical }); }).displayVertical;
    var fileImageChild = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.type === ContentsTypes.FILE_IMAGE; });
    var altChild = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.key === "alt"; });
    var titleChild = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.key === "title"; });
    var positionHorizontaleChild = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.key === "position_horizontale"; });
    var positionVerticaleChild = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.key === "position_verticale"; });
    var positionAjustementChild = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.key === "position_ajustement"; });
    var imageFiltersChild = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.key === "filters"; });
    var fileValue = __assign(__assign({}, (((fileImageChild === null || fileImageChild === void 0 ? void 0 : fileImageChild.value) && JSON.parse(fileImageChild.value)) || {})), { alt: altChild === null || altChild === void 0 ? void 0 : altChild.value, title: titleChild === null || titleChild === void 0 ? void 0 : titleChild.value });
    var handleSelectFile = function (file) {
        var _a = file || {}, alt = _a.alt, title = _a.title, filters = _a.filters, position_horizontale = _a.position_horizontale, position_verticale = _a.position_verticale, position_ajustement = _a.position_ajustement;
        onContentChange(__assign(__assign({}, content), { children: children === null || children === void 0 ? void 0 : children.map(function (child) {
                if (child === fileImageChild) {
                    return __assign(__assign({}, child), { value: file
                            ? JSON.stringify(file)
                            : "{\"url\":\"".concat(typeof window !== "undefined" && window.location.origin, "/default-image.jpg\"}") });
                }
                if (child === altChild) {
                    return __assign(__assign({}, child), { value: alt || "" });
                }
                if (child === titleChild) {
                    return __assign(__assign({}, child), { value: title || "" });
                }
                if (filters && child === imageFiltersChild) {
                    return filters;
                }
                // Position: Horizontal, vertical and fit.
                if (position_horizontale && child === positionHorizontaleChild) {
                    return __assign(__assign({}, child), { value: position_horizontale || "" });
                }
                if (position_verticale && child === positionVerticaleChild) {
                    return __assign(__assign({}, child), { value: position_verticale || "" });
                }
                if (position_ajustement && child === positionAjustementChild) {
                    return __assign(__assign({}, child), { value: position_ajustement || "" });
                }
                return child;
            }) }));
    };
    return (_jsxs(FormFieldset, { children: [_jsx("legend", { children: label }), _jsx(FilePicker, { onSelectFile: handleSelectFile, pageVersion: currentPageVersion, image: true, file: fileValue, required: !!requiredByKey, formSubmitted: formSubmitted, positionHorizontaleChild: positionHorizontaleChild, positionVerticaleChild: positionVerticaleChild, positionAjustementChild: positionAjustementChild, imageFiltersChild: imageFiltersChild, displayVertical: displayVertical })] }, id));
};
export default ImageForm;
