var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
var loadingPageStyled = {
    display: "flex",
    height: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "1.14rem",
    "& .MuiCircularProgress-circle": {
        stroke: "#3787FF",
        strokeLinecap: "round",
    },
    "& > span": {
        color: "#666666",
        mt: 2,
    },
};
var LoadingPage = function (props) {
    var title = props.title, sx = props.sx;
    return (_jsxs(Box, { sx: __spreadArray([loadingPageStyled], (Array.isArray(sx) ? sx : [sx]), true), children: [_jsx(CircularProgress, {}), _jsx("span", { children: title })] }));
};
export default LoadingPage;
