var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormTitle } from "administration/components/contentForms/ContentFormStyled";
import SelectPage from "administration/components/SelectPage";
var SubpageForm = function (props) {
    var content = props.content, onContentChange = props.onContentChange, _a = props.withLabel, withLabel = _a === void 0 ? true : _a, _b = props.canBeEmpty, canBeEmpty = _b === void 0 ? false : _b;
    var id = content.id, label = content.label;
    var handleSelectPage = function (p) {
        if (p && p.id) {
            var versions = p.versions, others = __rest(p, ["versions"]);
            versions.sort(function (v1, v2) { return new Date(v2.updated).getTime() - new Date(v1.updated).getTime(); });
            var lastVersion = versions[0];
            if (lastVersion) {
                var pageVersion = __assign(__assign({}, lastVersion), { page: __assign({}, others) });
                onContentChange(__assign(__assign({}, content), { value: pageVersion }));
            }
        }
        else if (!p || !p.id) {
            onContentChange(__assign(__assign({}, content), { value: "" }));
        }
    };
    var SelectPageInput = (_jsx(SelectPage, { currentPage: content.value && content.value.page, onSelectPage: handleSelectPage, canBeEmpty: canBeEmpty, label: label, onlyPublishedPages: true }));
    if (withLabel) {
        return (_jsxs("div", { style: { maxWidth: 400, marginBottom: "0.875rem" }, children: [_jsx(FormTitle, { children: label }), SelectPageInput] }, id));
    }
    return SelectPageInput;
};
export default SubpageForm;
