var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import ContentsTypes from "shared/enums/contentsTypes";
import AdminListForm from "administration/components/contentForms/AdminListForm";
import ImageForm from "administration/components/contentForms/ImageForm";
import { FormTitle } from "administration/components/contentForms/ContentFormStyled";
var ImagesForm = function (props) {
    var content = props.content, onContentChange = props.onContentChange, formSubmitted = props.formSubmitted;
    var id = content.id, key = content.key, label = content.label, children = content.children;
    var fileImageType = ContentsTypes.IMAGE_AS_FILE;
    var objectsChildren = (children === null || children === void 0 ? void 0 : children.filter(function (c) { return c.type === fileImageType; })) || [];
    var handleContentChange = function (oldChild, newChild) {
        onContentChange(__assign(__assign({}, content), { children: children === null || children === void 0 ? void 0 : children.map(function (oldContent) { return (oldContent === oldChild ? newChild : oldContent); }).filter(function (c) { return !!c; }) }));
    };
    var handleChildrenChange = function (oldChildren, newChildren) {
        onContentChange(__assign(__assign({}, content), { children: newChildren }));
    };
    return (_jsxs(Box, { sx: { mb: 2 }, children: [_jsx(FormTitle, { children: label }), _jsx(AdminListForm, { inline: true, contents: objectsChildren, elementsType: ContentsTypes.IMAGE_AS_FILE, elementsKey: "image", onContentsChange: function (newContents) { return handleChildrenChange(objectsChildren, newContents); }, title: "Image", children: objectsChildren.map(function (currentObject, i) { return (_jsx(ImageForm, { content: currentObject, onContentChange: function (newContent) { return handleContentChange(currentObject, newContent); }, formSubmitted: formSubmitted }, currentObject.id && key ? key + i : Math.floor(Math.random() * 1000) + i)); }) })] }, id));
};
export default ImagesForm;
