var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
var itemActionStyled = function (selected) { return ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "60px",
    height: "100%",
    borderRight: "1px solid",
    borderColor: "layout.border",
    px: 2,
    color: "secondary.text",
    fontWeight: "bold",
    cursor: "pointer",
    bgcolor: selected ? "secondary.main" : "inherit",
    "& > i": {
        fontSize: "1.3rem",
        color: selected ? "inherit" : "",
    },
    "&:hover": {
        bgcolor: "secondary.main",
    },
    "@media screen and (max-width: 1400px)": {
        "& > span": {
            display: "none",
        },
        "& > i": {
            mr: "0 !important",
        },
    },
}); };
var AdminVersionsItemAction = function (props) {
    var _a = props.selected, selected = _a === void 0 ? false : _a, others = __rest(props, ["selected"]);
    return _jsx(Grid, __assign({ item: true, sx: itemActionStyled(selected) }, others));
};
export default AdminVersionsItemAction;
