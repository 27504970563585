import contentsTypes from "shared/enums/contentsTypes";
import SubpageDynamicForm from "components/adminForms/SubpageDynamicForm";
import SubpageSearchForm from "components/adminForms/SubpageSearchForm";
import customTypes from "enums/contentsCustomTypes.enum";
var getFormComponent = function (_a) {
    var type = _a.type;
    switch (type) {
        case customTypes.SUBPAGE_SEARCH:
            return SubpageSearchForm;
        case contentsTypes.SUBPAGE:
            return SubpageDynamicForm;
        default:
            return null;
    }
};
export default getFormComponent;
