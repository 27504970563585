var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AddIcon from "@mui/icons-material/Add";
import { Chip, Grid, IconButton, TextField } from "@mui/material";
import { useState } from "react";
var AdminPageTagForm = function (_a) {
    var form = _a.form, setForm = _a.setForm, tags = _a.tags, formSubmitted = _a.formSubmitted, setFormSubmitted = _a.setFormSubmitted, errors = _a.errors;
    var _b = useState(""), newTag = _b[0], setNewTag = _b[1];
    var handleSubmitFormTags = function (e) {
        e.preventDefault();
        setFormSubmitted(true);
        var newTags = [];
        if (tags && Array.isArray(tags)) {
            newTags = tags;
        }
        var tag = newTag.trim();
        if (tag.length > 0) {
            if (newTags.find(function (t) { return t === newTag; }) === undefined) {
                newTags.push(tag);
                setForm(__assign(__assign({}, form), { tags: newTags }));
                setNewTag("");
            }
        }
    };
    var handleClickDeleteChip = function (tag) { return function () {
        var tagsList = tags === null || tags === void 0 ? void 0 : tags.filter(function (t) { return t !== tag; });
        setForm(__assign(__assign({}, form), { tags: tagsList }));
    }; };
    var handleTagChange = function (e) {
        var value = e.target.value;
        setNewTag(value);
    };
    var showError = function (key) { return formSubmitted && errors[key]; };
    return (_jsx(Grid, { container: true, spacing: 2, children: _jsx(Grid, { item: true, xs: 12, children: _jsxs("fieldset", { children: [_jsx("legend", { children: "Tags" }), _jsxs("form", { onSubmit: handleSubmitFormTags, children: [tags &&
                                tags.map(function (tag) { return (_jsx(Chip, { label: tag, onDelete: handleClickDeleteChip(tag), color: "primary", sx: { m: 1 } }, tags.indexOf(tag))); }), _jsx(TextField, { placeholder: "Ajouter un tag", value: newTag, onChange: handleTagChange, error: showError("duplicateTag"), helperText: showError("duplicateTag") && "Ce tag est déjà renseigné", variant: "standard", InputProps: {
                                    endAdornment: (_jsx(IconButton, { sx: {
                                            borderRadius: "0 7px 7px 0",
                                            bgcolor: "form.textFieldButtonBackground",
                                            color: "white",
                                        }, onClick: handleSubmitFormTags, size: "large", children: _jsx(AddIcon, {}) })),
                                } })] })] }) }) }));
};
export default AdminPageTagForm;
