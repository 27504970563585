var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HelpIcon from "@mui/icons-material/Help";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { FormLabelTooltip } from "administration/components/contentForms/ContentFormStyled";
import { GridBox } from "administration/components/contentForms/GridForm/GridBox";
export var SelectGridForm = function (_a) {
    var content = _a.content, onContentChange = _a.onContentChange, tooltip = _a.tooltip;
    // Members. ------------------------------------------------------------------
    var id = content.id, label = content.label, value = content.value, options = content.options;
    // Handlers. -----------------------------------------------------------------
    var handleClickBox = function (boxValue) {
        onContentChange(__assign(__assign({}, content), { value: boxValue }));
    };
    // Render. -------------------------------------------------------------------
    return (_jsx(FormControl, { children: _jsxs(Grid, { container: true, direction: "row", justifyContent: "flex-start", alignItems: "stretch", children: [_jsxs(Grid, { item: true, xs: 12, children: [label, tooltip && (_jsx(FormLabelTooltip, { title: tooltip, children: _jsx(HelpIcon, {}) }))] }), options.map(function (option) { return (_jsx(Grid, { item: true, xs: 6, sx: { height: "55px", m: "2px 0px", p: "2px" }, children: _jsx(GridBox, { option: option, onClickBox: handleClickBox, selected: value }) }, option.value)); })] }) }, id));
};
export default SelectGridForm;
