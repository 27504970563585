var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import axios from "axios";
import generateCRUD from "administration/services/generateCRUD";
import { adminProperties, generateContentId, replacePagesByIds } from "administration/utils/adminContentsUtils";
import { removeProperties } from "shared/utils/commonUtils";
var name = "section-versions";
var SectionsCRUD = generateCRUD(name);
var AdminSectionsService = /** @class */ (function (_super) {
    __extends(AdminSectionsService, _super);
    function AdminSectionsService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AdminSectionsService.getSectionVersions = function (_a) {
        var type = _a.type, siteId = _a.siteId;
        return axios.get("/getSectionVersions", { params: { type: type, siteId: siteId } }).then(function (r) { return r.data; });
    };
    AdminSectionsService.create = function (_a) {
        var _b = _a.contents, contents = _b === void 0 ? [] : _b, section = _a.section;
        var cleanContents = contents.map(function (c) { return removeProperties(c, adminProperties); });
        var updatedIds = {};
        var contentsWithIds = cleanContents.map(function (c) { return generateContentId(c, updatedIds); });
        var contentsWithPagesIds = contentsWithIds.map(function (content) { return replacePagesByIds(content, updatedIds); });
        return SectionsCRUD.create({
            contents: contentsWithPagesIds,
            sectionId: section.id,
        });
    };
    AdminSectionsService.update = function (_a) {
        var contents = _a.contents, versionName = _a.versionName, id = _a.id, updated = _a.updated;
        var cleanContents = contents.map(function (c) { return removeProperties(c, adminProperties); });
        var contentsWithIds = cleanContents.map(replacePagesByIds);
        return SectionsCRUD.update({
            id: id,
            updated: updated,
            contents: contentsWithIds,
            versionName: versionName,
        });
    };
    AdminSectionsService.updateStatus = function (sectionVersion, status) {
        return axios
            .patch("/api/".concat(name, "/").concat(sectionVersion.id, "/status"), { updated: sectionVersion.updated, status: status })
            .then(function (r) { return r.data; });
    };
    return AdminSectionsService;
}(SectionsCRUD));
export default AdminSectionsService;
