var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Icon from "shared/components/Icon";
import { v4 as uuidV4 } from "uuid";
export var CustomSelect = function (_a) {
    var label = _a.label, labelIcon = _a.labelIcon, isIcon = _a.isIcon, type = _a.type, value = _a.value, res = __rest(_a, ["label", "labelIcon", "isIcon", "type", "value"]);
    return (_jsx(Autocomplete, __assign({}, res, { id: "country-select-demo", sx: { width: "auto" }, autoHighlight: true, defaultValue: value, getOptionLabel: function (option) { return option[labelIcon]; }, renderOption: function (props, option) { return (_createElement(Box, __assign({ component: "li" }, props, { key: uuidV4() }),
            _jsx(Typography, { sx: { width: 30 }, component: "span", children: isIcon && _jsx(Icon, { icon: option[labelIcon], type: type }) }),
            _jsx(Typography, { component: "span", children: option[labelIcon] }))); }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { label: label, inputProps: __assign({}, params.inputProps) }))); } })));
};
