var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Fragment, useState } from "react";
import SimpleBar from "simplebar-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import "simplebar-react/dist/simplebar.min.css";
import EnhancedTableHead from "./EnhanceTableHead";
var tableContainerStyled = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflowX: "visible",
    "& > div": {
        maxHeight: "100%",
    },
    "& .simplebar-track.simplebar-vertical .simplebar-scrollbar:before ": {
        bgcolor: "layout.scrollbar",
    },
};
var EnhancedTable = function (props) {
    var _a = props.columns, columns = _a === void 0 ? [] : _a, _b = props.data, data = _b === void 0 ? [] : _b, order = props.order, orderBy = props.orderBy, _c = props.page, page = _c === void 0 ? 1 : _c, _d = props.totalRows, totalRows = _d === void 0 ? 0 : _d, _e = props.rowsPerPage, rowsPerPage = _e === void 0 ? 10 : _e, _f = props.rowsPerPageOptions, rowsPerPageOptions = _f === void 0 ? [5, 10, 20] : _f, onRequestSort = props.onRequestSort, onChangePage = props.onChangePage, onChangeRowsPerPage = props.onChangeRowsPerPage, _g = props.onRowClick, onRowClick = _g === void 0 ? null : _g, _h = props.renderRowDetail, renderRowDetail = _h === void 0 ? null : _h;
    var _j = useState([]), openedRows = _j[0], setOpenedRows = _j[1];
    var isRowOpen = function (row) { return openedRows.includes(row.id); };
    var openRow = function (row) {
        if (row === void 0) { row = {}; }
        var id = row.id;
        if (id) {
            setOpenedRows(__spreadArray(__spreadArray([], openedRows, true), [id], false));
        }
    };
    var closeRow = function (row) {
        if (row === void 0) { row = {}; }
        var id = row.id;
        if (id) {
            setOpenedRows(openedRows.filter(function (r) { return r !== id; }));
        }
    };
    var toggleRow = function (row) {
        if (isRowOpen(row)) {
            closeRow(row);
        }
        else {
            openRow(row);
        }
    };
    var handleRowClick = onRowClick;
    if (!handleRowClick && renderRowDetail) {
        handleRowClick = function (event, row) {
            toggleRow(row);
        };
    }
    return (_jsxs(Box, { sx: { height: "100%", display: "flex", flexDirection: "column" }, children: [_jsx(Box, { sx: { flex: "1 0 auto", position: "relative" }, children: _jsx(TableContainer, { sx: tableContainerStyled, children: _jsx(SimpleBar, { children: _jsxs(Table, { "aria-labelledby": "tableTitle", "aria-label": "enhanced table", stickyHeader: true, children: [_jsx(EnhancedTableHead, { columns: columns, order: order, orderBy: orderBy, onRequestSort: onRequestSort }), _jsx(TableBody, { children: data.map(function (row) {
                                        return (_jsxs(Fragment, { children: [_jsx(TableRow, { hover: true, onClick: function (event) { return typeof handleRowClick === "function" && handleRowClick(event, row); }, sx: { cursor: typeof handleRowClick === "function" ? "pointer" : "default" }, tabIndex: -1, children: columns.map(function (column) { return (_jsx(TableCell, __assign({ sx: column.sx && (column === null || column === void 0 ? void 0 : column.sx(row)) }, column.cellProps, { children: column.render(row) }), column.id)); }) }), isRowOpen(row) && (_jsx(TableRow, { children: renderRowDetail && (_jsx(TableCell, { colSpan: columns.length, children: renderRowDetail(row, function () { return closeRow(row); }) })) }))] }, row.id));
                                    }) })] }) }) }) }), _jsx(TablePagination, { rowsPerPageOptions: rowsPerPageOptions, component: "div", count: totalRows, rowsPerPage: rowsPerPage, page: page - 1, labelRowsPerPage: "", backIconButtonProps: {
                    "aria-label": "Pages suivantes",
                }, nextIconButtonProps: {
                    "aria-label": "Pages précédentes",
                }, onPageChange: function (event, p) { return onChangePage(p + 1); }, onRowsPerPageChange: onChangeRowsPerPage })] }));
};
export default EnhancedTable;
