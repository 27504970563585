var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { v4 as uuidV4 } from "uuid";
import AutoResizeInput from "administration/components/AutoResizeInput";
import CKEditorCustom from "administration/components/CKEditorCustom";
import Text from "shared/components/contents/text/Text";
import ContentsTypesEnum from "shared/enums/contentsTypes";
import { descriptionToolbarEditor } from "shared/utils/commonUtils";
import { mapSrcSet, pageImageFiltersKey } from "shared/utils/imageUtil";
import { getDynamicComponent } from "shared/utils/templatePropsUtils";
import { generateTemplatePropsFromAdminContents } from "./generateTemplatePropsFromAdminContents";
var generateEditablePage = function (page, handlePageChange, currentTemplate) {
    var _a, _b;
    var title = page.title, description = page.description, shortDescription = page.shortDescription, contents = page.contents;
    var handleTitleChange = function (t) {
        handlePageChange(__assign(__assign({}, page), { title: t }));
    };
    var handleDescriptionChange = function (data) {
        handlePageChange(__assign(__assign({}, page), { description: encodeURIComponent(data) }));
    };
    var handleShortDescriptionChange = function (data) {
        handlePageChange(__assign(__assign({}, page), { shortDescription: encodeURIComponent(data) }));
    };
    var handleContentsChange = function (c) {
        handlePageChange(__assign(__assign({}, page), { contents: c }));
    };
    var editableTitle = _jsx(AutoResizeInput, { value: title, onChange: function (v) { return handleTitleChange(v); } });
    var editableDescription = (_jsx(Text, { children: _jsx(CKEditorCustom, { initData: decodeURIComponent(description || ""), onChange: handleDescriptionChange, type: "inline", config: { toolbar: descriptionToolbarEditor } }, page.id) }));
    var editableShortDescription = (_jsx(Text, { children: _jsx(CKEditorCustom, { initData: decodeURIComponent(shortDescription || ""), onChange: handleShortDescriptionChange, type: "inline", config: { toolbar: descriptionToolbarEditor } }, page.id) }));
    var displayedImage = page.image || { url: "/default-image.jpg" };
    var overrideContentPropsByInitialContentProps = function (defaultContents, initialContents) {
        if (initialContents === void 0) { initialContents = []; }
        var getInitialContent = function (content) {
            return initialContents.find(function (i) { return i.key === content.key && i.type === content.type; }) || {};
        };
        var hasChildren = function (content) { return content.children && content.children.length > 0; };
        var overrideChildren = function (content, initialContent) {
            return hasChildren(initialContent) && hasChildren(content) && content.children
                ? overrideContentPropsByInitialContentProps(content.children, initialContent.children)
                : content.children;
        };
        var contentsOverrided = [];
        defaultContents.forEach(function (content) {
            var initialContent = getInitialContent(content);
            var contentWithChildrenOverrided = __assign(__assign({}, content), { children: overrideChildren(content, initialContent) });
            contentsOverrided.push(__assign(__assign({}, (initialContent !== null && initialContent !== void 0 ? initialContent : {})), contentWithChildrenOverrided));
        });
        return contentsOverrided;
    };
    var initialContents = currentTemplate
        ? ((_a = currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.initialContents) === null || _a === void 0 ? void 0 : _a.map(function (initialContent) {
            return __assign(__assign({}, initialContent), { id: uuidV4() });
        })) || []
        : [];
    var generatedProps = generateTemplatePropsFromAdminContents(overrideContentPropsByInitialContentProps(contents, initialContents), handleContentsChange);
    var imageFiltersChild = (_b = page === null || page === void 0 ? void 0 : page.contents) === null || _b === void 0 ? void 0 : _b.find(function (child) { return child.key === pageImageFiltersKey; });
    return __assign(__assign({}, page), { title: editableTitle, description: editableDescription, shortDescription: editableShortDescription, image: getDynamicComponent(ContentsTypesEnum.IMAGE, {
            file: {
                url: displayedImage.url,
                srcSet: displayedImage.publicUrlBySize ? mapSrcSet(displayedImage.publicUrlBySize) : undefined,
            },
            alt: displayedImage.alt,
            title: displayedImage.title,
            filters: imageFiltersChild && imageFiltersChild.children
                ? generateTemplatePropsFromAdminContents(imageFiltersChild.children)
                : undefined,
        }), contents: generatedProps });
};
export default generateEditablePage;
