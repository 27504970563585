var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import InputForm from "../InputForm";
export var SiteSettingsAnalyticsForm = function (_a) {
    var handleChildChange = _a.handleChildChange, findContent = _a.findContent;
    var analyticsChild = findContent("analytics");
    var tagManagerChild = findContent("tagManager");
    return (_jsxs(_Fragment, { children: [_jsx(InputForm, { content: __assign(__assign({}, analyticsChild), { label: "Identifiant de suivi" }), onContentChange: function (newContent) { return handleChildChange(analyticsChild, newContent); } }), _jsx(InputForm, { content: __assign(__assign({}, tagManagerChild), { label: "Identifiant de taggage" }), onContentChange: function (newContent) { return handleChildChange(tagManagerChild, newContent); } })] }));
};
