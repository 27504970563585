var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, FormControl, Grid, MenuItem } from "@mui/material";
import SiteSettingsForm from "administration/components/contentForms/SiteSettingsForm";
import { ModalActions } from "administration/components/Modal";
import Select from "shared/components/Select";
import { useSites } from "shared/hooks/SiteHook";
import { useDuplicateSite } from "administration/services/AdminSitesService";
import { adminProperties, enhanceContent } from "administration/utils/adminContentsUtils";
import { removeProperties } from "shared/utils/commonUtils";
import React, { useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { FlagPicture } from "shared/components/site-translation/FlagPicture";
import useSnackbarStore from "shared/store/SnackbarStore";
import { useSetCurrentSite } from "administration/hooks/AdminSiteHook";
var DuplicateSiteModal = function (props) {
    var onCancel = props.onCancel;
    var _a = useState(), site = _a[0], setSite = _a[1];
    var _b = useState(), siteContent = _b[0], setSiteContent = _b[1];
    var _c = useState(false), isSubmitted = _c[0], setIsSubmitted = _c[1];
    var queryClient = useQueryClient();
    var _d = useSites(), allSites = _d.allSites, refreshSite = _d.refreshSite;
    var setCurrentSite = useSetCurrentSite().setCurrentSite;
    var _e = useSnackbarStore(function (state) { return ({
        displaySuccess: state.displaySuccess,
        displayError: state.displayError,
    }); }), displaySuccess = _e.displaySuccess, displayError = _e.displayError;
    var _f = useDuplicateSite({
        onSuccess: function (_a) {
            var newSite = _a.data;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            displaySuccess("Le site a été dupliqué");
                            return [4 /*yield*/, queryClient.invalidateQueries({ queryKey: ["sites"] })];
                        case 1:
                            _b.sent();
                            return [4 /*yield*/, refreshSite()];
                        case 2:
                            _b.sent();
                            setCurrentSite(newSite);
                            onCancel();
                            return [2 /*return*/];
                    }
                });
            });
        },
        onError: function () {
            displayError("Une erreur est survenue durant la duplication du site ".concat(site === null || site === void 0 ? void 0 : site.name));
        },
    }), duplicateSite = _f.mutate, isLoading = _f.isPending;
    var handleSelectSite = function (e) {
        var value = e.target.value;
        var site = allSites.find(function (s) { return s.id === value; });
        setSite(site);
        var title = site.title, _a = site.contents, contents = _a === void 0 ? [] : _a;
        var siteSettings = contents.find(function (c) { return c.key === "settings"; }) || {};
        var content = __assign(__assign({}, siteSettings), { children: __spreadArray(__spreadArray([], (siteSettings.children || []), true), [{ key: "title", value: title }], false) });
        setSiteContent(enhanceContent(content));
        setIsSubmitted(false);
    };
    var handleContentChange = React.useCallback(function (updatedContent) {
        setSiteContent(updatedContent);
    }, []);
    var errors = useMemo(function () {
        if (!site || isLoading)
            return {};
        var name = site.name, host = site.host;
        var isRequired = function (test) { return (!test ? "Ce champs est obligatoire" : ""); };
        return {
            name: isRequired(name),
            host: isRequired(host),
        };
    }, [site, isLoading]);
    var handleDuplicateSite = function (e) {
        e.preventDefault();
        setIsSubmitted(true);
        var hasError = Object.values(errors).some(function (error) { return !!error; });
        if (!site || hasError)
            return;
        var _a = (siteContent || {}).children, children = _a === void 0 ? [] : _a;
        var titleChild = children.find(function (c) { return c.key === "title"; }) || { value: "" };
        var newSettings = siteContent && siteContent.children
            ? __assign(__assign({}, siteContent), { children: siteContent.children.filter(function (c) { return c.key !== "title"; }) }) : {};
        duplicateSite(__assign(__assign({}, site), { title: titleChild.value, contents: site.contents.map(function (c) {
                return c.key === "settings" ? removeProperties(newSettings, adminProperties) : c;
            }) }));
    };
    return (_jsxs("form", { onSubmit: handleDuplicateSite, children: [_jsx(Grid, { container: true, children: _jsx(Grid, { item: true, xs: 4, children: _jsx(FormControl, { children: _jsxs(Select, { value: (site === null || site === void 0 ? void 0 : site.id) || "", onChange: handleSelectSite, displayEmpty: true, disableUnderline: true, style: { fontSize: "1em", margin: 0 }, wrapperProps: undefined, children: [_jsx(MenuItem, { value: "", children: " -- S\u00E9lectionner un site -- " }), allSites === null || allSites === void 0 ? void 0 : allSites.map(function (site) { return (_jsx(MenuItem, { value: site.id, children: _jsx(FlagPicture, { lang: site.lang, title: site.name, hasWidthLabel: true, isBo: true }) }, site.id)); })] }) }) }) }), siteContent && (_jsx(SiteSettingsForm, { content: siteContent, onContentChange: handleContentChange, site: site, onSiteChange: setSite, errors: errors, isSubmitted: isSubmitted })), _jsxs(ModalActions, { children: [_jsx(Button, { color: "secondary", onClick: onCancel, children: "Annuler" }), _jsx(LoadingButton, { color: "primary", type: "submit", disabled: !site, loading: isLoading, children: "Dupliquer" })] })] }));
};
export default DuplicateSiteModal;
