var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CheckboxForm from "administration/components/contentForms/CheckboxForm";
import { FormFieldset } from "administration/components/contentForms/ContentFormStyled";
import InputForm from "administration/components/contentForms/InputForm";
import SelectPage from "administration/components/SelectPage";
import ContentsTypes from "shared/enums/contentsTypes";
import { useEffect, useState } from "react";
var linkTypes = {
    page: "page",
    url: "url",
};
var LinkForm = function (props) {
    var _a, _b;
    var content = props.content, onContentChange = props.onContentChange, _c = props.disabled, disabled = _c === void 0 ? false : _c, _d = props.formSubmitted, formSubmitted = _d === void 0 ? false : _d;
    var id = content.id, label = content.label, children = content.children;
    var pageChild = (_a = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.type === ContentsTypes.PAGE; })) !== null && _a !== void 0 ? _a : {};
    var urlChild = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.type === ContentsTypes.URL; });
    var titleUrlChild = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.type === ContentsTypes.INPUT; });
    var checkboxChild = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.type === ContentsTypes.CHECKBOX; });
    var _e = useState((pageChild === null || pageChild === void 0 ? void 0 : pageChild.value) ? linkTypes.page : linkTypes.url), linkType = _e[0], setLinkType = _e[1];
    useEffect(function () {
        setLinkType((pageChild === null || pageChild === void 0 ? void 0 : pageChild.value) ? linkTypes.page : linkTypes.url);
    }, [pageChild.value]);
    var handleLinkTypeChange = function (e) {
        var type = e.target.value;
        setLinkType(type);
    };
    var handleSelectPage = function (p) {
        var versions = p.versions, others = __rest(p, ["versions"]);
        // sort version by updated (last modified first)
        versions.sort(function (v1, v2) { return new Date(v2.updated).getTime() - new Date(v1.updated).getTime(); });
        var lastVersion = versions[0];
        if (lastVersion) {
            onContentChange(__assign(__assign({}, content), { children: children === null || children === void 0 ? void 0 : children.map(function (child) {
                    // Update page value
                    if (child === pageChild) {
                        return __assign(__assign({}, child), { value: __assign(__assign({}, lastVersion), { page: __assign({}, others) }) });
                    }
                    // Set url empty when a page is selected
                    if (child === urlChild) {
                        return __assign(__assign({}, child), { value: "" });
                    }
                    return child;
                }) }));
        }
    };
    var handleUrlChange = function (newUrlChild) {
        onContentChange(__assign(__assign({}, content), { children: children === null || children === void 0 ? void 0 : children.map(function (child) {
                // Set page empty when a page is selected
                if (child === pageChild) {
                    return __assign(__assign({}, child), { value: "" });
                }
                // Update url value
                if (child === urlChild) {
                    return newUrlChild;
                }
                return child;
            }) }));
    };
    var handleCheckboxChange = function (newCheckboxChild) {
        onContentChange(__assign(__assign({}, content), { children: children === null || children === void 0 ? void 0 : children.map(function (child) {
                if (child === checkboxChild) {
                    return newCheckboxChild;
                }
                return child;
            }) }));
    };
    var handleTitleUrlChange = function (newTitleUrlChild) {
        onContentChange(__assign(__assign({}, content), { children: children === null || children === void 0 ? void 0 : children.map(function (child) {
                if (child === titleUrlChild) {
                    return newTitleUrlChild;
                }
                return child;
            }) }));
    };
    return (_jsxs(FormFieldset, { children: [_jsx("legend", { children: label }), _jsxs(FormControl, { children: [_jsx(FormLabel, { component: "legend", children: "Type de lien" }), _jsxs(RadioGroup, { value: linkType, onChange: handleLinkTypeChange, row: true, children: [_jsx(FormControlLabel, { value: linkTypes.url, control: _jsx(Radio, { color: "secondary", disabled: disabled }), label: "Url" }), _jsx(FormControlLabel, { value: linkTypes.page, control: _jsx(Radio, { color: "secondary", disabled: disabled }), label: "Page" })] })] }), linkType === linkTypes.url && urlChild ? (_jsx(InputForm, { content: urlChild, onContentChange: handleUrlChange, regexValidation: urlChild.regexValidation, formSubmitted: formSubmitted })) : (_jsx(FormControl, { children: _jsx(SelectPage, { currentPage: (_b = pageChild.value) === null || _b === void 0 ? void 0 : _b.page, onSelectPage: handleSelectPage, disabled: disabled, label: pageChild === null || pageChild === void 0 ? void 0 : pageChild.label }) })), titleUrlChild && (_jsx(InputForm, { content: titleUrlChild, onContentChange: handleTitleUrlChange, placeholder: "Titre du lien", formSubmitted: formSubmitted })), checkboxChild && _jsx(CheckboxForm, { content: checkboxChild, onContentChange: handleCheckboxChange })] }, id));
};
export default LinkForm;
