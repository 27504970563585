var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, ClickAwayListener } from "@mui/material";
import adminActionsOnContent from "shared/enums/adminActionsOnContent";
import React from "react";
import useKeypress from "react-use-keypress";
import { StylesPosition } from "./styles-position";
export var AdminActionsContext = React.createContext({
    actions: [],
    parentActions: [],
    showActions: function () { },
    hideActions: function () { },
});
var ActionContainer = function (props) { return (_jsx(Box, { sx: { cursor: "pointer", mb: "-5px", pb: "5px" }, children: _jsx(Box, __assign({ sx: { borderLeft: "1px solid currentColor", p: "8px 8px 3px" } }, props)) })); };
var wrapperStyled = function (_a) {
    var isDisplayActions = _a.isDisplayActions, top = _a.top;
    return ({
        position: "relative",
        borderWidth: "1px",
        borderStyle: "dashed",
        borderColor: isDisplayActions ? "#333366" : "#33336618",
        mt: top ? 3 : 0,
        "&:hover": {
            borderColor: "#333366",
            "&> div": {
                opacity: 1,
            },
        },
    });
};
var actionsStyled = function (isDisplayActions) { return ({
    position: "absolute",
    zIndex: "50",
    display: "flex",
    alignItems: "center",
    opacity: isDisplayActions ? 1 : 0,
    color: "#DAE5FB",
}); };
var defaultActionsStyled = function (top) { return ({
    top: top ? -38 : 12,
    left: top ? -1 : 12,
    borderRadius: top ? "5px 5px 0px 0px" : "",
    bgcolor: top ? "#3787FF" : "",
}); };
var sortFunction = function (a, b) { return (a.order || Infinity) - (b.order || Infinity); };
var defaultActionPositionKey = "default";
var AdminActions = function (props) {
    var children = props.children, content = props.content, others = __rest(props, ["children", "content"]);
    var editionModalPosition = content.editionModalPosition, label = content.label;
    var top = editionModalPosition === "top";
    var _a = React.useState(false), isDisplayActions = _a[0], setIsDisplayActions = _a[1];
    var _b = React.useContext(AdminActionsContext).actions, actions = _b === void 0 ? [] : _b;
    var showActions = React.useCallback(function () { return setIsDisplayActions(true); }, []);
    var hideActions = React.useCallback(function () { return setIsDisplayActions(false); }, []);
    useKeypress(["Delete", "Backspace"], function () {
        var _a, _b;
        var activeElement = document.activeElement;
        if (activeElement) {
            if ((activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA") &&
                activeElement.getAttribute("role") === "textbox")
                if (isDisplayActions) {
                    var actionToExecute = actions.find(function (a) { return a.action === adminActionsOnContent.REMOVE_CONTENT; });
                    if (actionToExecute) {
                        (_b = (_a = actionToExecute.node) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.onClick();
                    }
                }
        }
    });
    var actionsByPosition = React.useMemo(function () {
        var _a;
        actions.sort(sortFunction);
        if (editionModalPosition) {
            return _a = {},
                _a[defaultActionPositionKey] = actions.map(function (a, id) { return (_jsx(ActionContainer, { children: a.node }, "act-cont-" + id)); }),
                _a;
        }
        var value = {};
        actions.forEach(function (a, id) {
            var node = a.node, _a = a.position, position = _a === void 0 ? defaultActionPositionKey : _a;
            if (!value[position]) {
                value[position] = [];
            }
            value[position].push(_jsx(ActionContainer, { children: node }, "act-cont-" + id));
        });
        return value;
    }, [actions, editionModalPosition]);
    if (!actions.length) {
        return children;
    }
    var _c = actionsByPosition, _d = defaultActionPositionKey, defaultPositionActions = _c[_d], othersPositionsActions = __rest(_c, [typeof _d === "symbol" ? _d : _d + ""]);
    var handleClickAway = function () {
        hideActions();
    };
    var handleClickContainer = function () {
        showActions();
    };
    return (_jsx(ClickAwayListener, { onClickAway: handleClickAway, children: _jsxs(Box, __assign({ sx: wrapperStyled({ isDisplayActions: isDisplayActions, top: top }), onClick: handleClickContainer }, others, { children: [_jsx(AdminActionsContext.Provider, { value: { showActions: showActions, hideActions: hideActions, parentActions: [], actions: [] }, children: _jsx(Box, { sx: __assign(__assign({}, actionsStyled(isDisplayActions)), defaultActionsStyled(top)), children: _jsxs(_Fragment, { children: [editionModalPosition ? (_jsx(Box, { sx: { px: 1, fontWeight: 700 }, style: { cursor: "default", color: "#ffffff" }, children: label })) : null, defaultPositionActions] }) }) }), Object.keys(othersPositionsActions).map(function (position) { return (_jsx(Box, { sx: __assign(__assign({}, actionsStyled(isDisplayActions)), StylesPosition.value(position)), children: othersPositionsActions[position] })); }), children] })) }));
};
export default AdminActions;
