var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import axios from "axios";
import generateCRUD from "administration/services/generateCRUD";
import { generateContentId } from "administration/utils/adminContentsUtils";
import { removeProperties } from "shared/utils/commonUtils";
import { useMutation } from "@tanstack/react-query";
import ContentsTypes from "shared/enums/contentsTypes";
var SitesCRUD = generateCRUD("sites");
var childCacheKey = "updated";
var params = {
    title: "test",
    menu: [],
    footer: [],
    header: [],
    home: {
        title: "Accueil",
        shortDescription: "Accueil",
        template: "HOME",
        path: "/",
        contents: [],
    },
    contents: [],
};
export var useDuplicateSite = function (config) {
    return useMutation(__assign(__assign({}, config), { mutationFn: function (site) {
            return axios.post("/api/sites/".concat(site.id, "/duplicate"), removeProperties(site, ["id", "settings"]));
        } }));
};
export var useCreateSite = function (config) {
    return useMutation(__assign(__assign({}, config), { mutationFn: function (_a) {
            var name = _a.name, host = _a.host, lang = _a.lang, contents = _a.contents;
            return axios.post("/createSite", {
                site: {
                    name: name,
                    host: host,
                    lang: lang,
                    contents: contents,
                },
            });
        } }));
};
export var createSiteForm = function (siteName, host, lang, cont) {
    var queryParams = {
        name: siteName,
        host: host,
        lang: lang,
        contents: cont,
    };
    return axios.post("/createSite", {
        site: queryParams,
    });
};
export var createPages = function (site) {
    var queryParams = [
        __assign({ name: site.name, host: site.host, lang: site.lang, id: site.id }, params),
    ];
    return axios.get("/createFullSite", {
        params: { site: queryParams },
    });
};
var AdminSitesService = /** @class */ (function (_super) {
    __extends(AdminSitesService, _super);
    function AdminSitesService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AdminSitesService.resetSiteCache = function (site) {
        var id = site.id, contents = site.contents;
        var cacheChild = contents.find(function (c) { return c.key === childCacheKey; });
        var newContents = [];
        if (cacheChild) {
            newContents = contents.map(function (c) { return (c.id === cacheChild.id ? __assign(__assign({}, cacheChild), { value: "".concat(new Date()) }) : c); });
        }
        else {
            newContents = __spreadArray(__spreadArray([], contents, true), [
                generateContentId({
                    children: [],
                    type: ContentsTypes.INPUT,
                    key: childCacheKey,
                    value: "".concat(new Date()),
                }),
            ], false);
        }
        SitesCRUD.update({ id: id, contents: newContents }).catch(function (e) { return console.error(e); });
    };
    AdminSitesService.export = function (siteId) {
        return axios.get("/api/sites/".concat(siteId, "/export"), { responseType: "blob" });
    };
    AdminSitesService.import = function (file) {
        return axios.post("/api/sites/import", file);
    };
    return AdminSitesService;
}(SitesCRUD));
export default AdminSitesService;
