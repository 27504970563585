var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import ColorForm from "administration/components/contentForms/ColorForm";
import { FormFieldset } from "administration/components/contentForms/ContentFormStyled";
import SelectForm from "administration/components/contentForms/SelectForm";
import IconAutocomplete from "administration/components/autocomplete/select/IconAutocomplete";
import useContentFormStore from "administration/store/ContentFormStore";
var IconForm = function (props) {
    var content = props.content, onContentChange = props.onContentChange;
    var id = content.id, children = content.children;
    var displayVertical = useContentFormStore(function (state) { return ({ displayVertical: state.displayVertical }); }).displayVertical;
    var typeChild = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.key === "type"; });
    var iconChild = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.key === "icon"; });
    var colorChild = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.key === "color"; });
    var backgroundColorChild = children === null || children === void 0 ? void 0 : children.find(function (c) { return c.key === "background"; });
    var handleChildChange = function (oldChild) { return function (newChild) {
        onContentChange(__assign(__assign({}, content), { children: children === null || children === void 0 ? void 0 : children.map(function (child) {
                if (child === oldChild) {
                    return newChild;
                }
                return child;
            }) }));
    }; };
    return (_jsxs(FormFieldset, { children: [_jsx("legend", { children: "Ic\u00F4ne" }), _jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: displayVertical ? 12 : 6, children: typeChild && _jsx(SelectForm, { content: typeChild, onContentChange: handleChildChange(typeChild) }) }), _jsx(Grid, { item: true, xs: displayVertical ? 12 : 6, children: iconChild && (_jsx(IconAutocomplete, { onContentChange: handleChildChange(iconChild), content: iconChild, type: typeChild === null || typeChild === void 0 ? void 0 : typeChild.value })) }), _jsx(Grid, { item: true, xs: displayVertical ? 12 : 6, children: colorChild && _jsx(ColorForm, { content: colorChild, onContentChange: handleChildChange(colorChild) }) }), _jsx(Grid, { item: true, xs: displayVertical ? 12 : 6, children: backgroundColorChild && (_jsx(ColorForm, { content: backgroundColorChild, onContentChange: handleChildChange(backgroundColorChild) })) })] })] }, id));
};
export default IconForm;
