var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Grid } from "@mui/material";
import Modal from "administration/components/Modal";
import ModalConfirm from "administration/components/ModalConfirm";
import PreventNavigation from "administration/components/PreventNavigation";
import RenameVersionForm from "administration/components/RenameVersionForm";
import SelectVersion from "administration/components/SelectVersion";
import Icon from "shared/components/Icon";
import Loader from "shared/components/Loader";
import AdminSitesService from "administration/services/AdminSitesService";
import { useSites } from "shared/hooks/SiteHook";
import { useThemeBackOffice } from "administration/theme/ThemeBackOfficeProvider";
import { PageVersionStatus } from "shared/types/cms/page/version/page-version.status.enum";
import { Fragment, useCallback, useEffect, useMemo, useState, } from "react";
import DefaultMessage from "./DefaultMessage";
import useEditorStore from "administration/store/EditorStore";
import { useShallow } from "zustand/react/shallow";
import usePageChangeStore from "administration/store/PageChangeStore";
import useAdminPageVersionStore from "administration/store/AdminPageVersionStore";
import { usePageVersion } from "administration/hooks/AdminPageVersionHook";
var actionBarStyled = function (principal) { return ({
    position: principal ? "fixed" : "initial",
    top: 0,
    left: 50,
    right: 0,
    height: "80px",
    bgcolor: principal ? "layout.background" : "transparent",
    borderBottom: "1px solid",
    borderColor: "layout.border",
    zIndex: 350,
    pr: 4,
    transition: "background-color 1000ms ease, color 1000ms ease, border-color 1000ms ease",
}); };
var pendingVersionActionStyled = {
    mt: 0.5,
    fontSize: "0.79rem",
    color: "layout.draft",
    cursor: "pointer",
    textAlign: "center",
    "&:hover": {
        textDecoration: "underline",
    },
};
var unpublishedStyled = {
    color: "unpublished.text",
    bgcolor: "unpublished.main",
    "&:hover": {
        bgcolor: "unpublished.dark",
    },
};
var AdminVersions = function (props) {
    var _a = props.showVersion, showVersion = _a === void 0 ? true : _a, leftActions = props.leftActions, rightActions = props.rightActions, versions = props.versions, currentVersion = props.currentVersion, setCurrentVersion = props.setCurrentVersion, onVersionChange = props.onVersionChange, canRenameProp = props.canRename, saveVersion = props.saveVersion, canPublishProp = props.canPublish, canUnpublishProp = props.canUnpublish, updateStatus = props.updateStatus, canDeleteProp = props.canDelete, deleteVersion = props.deleteVersion, children = props.children, _b = props.autoSaveDelay, autoSaveDelay = _b === void 0 ? 2000 : _b, manualSave = props.manualSave, principal = props.principal, title = props.title;
    var currentVersionId = currentVersion.id;
    var loadingPageVersion = useMemo(function () { return !versions.some(function (v) { return v.id === currentVersionId; }); }, [currentVersionId, versions]);
    var currentSite = useSites().currentSite;
    var _c = usePageChangeStore(useShallow(function (state) { return ({
        pageChanges: state.pageChanges,
        setPageChanges: state.setPageChanges,
    }); })), pageChanges = _c.pageChanges, setPageChanges = _c.setPageChanges;
    var _d = useAdminPageVersionStore(function (state) { return ({
        versionIsCanceled: state.versionIsCanceled,
        setVersionIsCanceled: state.setVersionIsCanceled,
        versionHasChanged: state.versionHasChanged,
        setVersionHasChanged: state.setVersionHasChanged,
    }); }), versionIsCanceled = _d.versionIsCanceled, setVersionIsCanceled = _d.setVersionIsCanceled, versionHasChanged = _d.versionHasChanged, setVersionHasChanged = _d.setVersionHasChanged;
    var _e = useEditorStore(useShallow(function (state) { return ({
        setEditorValueChange: state.setEditorValueChange,
        editorValueChange: state.editorValueChange,
    }); })), editorValueChange = _e.editorValueChange, setEditorValueChange = _e.setEditorValueChange;
    var setCurrentPageVersion = useAdminPageVersionStore(function (state) { return ({
        setCurrentPageVersion: state.setCurrentPageVersion,
    }); }).setCurrentPageVersion;
    // const theme = useTheme();
    var theme = useThemeBackOffice().themeBackOffice;
    var _f = useState(null), autoSaveTimeout = _f[0], setAutoSaveTimeout = _f[1];
    var _g = useState(null), versionMessage = _g[0], setVersionMessage = _g[1];
    var _h = useState(false), isSaving = _h[0], setIsSaving = _h[1];
    var _j = useState(false), isPendingVersion = _j[0], setIsPendingVersion = _j[1];
    var pageVersions = usePageVersion().pageVersions;
    var _k = useState({
        content: null,
        size: null,
    }), modal = _k[0], setModal = _k[1];
    useEffect(function () {
        setVersionHasChanged(false);
        setVersionMessage(null);
        setIsPendingVersion(false);
    }, [currentVersionId, setVersionHasChanged]);
    useEffect(function () {
        if (pageChanges && pageChanges.length === 0) {
            if (!isSaving) {
                setVersionMessage(null);
            }
            setVersionHasChanged(false);
            setIsPendingVersion(false);
            setVersionIsCanceled(true);
        }
    }, [isSaving, pageChanges, setVersionHasChanged, setVersionIsCanceled]);
    var openModal = function (content, size) {
        if (size === void 0) { size = null; }
        setModal({ content: content, size: size });
    };
    var closeModal = function () {
        return setModal({
            content: null,
            size: null,
        });
    };
    var handleClickRenameVersion = function () {
        openModal(_jsx(RenameVersionForm, { versionName: currentVersion.versionName, onValidate: function (name) {
                saveVersion(__assign(__assign({}, currentVersion), { versionName: name })).then(function () {
                    setCurrentVersion(currentVersion);
                    closeModal();
                });
            }, onCancel: closeModal }), "sm");
    };
    var handleClickDeleteVersion = function () {
        openModal(_jsx(ModalConfirm, { title: "Supprimer cette version", text: "Attention cette action est irr\u00E9versible", confirmText: "Supprimer", error: true, onConfirm: function () {
                if (deleteVersion) {
                    deleteVersion(currentVersion).then(function () {
                        setVersionHasChanged(false);
                        setCurrentVersion();
                        closeModal();
                    });
                }
            }, onCancel: closeModal }), "sm");
    };
    var updateVersionStatus = function (_a) {
        var version = _a.version, status = _a.status;
        AdminSitesService.resetSiteCache(currentSite);
        return (updateStatus &&
            updateStatus({ version: version, status: status }).then(function (statusData) {
                return setCurrentVersion(__assign(__assign({}, version), { status: statusData.status, updated: statusData.updated }));
            }));
    };
    var handleClickPublish = function () {
        var status = currentVersion.status;
        if (status === PageVersionStatus.DRAFT) {
            updateVersionStatus({ version: currentVersion, status: PageVersionStatus.PUBLISHED });
        }
        else {
            saveVersion(currentVersion).then(function (newVersion) {
                return updateVersionStatus({ version: newVersion, status: PageVersionStatus.PUBLISHED });
            });
        }
    };
    var handleClickUnpublish = function () {
        updateVersionStatus({ version: currentVersion, status: PageVersionStatus.ARCHIVED });
    };
    var handleSaveVersion = useCallback(function (version, refresh) {
        if (refresh === void 0) { refresh = true; }
        setIsPendingVersion(false);
        if (manualSave)
            setPageChanges([]);
        if (!isSaving) {
            if (version.status === PageVersionStatus.DRAFT) {
                setVersionMessage("Enregistrement...");
            }
            else {
                setVersionMessage("Création d'un nouveau brouillon...");
            }
            setAutoSaveTimeout(null);
            setIsSaving(true);
            saveVersion(version).then(function (v) {
                if (refresh) {
                    setIsSaving(false);
                    setCurrentVersion(v);
                    setVersionHasChanged(false);
                    setVersionMessage(null);
                }
                else {
                    setIsSaving(false);
                    setVersionHasChanged(false);
                    setVersionMessage(null);
                }
            });
        }
    }, [saveVersion, isSaving, manualSave]);
    var handleVersionChange = useCallback(function (version) {
        if (version.id === currentVersionId && (!versionIsCanceled || (versionIsCanceled && !editorValueChange))) {
            if (manualSave) {
                setIsPendingVersion(true);
                setVersionMessage("Modifications en attente");
            }
            else
                setVersionMessage("Modifications en cours");
            setVersionHasChanged(true);
            onVersionChange(version);
            if ((autoSaveDelay && autoSaveDelay > 0) || !manualSave) {
                if (autoSaveTimeout) {
                    clearTimeout(autoSaveTimeout);
                }
                if (autoSaveDelay && autoSaveDelay > 0) {
                    var newTimeout = setTimeout(function () {
                        handleSaveVersion(version);
                    }, autoSaveDelay);
                    setAutoSaveTimeout(newTimeout);
                }
            }
        }
        if (versionIsCanceled)
            setVersionIsCanceled(false);
        if (editorValueChange)
            setEditorValueChange(false);
    }, [
        currentVersionId,
        onVersionChange,
        autoSaveTimeout,
        autoSaveDelay,
        handleSaveVersion,
        manualSave,
        versionIsCanceled,
        editorValueChange,
        setVersionIsCanceled,
        setEditorValueChange,
        setVersionHasChanged,
    ]);
    var handleShowPrompt = function () {
        if (autoSaveTimeout) {
            clearTimeout(autoSaveTimeout);
        }
    };
    var handleClickNavigate = function () {
        setVersionHasChanged(false);
        setVersionMessage(null);
    };
    var handleCancel = function () {
        setVersionHasChanged(false);
        setVersionMessage(null);
        setIsPendingVersion(false);
        setVersionIsCanceled(true);
        setPageChanges([]);
        var oldPageVersion = pageVersions === null || pageVersions === void 0 ? void 0 : pageVersions.find(function (version) { return version.id === currentVersionId; });
        setCurrentPageVersion(oldPageVersion);
    };
    var status = (currentVersion || {}).status;
    var isDraft = status === PageVersionStatus.DRAFT;
    var isPublished = status === PageVersionStatus.PUBLISHED;
    var canRename = canRenameProp && isDraft;
    var canPublish = canPublishProp && typeof updateStatus === "function" && !versionHasChanged && !isPublished;
    var canUnpublish = canUnpublishProp && typeof updateStatus === "function" && isPublished;
    var canDelete = canDeleteProp && typeof deleteVersion === "function" && isDraft && versions.length > 1;
    return (_jsxs(Fragment, { children: [_jsx(PreventNavigation, { blocked: versionHasChanged && !isSaving, onSave: function () { return handleSaveVersion(currentVersion, false); }, onShowPrompt: handleShowPrompt, onNavigate: handleClickNavigate }), _jsx(Modal, { open: !!modal.content, size: modal.size, onClose: closeModal, children: modal.content ? modal.content : _jsx(_Fragment, {}) }), _jsxs(Box, { sx: { flexGrow: 1, height: "100%", display: "flex", flexDirection: "column" }, children: [_jsx(Box, { sx: actionBarStyled(principal), children: _jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", sx: { height: "100%" }, children: [leftActions && (_jsx(Grid, { item: true, sx: { height: "100%" }, children: leftActions })), showVersion && (_jsx(Grid, { item: true, sx: { flex: "auto" }, children: !loadingPageVersion ? (_jsxs(Grid, { container: true, direction: "row", alignItems: "center", spacing: 2, children: [_jsx(Grid, { item: true, children: versionMessage ? (_jsxs(_Fragment, { children: [_jsx(Box, { component: "span", sx: {
                                                                fontSize: isPendingVersion ? "0.875rem" : "0.79rem",
                                                                color: isPendingVersion ? "typography.default" : "layout.draft",
                                                            }, children: versionMessage }), isPendingVersion && (_jsx(Box, { sx: pendingVersionActionStyled, onClick: handleCancel, children: "Annuler" }))] })) : (_jsx(DefaultMessage, { title: title, version: currentVersion })) }), _jsx(Grid, { item: true, children: _jsxs(Grid, { container: true, alignItems: "center", spacing: 2, children: [_jsx(Grid, { item: true, children: _jsx(SelectVersion, { versions: versions, selectedVersion: currentVersion, onSelectVersion: setCurrentVersion }) }), _jsx(Grid, { item: true, children: _jsxs(Grid, { container: true, direction: "column", spacing: 1, children: [canRename && (_jsx(Grid, { item: true, children: _jsx(Icon, { icon: "edit", type: "fas", title: "Renommer cette version", onClick: handleClickRenameVersion, style: {
                                                                                fontSize: "0.83rem",
                                                                                cursor: "pointer",
                                                                                color: (theme === null || theme === void 0 ? void 0 : theme.palette).layout.icon,
                                                                            } }) })), canDelete && (_jsx(Grid, { item: true, children: _jsx(Icon, { icon: "trash", type: "fas", title: "Supprimer cette version", onClick: handleClickDeleteVersion, style: {
                                                                                fontSize: "0.83rem",
                                                                                cursor: "pointer",
                                                                                color: (theme === null || theme === void 0 ? void 0 : theme.palette).layout.icon,
                                                                            } }) }))] }) })] }) })] })) : (_jsx(Loader, {})) })), _jsx(Grid, { item: true, children: _jsxs(Grid, { container: true, alignItems: "center", spacing: 2, children: [_jsx(Grid, { item: true, children: rightActions }), manualSave && (_jsx(Grid, { item: true, children: _jsx(Button, { color: "secondary", onClick: function () { return handleSaveVersion(currentVersion); }, disabled: !versionHasChanged || isSaving, children: "Enregistrer" }) })), _jsxs(Grid, { item: true, children: [!canUnpublish && canPublishProp && (_jsx(Button, { color: "primary", onClick: handleClickPublish, disabled: !canPublish || versionHasChanged, children: "Publier cette version" })), canUnpublish && (_jsx(Button, { sx: unpublishedStyled, onClick: handleClickUnpublish, disabled: manualSave && isPendingVersion, children: "D\u00E9publier cette version" }))] })] }) })] }) }), _jsx(Box, { sx: { flexGrow: 1, pt: principal ? "80px" : 0 }, children: children(handleVersionChange) })] })] }));
};
export default AdminVersions;
