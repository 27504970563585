var LanguageDefault;
(function (LanguageDefault) {
    LanguageDefault["FR"] = "fr";
    LanguageDefault["GB"] = "gb";
    LanguageDefault["ES"] = "es";
    LanguageDefault["DE"] = "de";
    LanguageDefault["IT"] = "it";
    LanguageDefault["PT"] = "pt";
})(LanguageDefault || (LanguageDefault = {}));
export var Languages = Object.values(LanguageDefault);
