var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Popover } from "@mui/material";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { useKeycloak } from "@react-keycloak/web";
import AdminContentModal from "administration/components/admin/AdminContentEditor/AdminContentEditor";
import AdminPageForm from "administration/components/AdminPageForm";
import AdminSection from "administration/components/AdminSection";
import AdminFooterForm from "administration/components/contentForms/AdminSection/AdminFooterForm";
import AdminMenuForm from "administration/components/contentForms/AdminSection/AdminMenuForm";
import AvailableContentsPreview from "shared/components/contents/dynamic/AvailableContentsPreview";
import DuplicateSiteModal from "administration/components/DuplicateSiteModal";
import GEDModal from "administration/components/GEDModal";
import ImportExportModal from "administration/components/ImportExportModal";
import { SideBarContext } from "administration/components/Layout/SideBar";
import Modal from "administration/components/Modal";
import ModalConfirm from "administration/components/ModalConfirm";
import PagesTable from "administration/components/PagesTable";
import Icon from "shared/components/Icon";
import AdminPagesService from "administration/services/AdminPagesService";
import AdminPageVersionsService from "administration/services/AdminPageVersionsService";
import AdminSitesService from "administration/services/AdminSitesService";
import { useSites } from "shared/hooks/SiteHook";
import { useGenerateSitePDF } from "shared/services/SiteService";
import { PageRole, PageVersionRole, SiteRole } from "shared/types/cms/common/roles.enum";
import { PageVersionStatus } from "shared/types/cms/page/version/page-version.status.enum";
import { downloadFile, removeProperties } from "shared/utils/commonUtils";
import { adminProperties } from "administration/utils/adminContentsUtils";
import sectionsTypes from "shared/enums/sectionsTypes";
import useEnvironment from "shared/hooks/useEnvironment";
import CmsHooks from "shared/utils/register/CmsHooks";
import dayjs from "dayjs";
import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import AdminVersionsPrincipal from "./AdminVersionsPrincipal";
import { HOOKS } from "shared/utils/register/definitions";
import LoadingPage from "./LoadingPage";
import { FlagPicture } from "shared/components/site-translation/FlagPicture";
import useSnackbarStore from "shared/store/SnackbarStore";
import usePageChangeStore from "administration/store/PageChangeStore";
import { useSetCurrentSite } from "administration/hooks/AdminSiteHook";
import { useCurrentPage } from "administration/hooks/AdminPageHook";
import useAdminPageVersionStore from "administration/store/AdminPageVersionStore";
import { useHandleChangePageVersion, usePageVersion } from "administration/hooks/AdminPageVersionHook";
import { useShallow } from "zustand/react/shallow";
export var SiteGroup = {
    name: "site",
    order: 2,
};
export var PageGroup = {
    name: "page",
    order: 3,
};
export var MediaGroup = {
    name: "media",
    order: 4,
};
export var ContentsGroup = {
    name: "contents",
    order: 5,
};
export var DataGroup = {
    name: "data",
    order: 6,
};
var defaultModal = {
    content: null,
    size: null,
    title: undefined,
};
var AdminWebsite = function () {
    var _a, _b, _c, _d;
    var _e = React.useState(defaultModal), modal = _e[0], setModal = _e[1];
    var _f = React.useState(), adminContent = _f[0], setAdminContent = _f[1];
    var _g = React.useState(false), selectSiteAnchor = _g[0], setSelectSiteAnchor = _g[1];
    var _h = useSites(), allSites = _h.allSites, currentSite = _h.currentSite, refreshSite = _h.refreshSite;
    var setCurrentSite = useSetCurrentSite().setCurrentSite;
    var setPageChanges = usePageChangeStore(function (state) { return ({
        setPageChanges: state.setPageChanges,
    }); }).setPageChanges;
    var _j = useCurrentPage(), currentPage = _j.currentPage, setCurrentPage = _j.setCurrentPage;
    var currentPageVersion = useAdminPageVersionStore(useShallow(function (state) { return ({
        currentPageVersion: state.currentPageVersion,
    }); })).currentPageVersion;
    var handleChangePageVersion = useHandleChangePageVersion().handleChangePageVersion;
    var _k = usePageVersion(), pageVersions = _k.pageVersions, refreshPageVersions = _k.refreshPageVersions;
    var queryClient = useQueryClient();
    var currentSiteTheme = React.useMemo(function () {
        var projectTheme = CmsHooks.getHook(HOOKS.THEME);
        var getTheme = CmsHooks.getHook(HOOKS.GET_THEME);
        return (typeof getTheme === "function" && getTheme({ site: currentSite })) || projectTheme || createTheme();
    }, [currentSite]);
    React.useEffect(function () {
        if (currentPageVersion) {
            setPageChanges(true);
        }
    }, [currentPageVersion, currentSite, setPageChanges]);
    var excludedTemplatesForUpdate = React.useMemo(function () { return CmsHooks.getHook(HOOKS.TEMPLATES_excludedTemplatesForPageUpdate) || []; }, []);
    var lockedTemplate = React.useMemo(function () {
        return currentPageVersion
            ? Boolean(excludedTemplatesForUpdate.find(function (template) { return template.key === (currentPageVersion === null || currentPageVersion === void 0 ? void 0 : currentPageVersion.template); }))
            : true;
    }, [excludedTemplatesForUpdate, currentPageVersion]);
    var environment = useEnvironment();
    var keycloak = useKeycloak().keycloak;
    var canUpdateSite = keycloak.hasResourceRole(SiteRole.UPDATE_SITE, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms);
    var canCreatePage = keycloak.hasResourceRole(PageRole.CREATE_PAGE, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms) &&
        keycloak.hasResourceRole(PageVersionRole.CREATE_PAGE_VERSION, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms);
    var canUpdateVersion = keycloak.hasResourceRole(PageVersionRole.UPDATE_PAGE_VERSION, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms) && !lockedTemplate;
    var canImportSite = keycloak.hasResourceRole(SiteRole.IMPORT_SITE, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms);
    var canExportSite = keycloak.hasResourceRole(SiteRole.EXPORT_SITE, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms);
    var canDuplicateSite = keycloak.hasResourceRole(SiteRole.DUPLICATE_SITE, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms);
    var canAccessToGed = keycloak.hasResourceRole("ged_reader", environment === null || environment === void 0 ? void 0 : environment.keycloak_client_ged);
    var closeModal = React.useCallback(function () {
        setModal(defaultModal);
    }, []);
    var closeAdminContentModal = React.useCallback(function () {
        setAdminContent(undefined);
    }, []);
    var _l = useSnackbarStore(function (state) { return ({
        displaySuccess: state.displaySuccess,
        displayError: state.displayError,
    }); }), displaySuccess = _l.displaySuccess, displayError = _l.displayError;
    var _m = React.useContext(SideBarContext), addSideBarItems = _m.addItems, removeSideBarItems = _m.removeItems, addSideBarGroups = _m.addGroups, removeSideBarGroups = _m.removeGroups, setSidebarExpanded = _m.setExpanded;
    React.useEffect(function () {
        var groups = [SiteGroup, PageGroup, MediaGroup, ContentsGroup, DataGroup];
        addSideBarGroups(groups);
        return function () {
            removeSideBarGroups(groups);
        };
    }, [addSideBarGroups, removeSideBarGroups]);
    var pageRef = React.useRef(null);
    React.useEffect(function () {
        if (pageRef === null || pageRef === void 0 ? void 0 : pageRef.current)
            pageRef.current.scrollTop = 0;
    }, [currentPage]);
    var resetUpdatedDateOfContents = React.useCallback(function (contents, updatedAt) {
        if (!contents)
            return contents;
        return contents.map(function (content) { return (__assign(__assign({}, content), { updatedAt: updatedAt, children: content.children ? resetUpdatedDateOfContents(content.children, updatedAt) : [] })); });
    }, []);
    var setCurrentPageVersion = React.useCallback(function (version) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, refreshPageVersions()];
                case 1:
                    _a.sent();
                    version.contents = resetUpdatedDateOfContents(version.contents, dayjs());
                    handleChangePageVersion(version);
                    return [2 /*return*/];
            }
        });
    }); }, [pageVersions, refreshPageVersions, resetUpdatedDateOfContents, handleChangePageVersion]);
    var handleSaveVersion = React.useCallback(function (pageVersion) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1, version, e_2, version, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, AdminPagesService.update(pageVersion.page)];
                case 1:
                    _a.sent();
                    displaySuccess("La page a été mise à jour");
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    displayError("Erreur lors de la mise à jour de la page");
                    return [2 /*return*/];
                case 3:
                    if (!(pageVersion.status === PageVersionStatus.DRAFT)) return [3 /*break*/, 9];
                    _a.label = 4;
                case 4:
                    _a.trys.push([4, 6, , 8]);
                    return [4 /*yield*/, AdminPageVersionsService.update(pageVersion)];
                case 5:
                    version = _a.sent();
                    displaySuccess("Le brouillon a été mis à jour");
                    return [2 /*return*/, version];
                case 6:
                    e_2 = _a.sent();
                    return [4 /*yield*/, setCurrentPageVersion(pageVersion)];
                case 7:
                    _a.sent();
                    displayError("Erreur lors de la mise à jour du brouillon");
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
                case 9:
                    _a.trys.push([9, 11, , 13]);
                    return [4 /*yield*/, AdminPageVersionsService.create(pageVersion)];
                case 10:
                    version = _a.sent();
                    displaySuccess("La version a été mise à jour");
                    return [2 /*return*/, version];
                case 11:
                    e_3 = _a.sent();
                    return [4 /*yield*/, setCurrentPageVersion(pageVersion)];
                case 12:
                    _a.sent();
                    displayError("Erreur lors de la mise à jour de la version");
                    return [3 /*break*/, 13];
                case 13: return [2 /*return*/];
            }
        });
    }); }, [displaySuccess, setCurrentPageVersion, displayError]);
    var updateSite = React.useCallback(function (settings) {
        var children = (settings || {}).children;
        var titleChild = children.find(function (c) { return c.key === "title"; }) || {};
        var newSettings = __assign(__assign({}, settings), { children: settings.children.filter(function (c) { return c.key !== "title"; }) });
        if (currentSite) {
            AdminSitesService.update({
                id: currentSite.id,
                title: titleChild.value,
                contents: currentSite.contents.map(function (c) {
                    return c.key === "settings" ? removeProperties(newSettings, adminProperties) : c;
                }),
            })
                .then(function () {
                refreshSite();
                closeModal();
                displaySuccess("Le site a été mis à jour.");
            })
                .catch(function () {
                closeModal();
                displayError("Une erreur est survenue.");
            });
        }
    }, [closeModal, currentSite, displayError, displaySuccess, refreshSite]);
    var handleClickSiteSettings = React.useCallback(function () {
        var _a, _b;
        var _c = currentSite || {}, title = _c.title, _d = _c.contents, contents = _d === void 0 ? [] : _d;
        var siteSettings = (_a = contents === null || contents === void 0 ? void 0 : contents.find(function (c) { return c.key === "settings"; })) !== null && _a !== void 0 ? _a : {};
        if (siteSettings) {
            setAdminContent(__assign(__assign({}, siteSettings), { children: __spreadArray(__spreadArray([], ((_b = siteSettings === null || siteSettings === void 0 ? void 0 : siteSettings.children) !== null && _b !== void 0 ? _b : []), true), [{ key: "title", value: title }], false) }));
        }
    }, [currentSite]);
    var handleSiteSettingsChange = React.useCallback(function (settings) {
        if (currentSite) {
            setModal({
                content: (_jsx(ModalConfirm, { title: "Param\u00E8tres du site : ".concat(currentSite.name), text: "Attention, cette action sera effective imm\u00E9diatement sur le site", confirmText: "Mettre \u00E0 jour le site", onConfirm: function () {
                        updateSite(settings);
                    }, onCancel: function () {
                        closeModal();
                        handleClickSiteSettings();
                    } })),
                size: "sm",
            });
        }
    }, [closeModal, currentSite, handleClickSiteSettings, updateSite]);
    var handleValidateAdminContentModal = React.useCallback(function (settings) {
        closeAdminContentModal();
        handleSiteSettingsChange(settings);
    }, [closeAdminContentModal, handleSiteSettingsChange]);
    var handleClickPages = React.useCallback(function () {
        setModal({
            content: (_jsx("div", { style: { height: "65vh", overflow: "hidden" }, children: _jsx(PagesTable, { onPageClick: function (event, page) {
                        setCurrentPage(page);
                        closeModal();
                    } }) })),
            size: "md",
            title: "Les pages du site",
        });
    }, [closeModal, setCurrentPage]);
    var handleClickAdminMenu = React.useCallback(function () {
        setModal({
            title: "Gestion du menu",
            content: (_jsx(AdminSection, { title: "Gestion du menu", type: sectionsTypes.MENU, onSectionChanged: function (response) {
                    if (response === null || response === void 0 ? void 0 : response.sectionId) {
                        queryClient.setQueryData(["menu", { siteId: currentSite === null || currentSite === void 0 ? void 0 : currentSite.id }], response);
                    }
                }, children: function (_a) {
                    var section = _a.section, onSectionChange = _a.onSectionChange;
                    return (_jsx(AdminMenuForm, { menu: section, maxDepth: 1, currentDepth: 0, onMenuChange: onSectionChange, canUpdate: canUpdateVersion, itemKey: "menus", subItemKey: "subMenus" }));
                } })),
            size: "md",
        });
    }, [canUpdateVersion, currentSite, queryClient]);
    var handleClickAdminFooter = React.useCallback(function () {
        setModal({
            title: "Gestion du footer",
            content: (_jsx(AdminSection, { title: "Gestion du footer", type: sectionsTypes.FOOTER, onSectionChanged: function (response) {
                    if (response === null || response === void 0 ? void 0 : response.sectionId) {
                        queryClient.setQueryData(["footer", { siteId: currentSite === null || currentSite === void 0 ? void 0 : currentSite.id }], response);
                    }
                }, children: function (_a) {
                    var section = _a.section, onSectionChange = _a.onSectionChange;
                    return (_jsx(AdminFooterForm, { footer: section, onFooterChange: onSectionChange, canUpdate: canUpdateVersion }));
                } })),
            size: "lg",
        });
    }, [canUpdateVersion, currentSite, queryClient]);
    var handleLoadingModal = React.useCallback(function (titleModal) {
        setModal({
            content: _jsx(LoadingPage, { title: "Chargement...", sx: { height: "20vh" } }),
            size: "md",
            title: titleModal !== null && titleModal !== void 0 ? titleModal : "",
        });
    }, [setModal]);
    var createPage = React.useCallback(function (version, site) { return __awaiter(void 0, void 0, void 0, function () {
        var page, name, parentId, newPage, createdPage, newPageVersion, e_4, e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    page = version.page;
                    name = page.name, parentId = page.parentId;
                    newPage = {
                        name: name,
                        parentId: parentId,
                        siteId: (site === null || site === void 0 ? void 0 : site.id) || (currentSite === null || currentSite === void 0 ? void 0 : currentSite.id),
                    };
                    createdPage = null;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 8, , 9]);
                    return [4 /*yield*/, AdminPagesService.create(newPage)];
                case 2:
                    createdPage = _a.sent();
                    newPageVersion = __assign(__assign({}, version), { page: createdPage });
                    _a.label = 3;
                case 3:
                    _a.trys.push([3, 5, , 7]);
                    handleLoadingModal();
                    return [4 /*yield*/, AdminPageVersionsService.create(newPageVersion)];
                case 4:
                    _a.sent();
                    closeModal();
                    return [3 /*break*/, 7];
                case 5:
                    e_4 = _a.sent();
                    // Rollback page
                    return [4 /*yield*/, AdminPagesService.delete(createdPage)];
                case 6:
                    // Rollback page
                    _a.sent();
                    throw e_4;
                case 7: return [3 /*break*/, 9];
                case 8:
                    e_5 = _a.sent();
                    createdPage = null;
                    console.error(e_5);
                    return [3 /*break*/, 9];
                case 9:
                    if (createdPage) {
                        if (site) {
                            setCurrentSite(site);
                        }
                        else {
                            setCurrentPage(createdPage);
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [closeModal, currentSite, setCurrentPage, setCurrentSite, handleLoadingModal]);
    var handleClickAddPage = React.useCallback(function () {
        setModal({
            content: _jsx(AdminPageForm, { onValidate: createPage, onCancel: closeModal }),
            size: "md",
            title: "Créer une nouvelle page",
        });
    }, [closeModal, createPage]);
    var handleClickDuplicatePage = React.useCallback(function () {
        setModal({
            content: (_jsx(AdminPageForm, { version: currentPageVersion, onValidate: createPage, onCancel: closeModal, isDuplicateMod: true })),
            size: "md",
            title: "Dupliquer la page",
        });
    }, [currentPageVersion, closeModal, createPage]);
    var handleClickAdminPage = React.useCallback(function () {
        setModal({
            content: (_jsx(AdminPageForm, { version: currentPageVersion, onValidate: function (p) {
                    handleSaveVersion(p).then(setCurrentPageVersion);
                    closeModal();
                }, onCancel: closeModal })),
            size: "md",
            title: "Mettre à jour la page",
        });
    }, [currentPageVersion, closeModal, handleSaveVersion, setCurrentPageVersion]);
    var handleClickMedias = React.useCallback(function () {
        setModal({
            title: "ged",
            content: _jsx(GEDModal, {}),
            size: "lg",
        });
    }, []);
    var handleClickPreviewContents = React.useCallback(function () {
        setModal({
            title: "preview",
            content: (_jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(ThemeProvider, { theme: currentSiteTheme, children: _jsx(AvailableContentsPreview, {}) }) })),
            size: "md",
        });
    }, [currentSiteTheme]);
    var handleClickImportExport = React.useCallback(function () {
        setModal({
            content: _jsx(ImportExportModal, {}),
            size: "md",
            title: "Importer/Exporter un site",
        });
    }, []);
    var handleClickDuplicateSite = React.useCallback(function () {
        setModal({
            content: _jsx(DuplicateSiteModal, { onCancel: closeModal }),
            size: "md",
            title: "Dupliquer un site",
        });
    }, [closeModal]);
    var handleClickChangeSite = React.useCallback(function () {
        setSelectSiteAnchor(true);
    }, []);
    var handleClickSelectSite = React.useCallback(function (site) { return function (e) {
        e.stopPropagation();
        setCurrentSite(site);
        setSidebarExpanded(false);
        setSelectSiteAnchor(false);
    }; }, [setCurrentSite, setSidebarExpanded]);
    var _o = useGenerateSitePDF({
        onSuccess: function (_a) {
            var pdf = _a.data;
            var blob = new Blob([pdf], { type: "application/pdf" });
            currentSite && downloadFile(blob, "".concat(currentSite.name, ".pdf"));
        },
        onError: function () {
            displayError("Erreur lors de la génération du rapport du site");
        },
    }), generateSitePDF = _o.mutate, isReportSiteLoading = _o.isPending;
    var handleClickGenerateSiteReport = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            currentSite && generateSitePDF({ siteId: currentSite.id, host: currentSite.host });
            return [2 /*return*/];
        });
    }); }, [currentSite, generateSitePDF]);
    React.useEffect(function () {
        var sideBarItems = [
            {
                icon: _jsx(Icon, { icon: "compass" }),
                label: "Pages du site",
                title: "Pages du site",
                onClick: handleClickPages,
                group: SiteGroup.name,
                order: 3,
            },
            {
                icon: _jsx(Icon, { icon: "bars" }),
                label: "Gérer le menu du site",
                title: "Gérer le menu du site",
                onClick: handleClickAdminMenu,
                group: SiteGroup.name,
                order: 4,
            },
            {
                icon: _jsx(Icon, { icon: "line-columns" }),
                label: "Gérer le footer du site",
                title: "Gérer le footer du site",
                onClick: handleClickAdminFooter,
                group: SiteGroup.name,
                order: 5,
            },
            {
                icon: _jsx(Icon, { icon: "layer-group" }),
                label: "Visualiser les composants",
                title: "Visualiser les composants",
                onClick: handleClickPreviewContents,
                group: ContentsGroup.name,
                order: 1,
            },
            {
                icon: _jsx(Icon, { icon: "file-pdf" }),
                label: "Générer un rapport du site",
                title: "Générer un rapport du site",
                onClick: handleClickGenerateSiteReport,
                group: DataGroup.name,
                order: 1,
                isLoading: isReportSiteLoading,
            },
        ];
        if (canUpdateSite) {
            sideBarItems.push({
                icon: _jsx(Icon, { icon: "cog" }),
                label: "Paramètres du site",
                title: "Paramètres du site",
                onClick: handleClickSiteSettings,
                group: SiteGroup.name,
                order: 2,
            });
        }
        if (canImportSite || canExportSite) {
            sideBarItems.push({
                icon: _jsx(Icon, { icon: "file-import" }),
                label: "Importer/Exporter un site",
                title: "Importer/Exporter un site",
                onClick: handleClickImportExport,
                group: DataGroup.name,
                order: 2,
            });
        }
        if (canDuplicateSite) {
            sideBarItems.push({
                icon: _jsx(Icon, { icon: "fa-regular fa-clone" }),
                label: "Dupliquer un site",
                title: "Dupliquer un site",
                onClick: handleClickDuplicateSite,
                group: DataGroup.name,
                order: 2,
            });
        }
        if (canCreatePage) {
            sideBarItems.push({
                icon: _jsx(Icon, { icon: "file-plus" }),
                label: "Créer une nouvelle page",
                title: "Créer une nouvelle page",
                onClick: handleClickAddPage,
                group: PageGroup.name,
                order: 1,
            });
            sideBarItems.push({
                icon: _jsx(Icon, { icon: "fa-regular fa-copy" }),
                label: "Dupliquer la page",
                title: "Dupliquer la page",
                onClick: handleClickDuplicatePage,
                group: PageGroup.name,
                order: 3,
            });
        }
        if (canUpdateVersion) {
            sideBarItems.push({
                icon: _jsx(Icon, { icon: "file-alt" }),
                label: "Paramètres de la page",
                title: "Paramètres de la page",
                onClick: handleClickAdminPage,
                group: PageGroup.name,
                order: 2,
            });
        }
        if (canAccessToGed) {
            sideBarItems.push({
                icon: _jsx(Icon, { icon: "photo-video" }),
                label: "Médiathèque",
                title: "Médiathèque",
                onClick: handleClickMedias,
                group: MediaGroup.name,
                order: 1,
            });
        }
        if (Array.isArray(allSites) && allSites.length > 1) {
            currentSite &&
                sideBarItems.push({
                    icon: _jsx(Icon, { icon: "globe-americas", id: "iconEarthChangeSite" }),
                    label: currentSite.name,
                    title: "Changer de site (".concat(currentSite.name, ")"),
                    onClick: handleClickChangeSite,
                    group: SiteGroup.name,
                    order: 1,
                });
        }
        addSideBarItems(sideBarItems);
        return function () {
            removeSideBarItems(sideBarItems);
        };
    }, [
        addSideBarItems,
        allSites,
        canAccessToGed,
        canCreatePage,
        canUpdateSite,
        canUpdateVersion,
        canImportSite,
        canExportSite,
        canDuplicateSite,
        currentSite,
        handleClickAddPage,
        handleClickDuplicatePage,
        handleClickAdminFooter,
        handleClickAdminMenu,
        handleClickAdminPage,
        handleClickChangeSite,
        handleClickImportExport,
        handleClickMedias,
        handleClickPages,
        handleClickPreviewContents,
        handleClickSelectSite,
        handleClickSiteSettings,
        handleClickGenerateSiteReport,
        handleClickDuplicateSite,
        removeSideBarItems,
        selectSiteAnchor,
        isReportSiteLoading,
    ]);
    if (!currentPageVersion) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Modal, { open: !!modal.content, size: modal.size, title: modal.title, onClose: closeModal, children: (_a = modal.content) !== null && _a !== void 0 ? _a : _jsx(_Fragment, {}) }), _jsx(Popover, { open: Boolean(selectSiteAnchor), anchorEl: (_d = (_c = (_b = document.getElementById("iconEarthChangeSite")) === null || _b === void 0 ? void 0 : _b.parentNode) === null || _c === void 0 ? void 0 : _c.parentElement) !== null && _d !== void 0 ? _d : null, anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                }, onClose: function () {
                    setSelectSiteAnchor(false);
                }, children: allSites === null || allSites === void 0 ? void 0 : allSites.map(function (site) { return (_jsx(Box, { onClick: handleClickSelectSite(site), sx: {
                        p: "8px",
                        cursor: "pointer",
                        bgcolor: (currentSite === null || currentSite === void 0 ? void 0 : currentSite.id) === site.id ? "secondary.main" : "",
                        color: (currentSite === null || currentSite === void 0 ? void 0 : currentSite.id) === site.id ? "primary.light" : "",
                        "&:hover": {
                            bgcolor: "secondary.main",
                        },
                    }, children: _jsx(FlagPicture, { lang: site.lang, title: site.name, hasWidthLabel: true, isBo: true }) }, site.id)); }) }), _jsx(AdminContentModal, { open: !!adminContent, content: adminContent, onValidate: handleValidateAdminContentModal, onClose: closeAdminContentModal, title: "Param\u00E8tres du site : ".concat(currentSite === null || currentSite === void 0 ? void 0 : currentSite.name) }), _jsx(AdminVersionsPrincipal, { pageVersion: currentPageVersion, setCurrentPageVersion: setCurrentPageVersion, setPageVersion: handleChangePageVersion, handleSaveVersion: handleSaveVersion, setModal: setModal, closeModal: closeModal, canUpdateVersion: canUpdateVersion, lockedTemplate: lockedTemplate, pageRef: pageRef, handleClickAdminPage: handleClickAdminPage, currentSiteTheme: currentSiteTheme })] }));
};
export default AdminWebsite;
