import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal, { ModalActions } from "administration/components/Modal";
import Icon from "shared/components/Icon";
import { enhanceContent } from "administration/utils/adminContentsUtils";
import { Fragment, useEffect, useState } from "react";
import FormGroup from "./FormGroup";
import useContentsGroupStore from "administration/store/ContentsGroupStore";
var AdminContentsGroups = function (props) {
    var content = props.content, onContentChange = props.onContentChange;
    var _a = useState(false), modalOpened = _a[0], setModalOpened = _a[1];
    var _b = useState(content), fullContent = _b[0], setFullContent = _b[1];
    var buttonLabel = useContentsGroupStore(function (state) { return ({ buttonLabel: state.buttonLabel }); }).buttonLabel;
    useEffect(function () {
        if (modalOpened) {
            setFullContent(enhanceContent(content));
        }
    }, [modalOpened, content]);
    var handleButtonClick = function () {
        setModalOpened(true);
    };
    var handleClose = function () {
        setModalOpened(false);
    };
    var handleClickValidate = function () {
        onContentChange(fullContent);
        setModalOpened(false);
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, { py: 3, children: _jsx(Button, { onClick: handleButtonClick, variant: "contained", startIcon: _jsx(Icon, { icon: "cog", title: buttonLabel }), children: buttonLabel }) }), _jsx(Modal, { open: modalOpened, onClose: handleClose, size: "sm", children: _jsxs(_Fragment, { children: ["(fullContent && ", _jsx(FormGroup, { content: fullContent, onContentChange: setFullContent }), ")", _jsxs(ModalActions, { children: [_jsx(Button, { onClick: handleClose, children: "Annuler" }), _jsx(Button, { onClick: handleClickValidate, color: "primary", children: "Valider" })] })] }) })] }));
};
export default AdminContentsGroups;
