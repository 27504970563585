import { jsx as _jsx } from "react/jsx-runtime";
import Icon from "shared/components/Icon";
import { useThemeBackOffice } from "administration/theme/ThemeBackOfficeProvider";
var ContentIcon = function (_a) {
    var content = _a.content;
    var icon = content.icon, IconComponent = content.IconComponent;
    var theme = useThemeBackOffice().themeBackOffice;
    if (IconComponent) {
        return (_jsx(IconComponent, { color: (theme === null || theme === void 0 ? void 0 : theme.palette).layout.contentBtnBackground, backgroundColor: (theme === null || theme === void 0 ? void 0 : theme.palette).layout.contentBtnColor }));
    }
    if (icon) {
        return _jsx(Icon, { icon: content.icon, type: "fas" });
    }
    return null;
};
export default ContentIcon;
