import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid } from "@mui/material";
import { PageVersionStatus } from "shared/types/cms/page/version/page-version.status.enum";
import formatDate from "shared/utils/formatDate";
var statusLabelStyled = {
    display: "inline-flex",
    columnGap: "5px",
    fontSize: "1.05rem",
    color: "secondary.text",
    fontWeight: "bold",
};
var pageName = {
    overflow: "hidden",
    maxWidth: 200,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
};
var DefaultMessage = function (props) {
    var title = props.title, version = props.version;
    var status = (version || {}).status;
    var getPageName = function () {
        var name = version.page ? version.page.name : title;
        return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: pageName, title: name, children: name }), _jsx(Box, { component: "span", sx: { color: "layout.border" }, children: "/" })] }));
    };
    var getBlockVersion = function (status, color, description) { return (_jsxs(Grid, { container: true, direction: "column", spacing: 1, children: [_jsxs(Grid, { item: true, sx: statusLabelStyled, children: [getPageName(), _jsx(Box, { component: "span", sx: { color: color }, children: status })] }), _jsxs(Grid, { item: true, sx: { fontSize: "0.79rem", color: "layout.draft" }, children: [description, " ", formatDate(version.updated)] })] })); };
    switch (status) {
        case PageVersionStatus.DRAFT:
            return getBlockVersion("Brouillon", "layout.draft", "Dernière mise à jour le");
        case PageVersionStatus.ARCHIVED:
            return getBlockVersion("Version archivée", "unpublished.textStatus", "Archivée le");
        case PageVersionStatus.PUBLISHED:
            return getBlockVersion("Version publiée", "success.main", "Publiée le");
        default:
            return null;
    }
};
export default DefaultMessage;
