var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { AdminActionsContext } from "administration/components/admin/AdminActions";
import { ModalActions } from "administration/components/Modal";
import ThemeBackOfficeProvider from "administration/theme/ThemeBackOfficeProvider";
import { enhanceContent, isValidContent } from "administration/utils/adminContentsUtils";
import { debounce } from "shared/utils/commonUtils";
import React, { useEffect, useState } from "react";
import AdminContentEditorContainer from "./AdminContentEditorContainer";
import ContentForm from "administration/components/contentForms/Form/ContentForm";
import useContentFormStore from "administration/store/ContentFormStore";
var AdminContentEditor = function (props) {
    var open = props.open, _a = props.drawer, drawer = _a === void 0 ? false : _a, title = props.title, content = props.content, onValidate = props.onValidate, onContentChange = props.onContentChange, onClose = props.onClose, others = __rest(props, ["open", "drawer", "title", "content", "onValidate", "onContentChange", "onClose"]);
    var _b = useState(), fullContent = _b[0], setFullContent = _b[1];
    var _c = useState(false), formSubmitted = _c[0], setFormSubmitted = _c[1];
    var id = (content || {}).id;
    var hideActions = React.useContext(AdminActionsContext).hideActions;
    var setDisplayDrawer = useContentFormStore(function (state) { return ({ setDisplayDrawer: state.setDisplayVertical }); }).setDisplayDrawer;
    // Init... -------------------------------------------------------------------
    useEffect(function () {
        setDisplayDrawer(drawer);
    }, [drawer]);
    useEffect(function () {
        if (open && !fullContent && content) {
            setFullContent(enhanceContent(content));
        }
        else if (!open) {
            setFullContent(undefined);
        }
    }, [open, content, fullContent]);
    useEffect(function () {
        if (open) {
            setFormSubmitted(false);
        }
    }, [open, content, fullContent]);
    // handleSubmit: used in creation mode. --------------------------------------
    var handleSubmit = function (e) {
        e.preventDefault();
        setFormSubmitted(true);
        var formIsValid = true;
        if (fullContent && fullContent.children) {
            if (!isValidContent(fullContent))
                formIsValid = false;
        }
        if (formIsValid && onValidate !== null)
            fullContent && onValidate && onValidate(fullContent);
    };
    // handleContentChange: used to previsualize update. -------------------------
    var handleContentChange = function (newContent) {
        setFullContent(newContent);
        if (onContentChange && newContent && isValidContent(newContent)) {
            debounce(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, onContentChange(newContent)];
            }); }); }, 350)();
        }
    };
    // Display Drawer or Modal formular. -----------------------------------------
    if (!fullContent) {
        return null;
    }
    return (_jsx(ThemeBackOfficeProvider, { children: _jsx(AdminContentEditorContainer, { open: open, hideActions: hideActions, onClose: onClose, drawer: drawer, title: title, others: others, children: _jsxs("form", { onSubmit: handleSubmit, children: [_jsx(ContentForm, { topLevel: true, content: fullContent, onContentChange: handleContentChange, formSubmitted: formSubmitted }), _jsx(ModalActions, { children: drawer ? (_jsx(IconButton, { onClick: onClose, sx: { position: "absolute", top: "8px", right: "2px", color: "secondary.text" }, children: _jsx(CloseIcon, { sx: { width: "30px", height: "30px" } }) })) : (_jsxs(_Fragment, { children: [_jsx(Button, { color: "secondary", onClick: onClose, children: "Annuler" }), _jsx(Button, { type: "submit", color: "primary", children: content && content.id ? "Mettre à jour" : "Créer" })] })) })] }) }, id) }));
};
export default AdminContentEditor;
