var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormFieldset } from "administration/components/contentForms/ContentFormStyled";
import FilePicker from "administration/components/FilePicker";
import useAdminPageVersionStore from "administration/store/AdminPageVersionStore";
import { useShallow } from "zustand/react/shallow";
var FileForm = function (props) {
    var content = props.content, onContentChange = props.onContentChange, _a = props.formSubmitted, formSubmitted = _a === void 0 ? false : _a, _b = props.required, required = _b === void 0 ? false : _b, _c = props.disableAccessibility, disableAccessibility = _c === void 0 ? false : _c, others = __rest(props, ["content", "onContentChange", "formSubmitted", "required", "disableAccessibility"]);
    var id = content.id, label = content.label, value = content.value, _d = content.children, children = _d === void 0 ? [] : _d;
    var imageFiltersChild = children.find(function (c) { return c.key === "filters"; });
    var currentPageVersion = useAdminPageVersionStore(useShallow(function (state) { return ({
        currentPageVersion: state.currentPageVersion,
    }); })).currentPageVersion;
    var handleSelectFile = function (file) {
        onContentChange(__assign(__assign({}, content), { value: JSON.stringify(file) }));
    };
    return (_jsxs(FormFieldset, { children: [_jsx("label", { children: label }), _jsx(FilePicker, __assign({ onSelectFile: handleSelectFile, pageVersion: currentPageVersion, file: value && JSON.parse(value), imageFiltersChild: imageFiltersChild, formSubmitted: formSubmitted, required: required, disableAccessibility: disableAccessibility }, others))] }, id));
};
export default FileForm;
