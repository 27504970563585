var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { createPages, useCreateSite } from "administration/services/AdminSitesService";
import { useForm } from "react-hook-form";
import ContentsTypes from "shared/enums/contentsTypes";
import Languages from "shared/enums/languages";
import useSnackbarStore from "shared/store/SnackbarStore";
import { v4 as uuidV4 } from "uuid";
var style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    boxShadow: 24,
    bgcolor: "layout.backgroundSidebar",
    p: 4
};
var siteSettingsContents = [
    {
        id: uuidV4(),
        key: "settings",
        type: ContentsTypes.SITE_SETTINGS,
        value: "",
        children: []
    }
];
var CreateSiteForm = function (_a) {
    var _b, _c;
    var refetchSites = _a.refetchSites;
    var _d = useForm({
        defaultValues: {
            name: "",
            host: "",
            lang: "fr",
            contents: siteSettingsContents
        },
        mode: "onSubmit"
    }), register = _d.register, handleSubmit = _d.handleSubmit, errors = _d.formState.errors;
    var displayError = useSnackbarStore(function (state) { return ({ displayError: state.displayError }); }).displayError;
    var createSite = useCreateSite({
        onSuccess: function (response) {
            createPages(response.data).then(function (pageResponse) {
                if (pageResponse.data) {
                    refetchSites();
                }
            });
        },
        onError: function (er) {
            displayError("Erreur lors de la creation du site");
        },
        onMutate: function () {
            console.log("pending");
        }
    }).mutate;
    var onSubmit = function (data) {
        createSite({ name: data.name, host: data.host, lang: data.lang, contents: siteSettingsContents });
    };
    return (_jsx(Box, { sx: style, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsxs(Grid, { container: true, spacing: 2, justifyContent: "center", alignItems: "center", children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h2", align: "center", children: "Cr\u00E9er un site" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, __assign({ fullWidth: true, label: "Nom", placeholder: "Nom du site" }, register("name", {
                            required: {
                                message: "Nom du site requis",
                                value: true
                            },
                            pattern: {
                                message: "ne peut contenir de caractères spéciaux",
                                value: /^[a-zA-Z ]+$/gi
                            }
                        }), { error: !!errors.name, helperText: (_b = errors.name) === null || _b === void 0 ? void 0 : _b.message })) }), _jsx(Grid, { item: true, xs: 8, children: _jsx(TextField, __assign({ label: "Host", placeholder: "Host du site" }, register("host", {
                            required: {
                                message: "Host requis",
                                value: true
                            }
                        }), { error: !!errors.host, helperText: (_c = errors.host) === null || _c === void 0 ? void 0 : _c.message })) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(Select, __assign({ sx: { mt: "5px!important" }, label: "Language", placeholder: "Language", id: "simple-select", fullWidth: true, defaultValue: "fr" }, register("lang", {
                            required: {
                                message: "Langue requise",
                                value: true
                            }
                        }), { error: !!errors.host, children: Object.keys(Languages).map(function (lang, index) { return (_jsx(MenuItem, { value: Languages[lang], children: lang }, index)); }) })) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Button, { type: "submit", variant: "contained", fullWidth: true, children: "Envoyer" }) })] }) }) }));
};
export default CreateSiteForm;
