var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { createRef, Fragment, useEffect, useState } from "react";
var sizerStyled = function (showInput) { return ({
    display: showInput ? "none" : "inline-block",
    width: "100%",
    border: "1px dashed #999",
    minHeight: "1em",
    cursor: "text",
}); };
var AutoResizeInput = function (props) {
    var _a = props.value, value = _a === void 0 ? "" : _a, _b = props.onBlur, onBlur = _b === void 0 ? null : _b, onChange = props.onChange, _c = props.debounce, debounce = _c === void 0 ? 2000 : _c, others = __rest(props, ["value", "onBlur", "onChange", "debounce"]);
    var _d = useState(false), showInput = _d[0], setShowInput = _d[1];
    var _e = useState(null), currentTimeOut = _e[0], setCurrentTimeOut = _e[1];
    var _f = useState(value), localValue = _f[0], setLocalValue = _f[1];
    useEffect(function () {
        setLocalValue(value);
    }, [value]);
    var handleChange = function (e) {
        var inputValue = e.target.value;
        setLocalValue(inputValue);
        if (currentTimeOut) {
            clearTimeout(currentTimeOut);
        }
        setCurrentTimeOut(setTimeout(function () {
            onChange(inputValue);
        }, debounce));
    };
    var inputRef = createRef();
    useEffect(function () {
        if (inputRef.current && showInput) {
            inputRef.current.focus();
        }
    }, [showInput, inputRef]);
    var handleSizerClick = function () {
        setShowInput(true);
    };
    var handleInputBlur = function (e) {
        setShowInput(false);
        if (typeof onBlur === "function") {
            onBlur(e);
        }
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, { component: "span", onClick: handleSizerClick, sx: sizerStyled(showInput), children: localValue }), _jsx(Box, __assign({ component: "textarea" }, others, { onChange: handleChange, sx: { display: showInput ? "inline-block" : "none", color: "inherit" }, value: localValue, ref: inputRef, onBlur: handleInputBlur }))] }));
};
export default AutoResizeInput;
