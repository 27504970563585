var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HelpIcon from "@mui/icons-material/Help";
import { FormControl, FormControlLabel, InputLabel, Radio, RadioGroup, Tooltip } from "@mui/material";
var RadioGroupForm = function (props) {
    var content = props.content, classes = props.classes, onContentChange = props.onContentChange, _a = props.tooltip, tooltip = _a === void 0 ? null : _a;
    var id = content.id, label = content.label, value = content.value, options = content.options;
    var handleRadioChange = function (e) {
        onContentChange(__assign(__assign({}, content), { value: e.target.value }));
    };
    return (_jsxs(FormControl, { className: classes === null || classes === void 0 ? void 0 : classes.formControl, children: [_jsxs(InputLabel, { sx: { pointerEvents: "auto" }, shrink: true, className: tooltip ? classes.labelWithTooltip : null, children: [label, tooltip && (_jsx(Tooltip, { title: tooltip, children: _jsx(HelpIcon, {}) }))] }), _jsx(RadioGroup, { row: true, sx: { mt: 2.5 }, value: value, onChange: handleRadioChange, children: options === null || options === void 0 ? void 0 : options.map(function (option) { return (_jsx(FormControlLabel, { label: option.label, value: option.value, title: option.title, control: _jsx(Radio, {}) }, option.value)); }) })] }, id));
};
export default RadioGroupForm;
