import contentsTypes from "shared/enums/contentsTypes";
import ContentsCustomTypes from "enums/contentsCustomTypes.enum";
export var ContactForm = {
    label: "Formulaire de contact",
    type: ContentsCustomTypes.CONTACT_FORM,
    icon: "envelope-open-text",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Mail du destinataire",
            key: "recipient",
            type: contentsTypes.INPUT,
            tooltip: "Adresse e-mail de destination du formulaire de contact",
            value: "",
        },
    ],
};
