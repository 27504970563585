import React from "react";
import SubpageForm from "administration/components/contentForms/SubpageForm";
import ContentsTypes from "shared/enums/contentsTypes";

const SubpageDynamicForm = (props) => {
  const { content, onContentChange } = props;
  const { children } = content;

  const subPageChild = children.find((c) => c.type === ContentsTypes.PAGE);

  const { id } = content;

  const handleSubPageChange = (newSubpageChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === subPageChild) {
          return newSubpageChild;
        }
        return child;
      }),
    });
  };

  return (
    <div key={id}>
      <SubpageForm content={subPageChild} onContentChange={handleSubPageChange} />
    </div>
  );
};

export default SubpageDynamicForm;
