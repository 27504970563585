import ContentsTypes from "shared/enums/contentsTypes";
import ContentsCustomTypes from "enums/contentsCustomTypes.enum";
export var ArticleSection = {
    label: "Titre avec ancre",
    type: ContentsCustomTypes.ARTICLE_SECTION,
    icon: "anchor",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Titre",
            key: "title",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Ancre",
            key: "anchor",
            type: ContentsTypes.INPUT,
            value: "",
        },
    ],
};
