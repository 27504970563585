import TrombinoscopePreview from "../components/Trombinoscope/TrombinoscopePreview";
import ContentsTypes from "shared/enums/contentsTypes";
import ContentsCustomTypes from "enums/contentsCustomTypes.enum";
export var Trombinoscope = {
    label: "Trombinoscope",
    type: ContentsCustomTypes.TROMBINOSCOPE,
    Preview: TrombinoscopePreview,
    icon: "user",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Première image",
            key: "image",
            type: ContentsTypes.IMAGE,
            value: "",
        },
        {
            label: "Deuxième image",
            key: "imageHover",
            type: ContentsTypes.IMAGE,
            value: "",
        },
        {
            label: "Nom",
            key: "name",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Surnom",
            key: "nickname",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Poste",
            key: "position",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Lien LinkedIn",
            key: "link",
            type: ContentsTypes.INPUT,
            value: "",
        },
    ],
};
