var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var swapArrayItems = function (array, index1, index2) {
    var newArray = __spreadArray([], array, true);
    if (index1 !== -1 && index2 !== -1) {
        var tmpItem = newArray[index1];
        newArray[index1] = newArray[index2];
        newArray[index2] = tmpItem;
    }
    return newArray;
};
