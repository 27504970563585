import ActionPositions from "shared/enums/actionPositions";
var FlexDirection;
(function (FlexDirection) {
    FlexDirection["COLUMN"] = "column";
    FlexDirection["ROW"] = "row";
})(FlexDirection || (FlexDirection = {}));
var JustifyContent;
(function (JustifyContent) {
    JustifyContent["CENTER"] = "center";
    JustifyContent["FLEX_START"] = "flex-start";
    JustifyContent["FLEX_END"] = "flex-end";
})(JustifyContent || (JustifyContent = {}));
var StylesPosition = /** @class */ (function () {
    function StylesPosition() {
    }
    StylesPosition.checkActionPosition = function (position) {
        switch (position) {
            case ActionPositions.TOP_RIGHT:
                return {
                    top: -12,
                    right: -12,
                };
            case ActionPositions.RIGHT:
                return {
                    top: 0,
                    bottom: 0,
                    right: -12,
                    flexDirection: FlexDirection.COLUMN,
                    justifyContent: JustifyContent.CENTER,
                };
            case ActionPositions.BOTTOM_RIGHT:
                return {
                    bottom: -12,
                    right: -12,
                };
            case ActionPositions.BOTTOM:
                return {
                    bottom: -12,
                    right: 0,
                    left: 0,
                    justifyContent: JustifyContent.CENTER,
                };
            case ActionPositions.BOTTOM_LEFT:
                return {
                    bottom: -12,
                    left: -12,
                };
            case ActionPositions.LEFT:
                return {
                    top: 0,
                    bottom: 0,
                    left: -12,
                    flexDirection: FlexDirection.COLUMN,
                    justifyContent: JustifyContent.CENTER,
                };
            case ActionPositions.TOP_LEFT:
                return {
                    top: -12,
                    left: -12,
                };
            case ActionPositions.TOP:
                return {
                    top: -12,
                    right: 0,
                    left: 0,
                    justifyContent: JustifyContent.CENTER,
                };
            default:
                return {};
        }
    };
    StylesPosition.value = function (position) {
        return StylesPosition.checkActionPosition(position);
    };
    return StylesPosition;
}());
export { StylesPosition };
