var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
var CheckboxForm = function (props) {
    var content = props.content, onContentChange = props.onContentChange;
    var value = content.value;
    var handleCheckboxChange = function (e) {
        onContentChange(__assign(__assign({}, content), { value: e.target.checked ? "true" : "false" }));
    };
    return (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: value === "true", onChange: handleCheckboxChange, disableRipple: true }), label: content.label }));
};
export default CheckboxForm;
