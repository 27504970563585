var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress, ClickAwayListener, Divider, Switch, Tooltip } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import Modal from "administration/components/Modal";
import Icon from "shared/components/Icon";
import { getCurrentThemeType } from "administration/theme/themeBackOffice";
import { useThemeBackOffice } from "administration/theme/ThemeBackOfficeProvider";
import themeTypes from "administration/theme/themeTypes";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCurrentPage, useHomePageLocation } from "administration/hooks/AdminPageHook";
import { HOOKS } from "shared/utils/register/definitions";
import CmsHooks from "shared/utils/register/CmsHooks";
export var NavigationGroup = {
    name: "navigation",
    order: 1,
};
export var defaultGroups = [NavigationGroup];
export var SideBarContext = React.createContext({
    groups: [],
    items: [],
    isExpanded: false,
    setExpanded: function (expanded) { },
    addGroups: function (groups) { },
    removeGroups: function (groups) { },
    addItems: function (items) { },
    removeItems: function (items) { },
});
var initialState = { groups: [], items: [] };
var actionType = {
    addGroups: "addGroups",
    removeGroups: "removeGroups",
    addItems: "addItems",
    removeItems: "removeItems",
};
var reducer = function (state, action) {
    switch (action.type) {
        case actionType.addGroups:
            return __assign(__assign({}, state), { groups: state.groups.concat(action.groups) });
        case actionType.removeGroups:
            return __assign(__assign({}, state), { groups: state.groups.filter(function (group) { return !action.groups.includes(group); }) });
        case actionType.addItems:
            return __assign(__assign({}, state), { items: state.items.concat(action.items) });
        case actionType.removeItems:
            return __assign(__assign({}, state), { items: state.items.filter(function (item) { return !action.items.includes(item); }) });
        default:
            return state;
    }
};
var defaultModal = {
    content: null,
    size: null,
    title: undefined,
};
export var SideBarContextProvider = function (props) {
    var children = props.children;
    var historyPush = useHistory().push;
    var pathname = useLocation().pathname;
    var adminWebsitePath = CmsHooks.getHook(HOOKS.Bo_AdminWebsitePath);
    var keycloak = useKeycloak().keycloak;
    var resourceAccess = keycloak.resourceAccess;
    var isAdmin = React.useMemo(function () { var _a, _b; return (_b = (_a = resourceAccess === null || resourceAccess === void 0 ? void 0 : resourceAccess["realm-management"]) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b.includes("manage-users"); }, [resourceAccess]);
    var homePage = useHomePageLocation().homePage;
    var setCurrentPage = useCurrentPage().setCurrentPage;
    var _a = React.useReducer(reducer, initialState), state = _a[0], dispatch = _a[1];
    var _b = React.useState(defaultModal), modal = _b[0], setModal = _b[1];
    var closeModal = React.useCallback(function () {
        setModal(defaultModal);
    }, []);
    var addGroups = React.useCallback(function (groups) {
        dispatch({ type: actionType.addGroups, groups: groups });
    }, []);
    var removeGroups = React.useCallback(function (groups) {
        dispatch({ type: actionType.removeGroups, groups: groups });
    }, []);
    var addItems = React.useCallback(function (items) {
        dispatch({ type: actionType.addItems, items: items });
    }, []);
    var removeItems = React.useCallback(function (items) {
        dispatch({ type: actionType.removeItems, items: items });
    }, []);
    var _c = React.useState(false), isExpanded = _c[0], setExpanded = _c[1];
    var setThemeType = useThemeBackOffice().setThemeType;
    var currentThemeType = getCurrentThemeType();
    var isLightTheme = React.useMemo(function () { return currentThemeType === themeTypes.LIGHT_THEME; }, [currentThemeType]);
    var handleRedirectManagement = React.useCallback(function () {
        keycloak.accountManagement();
    }, [keycloak]);
    var handleChangeTheme = React.useCallback(function () {
        setThemeType(isLightTheme ? themeTypes.DARK_THEME : themeTypes.LIGHT_THEME);
    }, [isLightTheme, setThemeType]);
    var handleLogout = React.useCallback(function () {
        keycloak.logout();
    }, [keycloak]);
    var handleClickManageUsers = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            historyPush("/users");
            return [2 /*return*/];
        });
    }); }, [historyPush]);
    var defaultItems = React.useMemo(function () {
        var items = [
            {
                icon: _jsx(Icon, { icon: "home" }),
                label: "Accueil",
                title: "Accueil",
                onClick: function () {
                    if (pathname !== adminWebsitePath) {
                        historyPush(adminWebsitePath);
                    }
                    setCurrentPage(homePage.page);
                },
                group: NavigationGroup.name,
                order: 1,
            },
            {
                icon: _jsx(Icon, { icon: "user-circle" }),
                label: "Mon profil",
                title: "Mon profil",
                onClick: handleRedirectManagement,
                order: 1,
            },
            {
                icon: _jsx(Icon, { icon: "adjust" }),
                label: (_jsxs(_Fragment, { children: ["Mode sombre/clair", _jsx(Switch, { checked: isLightTheme, onChange: handleChangeTheme, color: "default", name: "isLightTheme" })] })),
                title: "Mode sombre/clair",
                onClick: handleChangeTheme,
                order: 2,
            },
            {
                icon: _jsx(Icon, { icon: "sign-out" }),
                label: "Déconnexion",
                title: "Déconnexion",
                onClick: handleLogout,
                order: 3,
            },
        ];
        if (isAdmin) {
            items.push({
                icon: _jsx(Icon, { icon: "users" }),
                label: " Gestion des utilisateurs",
                title: "Gestion des utilisateurs",
                onClick: handleClickManageUsers,
                order: 1.5,
            });
        }
        return items;
    }, [
        handleChangeTheme,
        handleClickManageUsers,
        handleLogout,
        handleRedirectManagement,
        setCurrentPage,
        historyPush,
        adminWebsitePath,
        pathname,
        homePage,
        isAdmin,
        isLightTheme,
    ]);
    return (_jsxs(SideBarContext.Provider, { value: {
            groups: defaultGroups.concat(state.groups),
            items: defaultItems.concat(state.items),
            isExpanded: isExpanded,
            setExpanded: setExpanded,
            addGroups: addGroups,
            removeGroups: removeGroups,
            addItems: addItems,
            removeItems: removeItems,
        }, children: [_jsx(Modal, { open: !!modal.content, size: modal.size, title: modal.title, onClose: closeModal, children: modal.content ? modal.content : _jsx(_Fragment, {}) }), children] }));
};
var sortFunction = function (a, b) { return (a.order || Infinity) - (b.order || Infinity); };
var SideBar = function () {
    var _a = React.useContext(SideBarContext), groups = _a.groups, items = _a.items, isExpanded = _a.isExpanded, setExpanded = _a.setExpanded;
    var pathname = useLocation().pathname;
    var sortedGroups = React.useMemo(function () { return __spreadArray(__spreadArray([], groups, true), [undefined], false).sort(sortFunction); }, [groups]);
    var sortedItems = React.useMemo(function () { return __spreadArray([], items, true).sort(sortFunction); }, [items]);
    var getGroupItems = function (g) { return sortedItems.filter(function (item) { return (!g && !item.group) || item.group === (g === null || g === void 0 ? void 0 : g.name); }); };
    var handleClickMenu = React.useCallback(function () {
        setExpanded(!isExpanded);
    }, [isExpanded, setExpanded]);
    var handleClickAway = React.useCallback(function () {
        setExpanded(false);
    }, [setExpanded]);
    var groupItem = function (item) {
        var icon = item.icon, label = item.label, onClick = item.onClick, title = item.title, isLoading = item.isLoading;
        return (_jsxs(Box, { onClick: isLoading ? function () { return undefined; } : onClick, sx: {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                cursor: isLoading ? undefined : "pointer",
                height: "45px",
                width: isExpanded ? 300 : 50,
                opacity: isLoading ? 0.6 : 1,
                "&:hover": {
                    bgcolor: "secondary.main",
                },
            }, title: isExpanded ? title : null, children: [_jsx(Box, { sx: __assign({ fontSize: "1.3rem", width: 50, textAlign: "center" }, (isLoading
                        ? {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }
                        : {})), children: isLoading ? _jsx(CircularProgress, { size: 24, sx: { color: "secondary.text" } }) : icon }), isExpanded && _jsx(Box, { sx: { ml: "10px", flexGrow: 1 }, children: label })] }, title));
    };
    if (pathname === "/noLayout") {
        return null;
    }
    return (_jsx(ClickAwayListener, { onClickAway: handleClickAway, children: _jsx(Box, { sx: { width: 50, position: "relative", zIndex: 500 }, children: _jsxs(Box, { sx: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    width: isExpanded ? 300 : 50,
                    overflow: "hidden",
                    bgcolor: "layout.backgroundSidebar",
                    borderRight: "1px solid",
                    borderColor: "layout.border",
                    transition: "width linear 350ms, background-color 1000ms ease, color 1000ms ease, border-color 1000ms ease",
                    color: "layout.textSidebar",
                    fontWeight: 600,
                }, children: [_jsx(Icon, { title: "Menu", icon: isExpanded ? "times" : "bars", style: { fontSize: "1.75rem", cursor: "pointer", padding: "26px 12px" }, onClick: handleClickMenu }), sortedGroups.map(function (group, index) {
                        var groupItems = getGroupItems(group);
                        if (!groupItems.length) {
                            return null;
                        }
                        return (_jsxs(React.Fragment, { children: [index > 0 && _jsx(Divider, {}), groupItems.map(function (item) {
                                    return isExpanded ? (groupItem(item)) : (_jsx(Tooltip, { title: item === null || item === void 0 ? void 0 : item.title, placement: "right", arrow: true, children: groupItem(item) }, "key" + (item === null || item === void 0 ? void 0 : item.title)));
                                })] }, (group === null || group === void 0 ? void 0 : group.name) || "default"));
                    })] }) }) }));
};
export default SideBar;
