import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Modal } from "@mui/material";
import LoadingPage from "administration/components/Layout/LoadingPage";
import { useSites } from "shared/hooks/SiteHook";
import { useEffect } from "react";
import CreateSiteForm from "administration/components/contentForms/CreateSiteForm";
import { useSetCurrentSite } from "administration/hooks/AdminSiteHook";
import useAdminEditorStore from "administration/store/AdminEditorStore";
export var AdminSiteProvider = function (props) {
    var children = props.children;
    var _a = useSites(), _b = _a.allSites, sites = _b === void 0 ? [] : _b, currentSite = _a.currentSite, refreshSite = _a.refreshSite, status = _a.status;
    var setCurrentSite = useSetCurrentSite().setCurrentSite;
    var setCurrentSiteId = useAdminEditorStore(function (state) { return ({ setCurrentSiteId: state.setCurrentSiteId }); }).setCurrentSiteId;
    useEffect(function () {
        if (currentSite === null || currentSite === void 0 ? void 0 : currentSite.id) {
            setCurrentSiteId(currentSite.id);
        }
    }, [currentSite, setCurrentSite, setCurrentSiteId]);
    if (status === "success" && !sites.length) {
        return (_jsx(Modal, { open: true, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", children: _jsx(CreateSiteForm, { refetchSites: refreshSite }) }));
    }
    if (!currentSite) {
        return _jsx(LoadingPage, { title: "Chargement des sites" });
    }
    return _jsx(_Fragment, { children: children });
};
