var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ContentsTypes from "shared/enums/contentsTypes";
import AdminListForm from "../AdminListForm";
import { Grid } from "@mui/material";
import InputForm from "../InputForm";
import IconForm from "../IconForm";
export var SiteSettingsSocialNetworkForm = function (_a) {
    var handleChildChange = _a.handleChildChange, handleChildrenChange = _a.handleChildrenChange, children = _a.children;
    var socialNetworkListChild = children.filter(function (c) { return c.key === "socialNetworks"; });
    return (_jsx(AdminListForm, { inline: true, contents: socialNetworkListChild, elementsType: ContentsTypes.SOCIAL_NETWORK, elementsKey: "socialNetworks", onContentsChange: function (newContents) { return handleChildrenChange(socialNetworkListChild, newContents); }, title: "R\u00E9seau social", children: _jsx(_Fragment, { children: socialNetworkListChild.map(function (socialNetwork) {
                return socialNetwork.children && (_jsxs("div", { children: [_jsxs(Grid, { container: true, spacing: 3, children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(InputForm, { content: socialNetwork.children.find(function (c) { return c.key === "url"; }), onContentChange: function (newContent) {
                                            return handleChildChange(socialNetwork, __assign(__assign({}, socialNetwork), { children: (socialNetwork.children || []).map(function (c) { return (c.key === "url" ? newContent : c); }) }));
                                        } }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(InputForm, { content: socialNetwork.children.find(function (c) { return c.key === "title"; }), onContentChange: function (newContent) {
                                            return handleChildChange(socialNetwork, __assign(__assign({}, socialNetwork), { children: (socialNetwork.children || []).map(function (c) { return (c.key === "title" ? newContent : c); }) }));
                                        } }) })] }), _jsx(IconForm, { content: socialNetwork.children.find(function (c) { return c.key === "icon"; }), onContentChange: function (newContent) {
                                return handleChildChange(socialNetwork, __assign(__assign({}, socialNetwork), { children: (socialNetwork.children || []).map(function (c) { return (c.key === "icon" ? newContent : c); }) }));
                            } })] }, socialNetwork.id));
            }) }) }));
};
