var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HelpIcon from "@mui/icons-material/Help";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import { FormLabelTooltip } from "administration/components/contentForms/ContentFormStyled";
import { isString } from "shared/utils/guards";
var InputForm = function (props) {
    var _a, _b;
    var content = props.content, onContentChange = props.onContentChange, formSubmitted = props.formSubmitted, required = props.required, regexValidation = props.regexValidation, tooltip = props.tooltip, _c = props.disabled, disabled = _c === void 0 ? false : _c, others = __rest(props, ["content", "onContentChange", "formSubmitted", "required", "regexValidation", "tooltip", "disabled"]);
    var id = content.id, label = content.label, value = content.value;
    var min = (_a = content === null || content === void 0 ? void 0 : content.options) === null || _a === void 0 ? void 0 : _a.filter(function (opt) {
        return opt.label === "min";
    })[0].value;
    var max = (_b = content === null || content === void 0 ? void 0 : content.options) === null || _b === void 0 ? void 0 : _b.filter(function (opt) {
        return opt.label === "max";
    })[0].value;
    var handleInputChange = function (e) {
        onContentChange(__assign(__assign({}, content), { value: e.target.value }));
    };
    var showRequiredError = required && formSubmitted && !value;
    var showRegexValidationError = regexValidation && formSubmitted && isString(value) && !value.match(regexValidation);
    return (_jsxs(FormControl, { error: showRequiredError || !!showRegexValidationError, children: [_jsxs(InputLabel, { sx: { pointerEvents: "auto" }, children: [label, tooltip && (_jsx(FormLabelTooltip, { title: tooltip, children: _jsx(HelpIcon, {}) }))] }), _jsx(Input, __assign({ value: value, onChange: handleInputChange, inputProps: { min: min ? +min : 0, max: max ? +max : 10 }, disabled: disabled }, others)), showRequiredError && _jsx(FormHelperText, { error: true, children: "Champ obligatoire" }), showRegexValidationError && _jsxs(FormHelperText, { error: true, children: [label, " invalide"] })] }, id));
};
export default InputForm;
