var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from "@mui/material";
import ImageForm from "../ImageForm";
import FileForm from "../FileForm";
import HelpIcon from "@mui/icons-material/Help";
var createLabelFaviconWithTooltip = function () {
    return (_jsxs(_Fragment, { children: ["Favicon", _jsx(Tooltip, { sx: { pt: 0.5 }, title: "Le favicon est la petite image affich\u00E9e \u00E0 c\u00F4t\u00E9 du titre de la page dans l'onglet du navigateur. Les changements seront visible seulement sur le site vitrine.", children: _jsx(HelpIcon, { fontSize: "small" }) })] }));
};
export var SiteSettingsLogosForm = function (_a) {
    var handleChildChange = _a.handleChildChange, findContent = _a.findContent;
    var logoHeaderChild = findContent("logoHeader");
    var logoFooterChild = findContent("logoFooter");
    var logoFaviconChild = findContent("logoFavicon");
    return (_jsxs(_Fragment, { children: [_jsx(ImageForm, { content: __assign(__assign({}, logoHeaderChild), { label: "Logo en haut de page" }), onContentChange: function (newContent) { return handleChildChange(logoHeaderChild, newContent); } }), _jsx(ImageForm, { content: __assign(__assign({}, logoFooterChild), { label: "Logo en bas de page" }), onContentChange: function (newContent) { return handleChildChange(logoFooterChild, newContent); } }), _jsx(FileForm, { content: __assign(__assign({}, logoFaviconChild), { label: createLabelFaviconWithTooltip() }), onContentChange: function (newContent) { return handleChildChange(logoFaviconChild, newContent); }, image: true, disableAccessibility: true })] }));
};
