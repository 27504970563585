var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, ButtonGroup, IconButton } from "@mui/material";
import Icon from "shared/components/Icon";
import ContentsTypes from "shared/enums/contentsTypes";
import { useState } from "react";
import AdminMenuForm from "./AdminMenuForm";
var MenuItem = function (props) {
    var canUpdate = props.canUpdate, menuItem = props.menuItem, refButtonDrag = props.refButtonDrag, onDeleteItem = props.onDeleteItem, onEditItem = props.onEditItem, saveItem = props.saveItem, subItemKey = props.subItemKey, maxDepth = props.maxDepth, currentDepth = props.currentDepth;
    var children = menuItem.children;
    var _a = useState(false), groupOpen = _a[0], setGroupOpen = _a[1];
    var titleChild = children.find(function (c) { return c.key === "title"; });
    var isGroup = menuItem.type === ContentsTypes.MENU_GROUP;
    var isOpen = groupOpen;
    var subMenusChild = children.filter(function (c) { return c.key === "subMenus"; });
    var handleItemChildrenChange = function (newChildren) {
        var child = children.filter(function (c) { return c.key !== "subMenus"; });
        saveItem(__assign(__assign({}, menuItem), { children: __spreadArray(__spreadArray([], child, true), newChildren.contents, true) }));
    };
    var handleDelete = function () {
        onDeleteItem(menuItem.id);
    };
    var handleEdit = function () {
        onEditItem(menuItem);
    };
    var openGroup = function () {
        setGroupOpen(true);
    };
    var closeGroup = function () {
        setGroupOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: {
                    px: "0.875rem",
                    py: "0.44rem",
                    my: "0.875rem",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    bgcolor: "secondary.main",
                }, children: [canUpdate && (_jsx(Box, { component: "span", ref: refButtonDrag, sx: { cursor: "grab", pt: 0.5 }, children: _jsx(DragIndicatorIcon, {}) })), _jsx(Box, { component: "span", px: 1, children: titleChild === null || titleChild === void 0 ? void 0 : titleChild.value }), _jsxs(ButtonGroup, { variant: "text", sx: { ml: "auto", mr: 1.875 }, children: [isGroup &&
                                (groupOpen ? (_jsx(IconButton, { sx: { maxHeight: "20px" }, size: "small", onClick: closeGroup, children: _jsx(Icon, { icon: "minus", type: "fas" }) })) : (_jsx(IconButton, { sx: { maxHeight: "20px" }, size: "small", onClick: openGroup, children: _jsx(Icon, { icon: "plus", type: "fas" }) }))), _jsx(IconButton, { sx: { maxHeight: "20px" }, size: "small", onClick: handleEdit, children: _jsx(Icon, { icon: "edit", type: "fas" }) }), _jsx(IconButton, { sx: { maxHeight: "20px" }, size: "small", edge: "end", "aria-label": "supprimer", onClick: handleDelete, children: _jsx(Icon, { icon: "trash", title: "Supprimer" }) })] })] }), isGroup && isOpen && (_jsx(Box, { ml: 6, border: 1, p: 1, children: _jsx(AdminMenuForm, { onMenuChange: handleItemChildrenChange, menu: { contents: subMenusChild }, canUpdate: canUpdate, itemKey: subItemKey, maxDepth: maxDepth, currentDepth: currentDepth + 1, subItemKey: subItemKey + currentDepth }) }))] }));
};
export default MenuItem;
