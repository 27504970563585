import React from "react";
import Image from "shared/components/contents/image/Image";
import BlockWithTitle from "./BlockWithTitle";

const BlockWithTitlePreview = () => {
  return (
    <BlockWithTitle
      image={<Image file={{ url: "/exemple-image.jpg" }} disableLazy />}
      title="Titre"
      justify="flex-start"
      backgroundColor="#F5F5F5"
    />
  );
};

export default BlockWithTitlePreview;
