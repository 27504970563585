var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useCallback, useMemo, memo, useRef } from "react";
import Box from "@mui/material/Box";
import AdminTemplateSettings from "administration/components/AdminTemplateSettings/AdminTemplateSettings";
import ContentsTypes from "shared/enums/contentsTypes";
import { generateTemplatePropsFromAdminContents } from "administration/utils/templates/generateTemplatePropsFromAdminContents";
import generateTemplatePropsFromContents from "frontoffice/utils/templates/generateTemplatePropsFromContents";
import generateEditablePage from "administration/utils/templates/generateEditablePage";
import { HOOKS } from "shared/utils/register/definitions";
import { isObjectEmpty } from "shared/utils/guards";
import CmsHooks from "shared/utils/register/CmsHooks";
import { enhancePageVersionContents, getTemplateComponentByKey } from "shared/utils/templateUtils";
import Page from "frontoffice/components/Page";
import useAdminTemplateSettingsStore from "administration/store/AdminTemplateSettingsStore";
import { useSites } from "shared/hooks/SiteHook";
import useGlobalStore from "shared/store/GlobalStore";
var ckeBordersStyled = {
    "& .cke_editable, & input,  & textarea, & pre": {
        bgcolor: "transparent",
        fontSize: "inherit",
        fontWeight: "inherit",
        fontFamily: "inherit",
        lineHeight: "inherit",
        textAlign: "inherit",
        letterSpacing: "inherit",
        p: 0,
        width: "100%",
    },
    "& .cke_editable, & input,  & textarea": {
        border: "1px dashed #999",
    },
    "& pre": {
        whiteSpace: "pre-wrap",
    },
};
var RenderPage = function (props) {
    var Layout = CmsHooks.getHook(HOOKS.App_Layout) || (function (_a) {
        var children = _a.children;
        return children;
    });
    var pageProps = props.page, handlePageChange = props.handlePageChange, handleTemplateSettingsChange = props.handleTemplateSettingsChange, editionMode = props.editionMode;
    var page = React.useMemo(function () { return enhancePageVersionContents(pageProps); }, [pageProps]);
    var _a = useAdminTemplateSettingsStore(function (state) { return ({
        displayAdminTemplateSettingsModal: state.displayAdminTemplateSettingsModal,
        setDisplayAdminTemplateSettingsModal: state.setDisplayAdminTemplateSettingsModal,
    }); }), displayAdminTemplateSettingsModal = _a.displayAdminTemplateSettingsModal, setDisplayAdminTemplateSettingsModal = _a.setDisplayAdminTemplateSettingsModal;
    var setIsBo = useGlobalStore(function (state) { return ({ setIsBo: state.setIsBo }); }).setIsBo;
    var currentSite = useSites().currentSite;
    //const { currentPageVersion, setCurrentPageVersion } = useAdminPageVersionContext();
    var contents = useMemo(function () { var _a; return (_a = currentSite === null || currentSite === void 0 ? void 0 : currentSite.contents) !== null && _a !== void 0 ? _a : []; }, [currentSite]);
    var getTemplatesSettings = useCallback(function () {
        return (contents && contents.find(function (c) { return c.type === ContentsTypes.TEMPLATES_SETTINGS; })) || {};
    }, [contents]);
    var template = (page || {}).template;
    var getTemplateSettings = useCallback(function () {
        var _a;
        var templatesSettings = getTemplatesSettings();
        var currentTemplateSettings = !isObjectEmpty(templatesSettings)
            ? (_a = templatesSettings.children) === null || _a === void 0 ? void 0 : _a.find(function (c) { return c.key === template; })
            : null;
        if (currentTemplateSettings === null || currentTemplateSettings === void 0 ? void 0 : currentTemplateSettings.children) {
            var currentTemplateSettingsChildren_1 = [];
            currentTemplateSettings.children.forEach(function (c) {
                currentTemplateSettingsChildren_1.push(__assign(__assign({}, c), { isTemplateSettings: true }));
            });
            return __assign(__assign({}, currentTemplateSettings), { children: currentTemplateSettingsChildren_1 });
        }
        return currentTemplateSettings !== null && currentTemplateSettings !== void 0 ? currentTemplateSettings : {};
    }, [template, getTemplatesSettings]);
    var _b = useState(false), templateIsModify = _b[0], setTemplateIsModify = _b[1];
    var previousTemplateSettingsRef = useRef(null);
    //const [previousTemplateSettings, setPreviousTemplateSettings] = useState(getTemplateSettings());
    var _c = useState(null), currentTemplateSettings = _c[0], setCurrentTemplateSettings = _c[1];
    useEffect(function () {
        setIsBo(true);
    }, [setIsBo]);
    useEffect(function () {
        var templatesSettings = getTemplatesSettings();
        previousTemplateSettingsRef.current = templatesSettings;
        setCurrentTemplateSettings(templatesSettings);
    }, [getTemplateSettings, getTemplatesSettings, setCurrentTemplateSettings]);
    var templateSettingsProps = useMemo(function () {
        var _a, _b;
        if (!currentTemplateSettings) {
            return {};
        }
        if (editionMode)
            return generateTemplatePropsFromAdminContents((_a = currentTemplateSettings.children) !== null && _a !== void 0 ? _a : []);
        return generateTemplatePropsFromContents((_b = currentTemplateSettings.children) !== null && _b !== void 0 ? _b : []);
    }, [currentTemplateSettings, editionMode]);
    var currentTemplate = useMemo(function () {
        var templates = CmsHooks.getHook(HOOKS.TEMPLATES) || [];
        return templates.find(function (t) { return t.key === template; });
    }, [template]);
    var editablePage = useMemo(function () {
        if (!page) {
            return null;
        }
        return generateEditablePage(page, handlePageChange, currentTemplate);
    }, [currentTemplate, handlePageChange, page]);
    if (!page) {
        return null;
    }
    var TemplateTag = getTemplateComponentByKey(template);
    var handleChangeTemplateSettings = function (newTemplateSettings) {
        setTemplateIsModify(true);
        setCurrentTemplateSettings(newTemplateSettings);
    };
    var handleValidateTemplateSettingsChange = function () {
        var _a;
        var templatesSettings = getTemplatesSettings();
        handleTemplateSettingsChange(__assign(__assign({}, templatesSettings), { children: (_a = templatesSettings.children) === null || _a === void 0 ? void 0 : _a.map(function (t) {
                return t.key === template && currentTemplateSettings ? currentTemplateSettings : t;
            }) }));
    };
    var handleCancelTemplateSettingsChange = function () {
        setCurrentTemplateSettings(previousTemplateSettingsRef.current);
    };
    return (_jsx(Layout, { children: _jsx(Box, { sx: ckeBordersStyled, children: editionMode ? (_jsxs(_Fragment, { children: [_jsx(AdminTemplateSettings, { templateSettings: currentTemplateSettings !== null && currentTemplateSettings !== void 0 ? currentTemplateSettings : {}, handleChangeTemplateSettings: handleChangeTemplateSettings, displayModal: displayAdminTemplateSettingsModal, setDisplayModal: setDisplayAdminTemplateSettingsModal, handleValidateTemplateSettingsChange: handleValidateTemplateSettingsChange, handleCancelTemplateSettingsChange: handleCancelTemplateSettingsChange, templateIsModify: templateIsModify, setTemplateIsModify: setTemplateIsModify }), _jsx(TemplateTag, __assign({ page: editablePage }, templateSettingsProps))] })) : (_jsx(Page, { page: page, templatesSettings: templateSettingsProps })) }) }));
};
export default memo(RenderPage);
