var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HelpIcon from "@mui/icons-material/Help";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormLabelTooltip } from "administration/components/contentForms/ContentFormStyled";
import Select from "shared/components/Select";
var SelectForm = function (props) {
    var content = props.content, onContentChange = props.onContentChange, _a = props.tooltip, tooltip = _a === void 0 ? null : _a;
    var id = content.id, label = content.label, value = content.value, options = content.options;
    var handleSelectChange = function (e) {
        onContentChange(__assign(__assign({}, content), { value: e.target.value }));
    };
    return (_jsxs(FormControl, { children: [_jsxs(InputLabel, { sx: { pointerEvents: "auto" }, children: [label, tooltip && (_jsx(FormLabelTooltip, { title: tooltip, children: _jsx(HelpIcon, {}) }))] }), _jsx(Select, { value: value, 
                // native
                onChange: handleSelectChange, displayEmpty: true, wrapperProps: undefined, children: options === null || options === void 0 ? void 0 : options.map(function (option, index) { return (_jsx(MenuItem, { value: option.value, children: option.label }, index)); }) })] }, id));
};
export default SelectForm;
