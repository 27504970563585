import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal, { ModalActions } from "administration/components/Modal";
import { Fragment, useEffect, useState } from "react";
import { Prompt, useHistory, useLocation } from "react-router-dom";
var PreventNavigation = function (props) {
    var _a = props.blocked, blocked = _a === void 0 ? false : _a, _b = props.onSave, onSave = _b === void 0 ? null : _b, _c = props.onShowPrompt, onShowPrompt = _c === void 0 ? null : _c, _d = props.onNavigate, onNavigate = _d === void 0 ? null : _d;
    var _e = useState(false), blockedLocation = _e[0], setBlockedLocation = _e[1];
    var _f = useState(false), confirmNavigation = _f[0], setConfirmNavigation = _f[1];
    var location = useLocation();
    var history = useHistory();
    useEffect(function () {
        // eslint-disable-next-line consistent-return
        window.onbeforeunload = function () {
            if (blocked) {
                return "";
            }
        };
    }, [blocked]);
    useEffect(function () {
        setConfirmNavigation(false);
    }, [location]);
    useEffect(function () {
        if (confirmNavigation && blockedLocation) {
            history.push(blockedLocation);
            setBlockedLocation(false);
        }
    }, [blockedLocation, confirmNavigation, history]);
    //@fixme: une incompréhension sur le type de nextLocation
    var handleBlockedNavigation = function (nextLocation) {
        if (nextLocation.pathname === location) {
            return false;
        }
        if (!confirmNavigation) {
            if (typeof onShowPrompt === "function") {
                onShowPrompt();
            }
            setBlockedLocation(nextLocation);
            return false;
        }
        return true;
    };
    var handleConfirmNavigation = function () {
        if (typeof onNavigate === "function") {
            onNavigate();
        }
        setConfirmNavigation(true);
    };
    var handleCancelNavigation = function () {
        setBlockedLocation(false);
    };
    var handleSaveAndLeave = function () {
        if (typeof onSave === "function") {
            // const promise = onSave();
            onSave();
            // if (promise && typeof promise.then === "function") {
            // promise.then(() => {
            setConfirmNavigation(true);
            // });
            // } else {
            //   setConfirmNavigation(true);
            // }
        }
    };
    return (_jsxs(Fragment, { children: [_jsx(Prompt, { when: blocked, message: handleBlockedNavigation }), _jsx(Modal, { size: "sm", open: blockedLocation, onClose: handleCancelNavigation, children: _jsxs(_Fragment, { children: [_jsx("h2", { children: "Voulez-vous enregistrer les modifications apport\u00E9es \u00E0 cette page ?" }), _jsx(Box, { pb: 6, children: "Vous perdrez vos modifications, si vous ne les enregistrez pas." }), _jsxs(ModalActions, { children: [_jsx(Button, { color: "secondary", onClick: handleConfirmNavigation, children: "Ne pas enregistrer" }), _jsx(Button, { onClick: handleCancelNavigation, children: "Annuler" }), typeof onSave === "function" && (_jsx(Button, { color: "primary", onClick: handleSaveAndLeave, children: "Enregistrer et quitter" }))] })] }) })] }));
};
export default PreventNavigation;
