// import React from "react";
// import customTypes from "customComponents/customTypes";
var getEditorComponent = function (_a) {
    var content = _a.content /* handleContentChange, value, id */;
    var type = content.type;
    switch (type) {
        default:
            return null;
    }
};
export default getEditorComponent;
