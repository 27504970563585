var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Drawer } from "@mui/material";
import Modal from "administration/components/Modal";
var AdminContentEditorContainer = function (props) {
    var open = props.open, hideActions = props.hideActions, onClose = props.onClose, drawer = props.drawer, title = props.title, children = props.children, others = props.others;
    var close = function () {
        if (typeof hideActions === "function")
            hideActions();
        onClose();
    };
    if (drawer) {
        return (_jsx(Drawer, __assign({ transitionDuration: 0, anchor: "right", BackdropProps: { sx: { bgcolor: "rgba(0,0,0,0.4)" } }, open: open, onClose: close, PaperProps: {
                sx: {
                    width: "325px",
                    pr: "10px",
                    pl: "10px",
                    border: "none",
                    borderRadius: "0px",
                },
            } }, others, { children: children })));
    }
    else {
        return (_jsx(Modal, __assign({ "aria-labelledby": "admin-content", "aria-describedby": "content-administration", open: open, title: title, onClose: onClose, size: "md" }, others, { children: _jsx(_Fragment, { children: children }) })));
    }
};
export default AdminContentEditorContainer;
