import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import AdminContentEditor from "administration/components/admin/AdminContentEditor/AdminContentEditor";
import ThemeBackOfficeProvider from "administration/theme/ThemeBackOfficeProvider";
import { adminProperties, replacePagesByIds } from "administration/utils/adminContentsUtils";
import { removeProperties } from "shared/utils/commonUtils";
import CmsHooks from "shared/utils/register/CmsHooks";
import { HOOKS } from "shared/utils/register/definitions";
var buttonsStyled = {
    mb: 2,
    ml: 2,
    "& button": {
        py: 1.5,
        "& span": {
            mr: 1.5,
        },
    },
};
var AdminTemplateSettings = function (props) {
    var _a = props.templateSettings, templateSettings = _a === void 0 ? null : _a, handleChangeTemplateSettings = props.handleChangeTemplateSettings, displayModal = props.displayModal, setDisplayModal = props.setDisplayModal, handleValidateTemplateSettingsChange = props.handleValidateTemplateSettingsChange, handleCancelTemplateSettingsChange = props.handleCancelTemplateSettingsChange, templateIsModify = props.templateIsModify, setTemplateIsModify = props.setTemplateIsModify;
    var templates = CmsHooks.getHook(HOOKS.TEMPLATES) || [];
    var template = templateSettings && templates.find(function (t) { return t.key === templateSettings.key; });
    var templateLabel = template === null || template === void 0 ? void 0 : template.label;
    var handleContentChange = function (updatedContent) {
        setDisplayModal(false);
        setTemplateIsModify(true);
        handleChangeTemplateSettings(updatedContent);
    };
    var handleValidate = function () {
        var _a;
        setTemplateIsModify(false);
        if (!templateSettings || !templateSettings.children)
            return;
        templateSettings.children = templateSettings.children.map(function (c) { return removeProperties(c, adminProperties); });
        templateSettings.children = (_a = templateSettings.children) === null || _a === void 0 ? void 0 : _a.map(function (content) { return replacePagesByIds(content); });
        handleValidateTemplateSettingsChange(templateSettings);
    };
    var handleCancel = function () {
        setTemplateIsModify(false);
        handleCancelTemplateSettingsChange();
    };
    if (!templateSettings || !templateSettings.children) {
        return null;
    }
    return (_jsxs(ThemeBackOfficeProvider, { children: [_jsxs(Box, { sx: buttonsStyled, children: [_jsxs(Button, { color: "secondary", variant: "contained", onClick: function () { return setDisplayModal(true); }, children: [_jsx(Icon, { className: "fas fa-cog" }), " Modifier le gabarit"] }), templateIsModify && (_jsxs(_Fragment, { children: [_jsx(Button, { color: "primary", variant: "contained", onClick: handleCancel, children: "Annuler" }), _jsx(Button, { color: "primary", variant: "contained", onClick: handleValidate, children: "Valider pour toutes les pages" })] }))] }), _jsx(AdminContentEditor, { title: "Param\u00E8tres du gabarit: ".concat(templateLabel), open: displayModal, content: templateSettings, onValidate: handleContentChange, onClose: function () { return setDisplayModal(false); } })] }));
};
export default AdminTemplateSettings;
