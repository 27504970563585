import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Search from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import EnhancedTable from "administration/components/EnhancedTable/EnhancedTable";
import AdminPagesService from "administration/services/AdminPagesService";
import { useSites } from "shared/hooks/SiteHook";
import { useEffect, useState } from "react";
var searchTimeout;
var PagesTable = function (props) {
    var onPageClick = props.onPageClick;
    var _a = useState({
        data: [],
        total: 0,
    }), pages = _a[0], setPages = _a[1];
    var _b = useState({
        order: "desc",
        orderBy: "updated",
    }), sorting = _b[0], setSorting = _b[1];
    var _c = useState(1), page = _c[0], setPage = _c[1];
    var _d = useState(10), rowsPerPage = _d[0], setRowsPerPage = _d[1];
    var _e = useState(), search = _e[0], setSearch = _e[1];
    var currentSite = useSites().currentSite;
    var order = sorting.order, orderBy = sorting.orderBy;
    var columns = [
        {
            id: "name",
            label: "Nom de la page",
            sortable: true,
            render: function (p) { return p.name; },
            sx: function () { return ({ fontWeight: "bold" }); },
        },
        {
            id: "status",
            label: "Statut",
            render: function (p) { return (p.isPublished ? "Publiée" : "Non publiée"); },
            sx: function (p) { return (p.isPublished ? { color: "success.main" } : null); },
        },
        {
            id: "updated",
            label: "Dernière modification",
            sortable: true,
            render: function (p) { return new Date(p.updated).toLocaleDateString(); },
        },
    ];
    useEffect(function () {
        var filter = ["siteId||eq||".concat(currentSite === null || currentSite === void 0 ? void 0 : currentSite.id)];
        if (search) {
            filter.push("name||cont||".concat(search));
        }
        var options = {
            params: {
                filter: filter,
                sort: ["".concat(orderBy, ",") + (order === null || order === void 0 ? void 0 : order.toUpperCase())],
                per_page: rowsPerPage,
                offset: (page - 1) * rowsPerPage,
            },
        };
        AdminPagesService.find(options).then(function (pagesResult) {
            var data = pagesResult.data, total = pagesResult.total;
            setPages({ data: data, total: total });
        });
    }, [currentSite, order, orderBy, page, rowsPerPage, search]);
    var handleChangeSort = function (event, key) {
        if (orderBy !== key) {
            setSorting({
                order: "asc",
                orderBy: key,
            });
        }
        else {
            setSorting({
                order: order === "asc" ? "desc" : "asc",
                orderBy: key,
            });
        }
    };
    var handleChangeRowsPerPage = function (event) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };
    var handleSearchChange = function (event) {
        var value = event.target.value;
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        searchTimeout = setTimeout(function () {
            setSearch(value);
        }, 350);
    };
    return (_jsxs(Grid, { container: true, direction: "column", sx: { height: "100%" }, children: [_jsx(Grid, { container: true, children: _jsx(Grid, { item: true, xs: 5, children: _jsx(Input, { placeholder: "Filtrer les pages", disableUnderline: true, onChange: handleSearchChange, sx: { width: "100%", mb: 2 }, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { size: "large", children: _jsx(Search, {}) }) }) }) }) }), _jsx("div", { style: { flex: "1 0 auto" }, children: _jsx(EnhancedTable, { columns: columns, data: pages.data, order: order, orderBy: orderBy, page: page, totalRows: pages.total, rowsPerPage: rowsPerPage, rowsPerPageOptions: [5, 10, 25, 50], onRequestSort: handleChangeSort, onChangePage: setPage, onChangeRowsPerPage: handleChangeRowsPerPage, onRowClick: onPageClick }) })] }));
};
export default PagesTable;
