var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Grid, TextField } from "@mui/material";
import { stringToPath } from "shared/utils/urlUtils";
var AdminPagePathForm = function (_a) {
    var form = _a.form, setForm = _a.setForm, formSubmitted = _a.formSubmitted, errors = _a.errors, setPathIsUnavailable = _a.setPathIsUnavailable, path = _a.path;
    var handleChangePath = function (e) {
        var value = e.target.value;
        setPathIsUnavailable(false);
        setForm(__assign(__assign({}, form), { path: stringToPath(value) }));
    };
    return (_jsx(Grid, { item: true, xs: 6, children: _jsx(TextField, { error: formSubmitted && Boolean(errors.path), label: "Chemin", value: path, name: "path", onChange: handleChangePath, helperText: formSubmitted && errors.path }) }));
};
export default AdminPagePathForm;
