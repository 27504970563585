var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import dayjs from "dayjs";
import { enhanceContent } from "administration/utils/adminContentsUtils";
import { getEditorComponent } from "administration/utils/templateAdminUtils";
export var generateTemplatePropsFromAdminContents = function (contents, handleContentsChange) {
    var props = {};
    if (Array.isArray(contents)) {
        contents.forEach(function (content) {
            var value = content.value;
            var key = content.key, id = content.id;
            var enChancedContent = enhanceContent(content);
            var children = enChancedContent.children;
            if (children && children.length) {
                var handleChildrenChange = function (newChildren) {
                    handleContentsChange &&
                        handleContentsChange(contents.map(function (oldContent) {
                            return oldContent === content ? __assign(__assign({}, content), { children: newChildren }) : oldContent;
                        }));
                };
                // @ts-ignore @fixme: à fixer avec
                value = generateTemplatePropsFromAdminContents(children, handleChildrenChange);
                // @ts-ignore
                value.id = id;
            }
            var handleContentChange = function (updatedContent) {
                handleContentsChange &&
                    handleContentsChange(contents
                        .map(function (oldContent) { return (oldContent === content ? __assign(__assign({}, updatedContent), { updatedAt: dayjs() }) : oldContent); })
                        .filter(function (c) { return !!c; }));
            };
            value = getEditorComponent({
                content: enChancedContent,
                originalContent: content,
                sameLevelContents: contents,
                value: value,
                handleContentChange: handleContentChange,
                handleContentsChange: handleContentsChange,
            });
            // if key already exists, then it will be an array
            if (key) {
                if (props[key]) {
                    if (!Array.isArray(props[key])) {
                        props[key] = [props[key]];
                    }
                    props[key].push(value);
                }
                else {
                    props[key] = value;
                }
            }
        });
    }
    return props;
};
