import axios from "axios";
import { Routes } from "shared/routes/enums/routes.enum";
import { useQuery } from "@tanstack/react-query";
export var useIconAutocomplete = function () {
    var _a = useQuery({
        queryKey: ["font-awesome-icons"],
        queryFn: function () { return axios.get(Routes.FONT_AWESOME_ICON).then(function (response) { return response.data; }); },
        initialData: [],
    }), data = _a.data, isLoading = _a.isLoading;
    return { data: data, isLoading: isLoading };
};
