var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, Button, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Icon from "shared/components/Icon";
import { generateContentId, getDefaultContentByType } from "administration/utils/adminContentsUtils";
import { swapArrayItems } from "shared/utils/ArrayUtils";
import React, { Fragment, isValidElement, useMemo } from "react";
import useContentFormStore from "administration/store/ContentFormStore";
var AdminListForm = function (props) {
    var _a;
    var contents = props.contents, elementsType = props.elementsType, elementsKey = props.elementsKey, onContentsChange = props.onContentsChange, _b = props.inline, inline = _b === void 0 ? false : _b, _c = props.title, title = _c === void 0 ? "" : _c, children = props.children;
    var displayVertical = useContentFormStore(function (state) { return ({ displayVertical: state.displayVertical }); }).displayVertical;
    var handleMove = function (childId, up) {
        if (up === void 0) { up = false; }
        var contentToMove = contents.find(function (c) { return c.id === childId; });
        var contentIndex = contentToMove ? contents.indexOf(contentToMove) : 0;
        var targetIndex = up ? contentIndex - 1 : contentIndex + 1;
        onContentsChange(swapArrayItems(contents, contentIndex, targetIndex));
    };
    var handleClickDelete = function (childId) {
        onContentsChange(__spreadArray([], contents.filter(function (c) { return c.id !== childId; }), true));
    };
    var handleClickAdd = function () {
        var defaultContent = getDefaultContentByType(elementsType);
        onContentsChange(__spreadArray(__spreadArray([], contents, true), [__assign({ key: elementsKey }, generateContentId(defaultContent))], false));
    };
    var displayInline = useMemo(function () { return inline && !displayVertical; }, [inline, displayVertical]);
    var isReactChildren = isValidElement(children);
    var childrenList = isReactChildren ? (_a = children["props"]) === null || _a === void 0 ? void 0 : _a.children : children;
    var IconListActions = function (_a) {
        var child = _a.child, index = _a.index;
        return (_jsxs(Box, { sx: {
                display: "flex",
                flexDirection: displayInline ? "row" : "column-reverse",
                // justifyContent: displayInline ? "space-between" : "flex-start",
                alignItems: "center",
                mt: index === 0 || displayInline ? 0 : 2,
                border: "solid",
                borderColor: "#999",
                padding: 2,
                paddingBottom: 1,
                marginBottom: 2,
                borderRadius: 2,
            }, children: [_jsxs(Box, { flexDirection: "row", children: [title !== "" && (_jsxs(Typography, { variant: "h3", sx: { marginBottom: 1 }, children: [title, " ", index + 1] })), _jsx("div", { style: { flex: 1 }, children: child })] }), _jsxs(Box, { flexDirection: "row", children: [_jsx(IconButton, { onClick: function () { return (isReactChildren ? handleMove(child.key, true) : handleMove(child.props.content.id, true)); }, edge: "end", "aria-label": "monter", disabled: index === 0, size: "large", children: _jsx(Icon, { icon: "chevron-up", title: "Monter" }) }), _jsx(IconButton, { onClick: function () { return (isReactChildren ? handleMove(child.key) : handleMove(child.props.content.id)); }, edge: "end", "aria-label": "descendre", disabled: isReactChildren
                                ? index === childrenList.length - 1
                                : Array.isArray(children) && index === childrenList.length - 1, size: "large", children: _jsx(Icon, { icon: "chevron-down", title: "Descendre" }) }), _jsx(IconButton, { onClick: function () {
                                return isReactChildren ? handleClickDelete(child.key) : handleClickDelete(child.props.content.id);
                            }, edge: "end", "aria-label": "supprimer", size: "large", children: _jsx(Icon, { icon: "trash", title: "Supprimer" }) })] })] }, child.key));
    };
    return (_jsxs(Fragment, { children: [isReactChildren
                ? childrenList.map(function (child, index) { return _jsx(IconListActions, { child: child, index: index }); })
                : React.Children.map(children, function (child, index) { return _jsx(IconListActions, { child: child, index: index }); }), elementsType && (_jsx(Button, { style: { marginTop: 1 }, onClick: handleClickAdd, children: "Ajouter" }))] }));
};
export default AdminListForm;
