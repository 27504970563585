import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useHandleChangePageVersion, usePageVersion } from "administration/hooks/AdminPageVersionHook";
import useAdminPageVersionStore from "administration/store/AdminPageVersionStore";
import { useShallow } from "zustand/react/shallow";
import useAdminEditorStore from "administration/store/AdminEditorStore";
export var AdminPageVersionProvider = function (props) {
    var children = props.children;
    var pageVersionId = useAdminEditorStore(function (state) { return ({ pageVersionId: state.currentPageVersionId }); }).pageVersionId;
    var pageVersions = usePageVersion().pageVersions;
    var _a = useAdminPageVersionStore(useShallow(function (state) { return ({
        currentPageVersion: state.currentPageVersion,
        setCurrentPageVersion: state.setCurrentPageVersion,
    }); })), currentPageVersion = _a.currentPageVersion, setCurrentPageVersion = _a.setCurrentPageVersion;
    var handleChangePageVersion = useHandleChangePageVersion().handleChangePageVersion;
    // Set current version
    useEffect(function () {
        if (pageVersions && pageVersions.length) {
            var version = pageVersions.find(function (v) { return v.id === pageVersionId; });
            if (version && (!currentPageVersion || version.id !== currentPageVersion.id)) {
                setCurrentPageVersion(version);
            }
            else if (!version) {
                handleChangePageVersion(pageVersions[0]);
            }
        }
    }, [currentPageVersion, handleChangePageVersion, pageVersionId, pageVersions, setCurrentPageVersion]);
    return _jsx(_Fragment, { children: children });
};
