import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import EditIcon from "@mui/icons-material/Settings";
import { AdminActionsContext } from "administration/components/admin/AdminActions";
import AdminContentEditor from "administration/components/admin/AdminContentEditor/AdminContentEditor";
import React from "react";
var EditContentAction = function (props) {
    var content = props.content, onContentChange = props.onContentChange;
    var _a = React.useState(false), displayModalContent = _a[0], setDisplayModalContent = _a[1];
    var _b = React.useContext(AdminActionsContext), showActions = _b.showActions, hideActions = _b.hideActions;
    // handleClickEdit: display Modal or Drawer when click on edit. --------------
    var handleClickEdit = function () {
        setDisplayModalContent(true);
        showActions && showActions();
    };
    // handleValidate: used when submit. -----------------------------------------
    var handleValidate = function () {
        setDisplayModalContent(false);
        hideActions && hideActions();
    };
    // handleContentChange: used to update form for preview. ---------------------
    var handleContentChange = React.useCallback(function (updatedContent) {
        onContentChange(updatedContent);
    }, [onContentChange]);
    // handleClose: used to close modal or drawer. -------------------------------
    var handleClose = React.useCallback(function () {
        setDisplayModalContent(false);
    }, []);
    // Display admin modal or drawer. --------------------------------------------
    return (_jsxs(_Fragment, { children: [_jsx(EditIcon, { onClick: handleClickEdit }), _jsx(AdminContentEditor, { drawer: true, open: displayModalContent, content: content, onValidate: handleValidate, onContentChange: handleContentChange, onClose: handleClose })] }));
};
export default EditContentAction;
