var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HelpIcon from "@mui/icons-material/Help";
import { Box, FormControl, Grid, InputLabel, Tooltip } from "@mui/material";
import CKEditorCustom from "../CKEditorCustom";
import { descriptionToolbarEditor } from "shared/utils/commonUtils";
var labelWithTooltipStyled = {
    pointerEvents: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    columnGap: 1,
};
var AdminPageShortDescriptionForm = function (_a) {
    var form = _a.form, setForm = _a.setForm, shortDescription = _a.shortDescription, page = _a.page;
    var handleShortDescriptionChange = function (value) {
        setForm(__assign(__assign({}, form), { shortDescription: value }));
    };
    return (_jsx(Grid, { container: true, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(Box, { mt: 1, children: _jsxs(FormControl, { children: [_jsxs(InputLabel, { sx: labelWithTooltipStyled, shrink: true, children: ["Description courte", _jsx(Tooltip, { title: "Utilis\u00E9 comme chapeau par certains gabarits ou lorsque ces donn\u00E9es sont ins\u00E9r\u00E9es en tant que sous-page", children: _jsx(HelpIcon, {}) })] }), _jsx(CKEditorCustom, { initData: decodeURIComponent(shortDescription || ""), onChange: function (data) { return handleShortDescriptionChange(encodeURIComponent(data)); }, type: "inline", config: { toolbar: descriptionToolbarEditor, baseFloatZIndex: 1350 } }, page.id)] }) }) }) }));
};
export default AdminPageShortDescriptionForm;
