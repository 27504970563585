var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { getDefaultContentByType } from "administration/utils/adminContentsUtils";
import ContentsTypes from "shared/enums/contentsTypes";
import { FormFieldset } from "administration/components/contentForms/ContentFormStyled";
import CheckboxForm from "./CheckboxForm";
import SliderForm from "./SliderForm";
import useContentFormStore from "administration/store/ContentFormStore";
var defaultFilters = getDefaultContentByType(ContentsTypes.IMAGE_FILTERS);
var ImageFiltersForm = function (props) {
    var content = props.content, onContentChange = props.onContentChange;
    var displayVertical = useContentFormStore(function (state) { return ({ displayVertical: state.displayVertical }); }).displayVertical;
    var id = content.id, label = content.label, children = content.children;
    var activatedChild = React.useMemo(function () { return children === null || children === void 0 ? void 0 : children.find(function (c) { return c.key === "activated"; }); }, [children]);
    var filtersChildren = React.useMemo(function () { return children === null || children === void 0 ? void 0 : children.filter(function (c) { return c !== activatedChild; }); }, [children, activatedChild]);
    var handleChangeChild = function (child) { return function (newChild) {
        onContentChange(__assign(__assign({}, content), { children: children === null || children === void 0 ? void 0 : children.map(function (c) {
                if (c === child) {
                    return newChild;
                }
                return c;
            }) }));
    }; };
    var handleClickResetFilters = React.useCallback(function (e) {
        e.preventDefault();
        onContentChange(__assign(__assign({}, content), { children: children === null || children === void 0 ? void 0 : children.map(function (c) {
                var _a, _b;
                if (c === activatedChild) {
                    return c;
                }
                return __assign(__assign({}, c), { value: (_b = (_a = defaultFilters === null || defaultFilters === void 0 ? void 0 : defaultFilters.children) === null || _a === void 0 ? void 0 : _a.find(function (defaultChild) { return defaultChild.key === c.key; })) === null || _b === void 0 ? void 0 : _b.value });
            }) }));
    }, [children, activatedChild, content, onContentChange]);
    return (_jsxs(FormFieldset, { children: [_jsx("legend", { children: label }), activatedChild && _jsx(CheckboxForm, { content: activatedChild, onContentChange: handleChangeChild(activatedChild) }), (activatedChild === null || activatedChild === void 0 ? void 0 : activatedChild.value) === "true" && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx(Link, { sx: { fontSize: 12, cursor: "pointer", mb: 1, display: "block" }, onClick: handleClickResetFilters, children: "Valeurs par d\u00E9faut" }), _jsx(Grid, { container: true, spacing: 1, children: filtersChildren === null || filtersChildren === void 0 ? void 0 : filtersChildren.map(function (filterChild) { return (_jsx(Grid, { item: true, xs: displayVertical ? 12 : 6, children: _jsx(SliderForm, { content: filterChild, onContentChange: handleChangeChild(filterChild) }) }, filterChild.id)); }) })] }))] }, id));
};
export default ImageFiltersForm;
