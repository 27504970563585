var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormFieldset, FormTitle } from "administration/components/contentForms/ContentFormStyled";
import { Fragment, useMemo } from "react";
import FormFactory from "./formFactory";
var ContentForm = function (props) {
    var content = props.content, _a = props.topLevel, topLevel = _a === void 0 ? false : _a, _b = props.formSubmitted, formSubmitted = _b === void 0 ? false : _b, _c = props.required, required = _c === void 0 ? false : _c, onContentChange = props.onContentChange, regexValidation = props.regexValidation;
    var type = content.type, id = content.id, key = content.key, label = content.label, children = content.children, _d = content.editionModal, editionModal = _d === void 0 ? false : _d, _e = content.tooltip, tooltip = _e === void 0 ? null : _e;
    var _f = useMemo(function () { return FormFactory.init(type).getForm; }, [type]), Form = _f.Form, formProps = _f.formProps;
    if (editionModal && !topLevel) {
        return null;
    }
    if (Form) {
        return (_jsx(Form, __assign({ content: content, onContentChange: onContentChange, formSubmitted: formSubmitted, required: required, regexValidation: regexValidation !== null && regexValidation !== void 0 ? regexValidation : content.regexValidation, tooltip: tooltip }, formProps), id !== null && id !== void 0 ? id : key));
    }
    if (children) {
        var childrenForm = children
            .map(function (child) {
            var _a;
            var onChildChange = function (newChild) {
                onContentChange(__assign(__assign({}, content), { children: children.map(function (c) { return (c === child ? newChild : c); }) }));
            };
            if (child.key) {
                return ContentForm({
                    content: child,
                    onContentChange: onChildChange,
                    topLevel: false,
                    formSubmitted: formSubmitted,
                    required: (_a = content === null || content === void 0 ? void 0 : content.requiredByKey) === null || _a === void 0 ? void 0 : _a.includes(child.key),
                    regexValidation: child.regexValidation,
                });
            }
            else {
                return null;
            }
        })
            .filter(function (c) { return !!c; });
        if (childrenForm.length) {
            if (topLevel) {
                return (_jsxs(Fragment, { children: [_jsx(FormTitle, { children: label }), _jsx("div", { children: childrenForm })] }, id));
            }
            return (_jsxs(FormFieldset, { children: [_jsx("legend", { children: label }), childrenForm] }, id));
        }
    }
    return null;
};
export default ContentForm;
