var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import CheckboxForm from "../CheckboxForm";
import CKEditorCustom from "administration/components/CKEditorCustom";
import SelectForm from "../SelectForm";
import { standardToolbarEditor } from "shared/utils/commonUtils";
export var SiteSettingsBannerForm = function (_a) {
    var _b;
    var handleChildChange = _a.handleChildChange, findContent = _a.findContent;
    var warningBannerChild = findContent("warningBanner");
    var warningMessage = (warningBannerChild.children || []).find(function (c) { return c.key === "message"; }) || {};
    return (_jsxs(_Fragment, { children: [_jsx(CheckboxForm, { content: __assign(__assign({}, (warningBannerChild.children || []).find(function (c) { return c.key === "enable"; })), { label: "Activer le bandeau d'information" }), onContentChange: function (newContent) {
                    return handleChildChange(warningBannerChild, __assign(__assign({}, warningBannerChild), { children: (warningBannerChild.children || []).map(function (c) { return (c.key === "enable" ? newContent : c); }) }));
                } }), _jsxs(_Fragment, { children: [_jsx(CKEditorCustom, { onChange: function (data) {
                            return handleChildChange(warningBannerChild, __assign(__assign({}, warningBannerChild), { children: (warningBannerChild.children || []).map(function (c) {
                                    return c.key === "message" ? __assign(__assign({}, c), { value: encodeURIComponent(data) }) : c;
                                }) }));
                        }, initData: decodeURIComponent(warningMessage === null || warningMessage === void 0 ? void 0 : warningMessage.value), config: { toolbar: standardToolbarEditor } }, (_b = warningMessage === null || warningMessage === void 0 ? void 0 : warningMessage.value) === null || _b === void 0 ? void 0 : _b.id), _jsx(SelectForm, { content: (warningBannerChild.children || []).find(function (c) { return c.key === "severity"; }), onContentChange: function (newContent) {
                            return handleChildChange(warningBannerChild, __assign(__assign({}, warningBannerChild), { children: (warningBannerChild.children || []).map(function (c) { return (c.key === "severity" ? newContent : c); }) }));
                        } })] })] }));
};
