import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { PageVersionStatus } from "shared/types/cms/page/version/page-version.status.enum";
var selectStyled = {
    fontWeight: 600,
    bgcolor: "secondary.main",
    "& .secondaryLabel": {
        display: "none",
    },
    "& .versionContainer": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: 200,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
    },
};
var menuItemStyled = function (index) { return ({
    p: 0,
    "& .spacing": {
        width: "100%",
        px: 2,
    },
    "& .published": {
        color: "success.main",
    },
    "& .versionContainer": {
        py: 1,
        width: "100%",
        borderTop: index === 0 ? "none" : "1px solid",
        borderColor: "layout.border",
    },
}); };
var SelectVersion = function (props) {
    var _a = props.versions, versions = _a === void 0 ? [] : _a, _b = props.selectedVersion, selectedVersion = _b === void 0 ? null : _b, onSelectVersion = props.onSelectVersion;
    var handleSelectChange = function (e) {
        var id = e.target.value;
        if (selectedVersion && selectedVersion.id !== id) {
            onSelectVersion(versions.find(function (version) { return version.id === id; }));
        }
    };
    var getDateFormated = function (data) {
        return new Date(data).toLocaleDateString("FR-fr", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
        });
    };
    var getAllDateFormated = function (data) {
        return new Date(data).toLocaleDateString("FR-fr", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        });
    };
    var getVersionName = function (version) { return version.versionName || "Version du ".concat(getDateFormated(version.created)); };
    var getVersionUpdatedLabel = function (version) {
        var date = getAllDateFormated(version.updated);
        switch (version.status) {
            case PageVersionStatus.DRAFT:
                return "Derni\u00E8re mise \u00E0 jour le ".concat(date);
            case PageVersionStatus.ARCHIVED:
                return "Archiv\u00E9e le ".concat(date);
            case PageVersionStatus.PUBLISHED:
                return "Publi\u00E9e le ".concat(date);
            default:
                return "";
        }
    };
    return (_jsx(FormControl, { variant: "outlined", children: selectedVersion && (_jsx(Select, { sx: selectStyled, value: selectedVersion.id, onChange: handleSelectChange, IconComponent: KeyboardArrowDownIcon, MenuProps: {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
            }, children: versions.map(function (version, index) { return (_jsx(MenuItem, { value: version.id, sx: menuItemStyled(index), children: _jsx("div", { className: "spacing", children: _jsxs(Box, { className: "versionContainer", sx: { border: index === 0 ? "none" : "inherit" }, children: [_jsx(Box, { component: "span", sx: { color: version.status === "published" ? "success.main" : "secondary.text" }, title: getVersionName(version), children: getVersionName(version) }), _jsx("br", {}), _jsx("span", { className: "secondaryLabel", style: { fontSize: "0.79rem", color: "layout.draft" }, children: getVersionUpdatedLabel(version) })] }) }) }, version.id)); }) })) }));
};
export default SelectVersion;
